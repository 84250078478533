import React, { useState, useEffect, useRef } from "react";
import Cities from "../utils/cities";
import iconLocation from "../images/pin-logo.png";
import iconLocationRed from "../images/pin-logo-red.png";
import iconLocationOrange from "../images/pin-logo-orange.svg";

const Autocomplete = ({
  defaultValue,
  name,
  label,
  setLocation,
  withCountries,
  placeholder,
  withValidation = false,
  theme = "green"
}) => {
  const [query, setQuery] = useState("");
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(false);
  const inputEl = useRef();

  const selectIcon = () => {
    if (theme === "red") {
      return iconLocationRed;
    } else if (theme === "orange") {
      return iconLocationOrange;
    }

    return iconLocation;
  };

  const validatedInput = value => {
    if (!withValidation) return;
    if (value === "") {
      setError(false);
      return;
    }
    setError(!Cities.includes(value));
  };

  useEffect(() => {
    const cities =
      query.length == 0
        ? []
        : Cities.filter(
            city =>
              city.toLowerCase().includes(query.toLowerCase()) &&
              (withCountries || city.split(",").length > 1)
          );
    setCities(cities.slice(0, 5));
  }, [query]);

  return (
    <div id="citiesAutocomplete" className="customInputWrapper">
      <div className="flex">
        <label className="inputLabel">{`${label || "Location"}`}</label>
      </div>
      <div className="errorMessages">
        {error ? (
          <span className="error">Please select a location from the list.</span>
        ) : null}
      </div>
      <input
        onBlur={() => {
          setTimeout(() => {
            validatedInput(inputEl.current.value);
            setQuery("");
          }, 200);
        }}
        type="text"
        name={name}
        defaultValue={defaultValue}
        ref={inputEl}
        onKeyUp={e => setQuery(e.target.value)}
        placeholder={placeholder}
        className={`customInput ${theme} ${error ? "errorBg" : ""}`}
        autoComplete="off"
      />
      <div className="cities">
        {cities.map(city => (
          <div
            className={`city ${theme}`}
            key={city}
            onClick={() => {
              inputEl.current.value = city;
              setQuery("");
              if (setLocation) setLocation(city);
              validatedInput(inputEl.current.value);
            }}
          >
            <img src={selectIcon()} />
            {city}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Autocomplete;
