import flatpickr from "flatpickr";

const error = `<span class="gig-deadline-error error mb-2 block">Your deadline must be in the future</span>`;

const handleDeadline = (value, gigDeadlineContainer) => {
  const gigDeadlineError = document.querySelector(".gig-deadline-error");
  gigDeadlineError?.remove();

  if (value.getTime() <= Date.now()) {
    gigDeadlineContainer.insertAdjacentHTML("beforeend", error);
  }
};

export const gigDeadlineValidation = () => {
  const gigDeadlineNode = document.getElementById("gig_deadline");

  if (gigDeadlineNode) {
    const gigDeadlineContainer = gigDeadlineNode.parentElement;
    const tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
    flatpickr(gigDeadlineNode, {
      disableMobile: "true",
      minDate: tomorrow,
      onChange: value => {
        if (value[0]) {
          handleDeadline(value[0], gigDeadlineContainer);
        }
      }
    });
  }
};
