import { emailValidator } from "../../utils/emailValidator.js";

const error = `<span class="error mb-2 block" id="email-error">The email is invalid</span>`;

const initEmailValidator = () => {
  const input = document.querySelector("#user-register-email");
  const form = input?.closest("form");
  const emailError = document.querySelector("#email-error");
  const submitBtn = form?.querySelector("input[type='submit']");
  input?.addEventListener("keyup", () => {
    if (emailValidator(input.value)) {
      input.style.borderColor = "#b7fc22";
      submitBtn.disabled = false;
      emailError.classList.add("hidden");
    } else {
      input.style.borderColor = "#ff4747";
      submitBtn.disabled = true;
      emailError.classList.remove("hidden");
    }
  });
  input?.addEventListener("change", () => {
    if (emailValidator(input.value)) {
      input.style.borderColor = "#b7fc22";
      submitBtn.disabled = false;
    } else {
      input.style.borderColor = "#ff4747";
      submitBtn.disabled = true;
    }
  });
};

export { initEmailValidator };
