const strength = {
  0: "Bad",
  1: "Bad",
  2: "Weak",
  3: "Good",
  4: "Strong",
  5: "Strong"
};

const testPassword = password => {
  //TextBox left blank.
  if (password.length == 0) {
    document.querySelector("#password-strength-text").innerHTML = "";
    return;
  }
  //Regular Expressions.
  var regex = new Array();
  regex.push("[A-Z]"); //Uppercase Alphabet.
  regex.push("[a-z]"); //Lowercase Alphabet.
  regex.push("[0-9]"); //Digit.
  regex.push("[$@$!%*#?&]"); //Special Character.

  var passed = 0;

  //Validate for each Regular Expression.
  for (var i = 0; i < regex.length; i++) {
    if (new RegExp(regex[i]).test(password)) {
      passed++;
    }
  }

  //Validate for length of Password.
  if (passed > 2 && password.length > 8) {
    passed++;
  }

  //Display status.
  const result = {};
  switch (passed) {
    case 0:
      result.score = 0;
      break;
    case 1:
      result.score = 1;
      break;
    case 2:
      result.score = 2;
      break;
    case 3:
      result.score = 3;
    case 4:
      result.score = 4;
      break;
    case 5:
      result.score = 4;
      break;
  }

  return result;
};

const initPasswordValidator = () => {
  const password = document.getElementById("user_password");
  const meter = document.getElementById("password-strength-meter");
  const page = document.querySelector("#registrationPage");
  if (page) {
    const text = document.getElementById("password-strength-text");
    password.addEventListener("input", function () {
      if (password.value.length > 1) {
        meter.style.opacity = "1";
      }
      const val = password.value;
      const result = testPassword(val);
      if (!result){
        meter.value = 0;
        return
      }
      meter.value = result.score;
      text.innerHTML = "Strength:   " + strength[result.score];
    });
  }
};

export { initPasswordValidator };
