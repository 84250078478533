import React from "react";
import ImageGallery from "react-image-gallery";
import { generateCloseIcon } from "../utils/generateIcons";

const Slider = ({ closeSlider, pictures, photoIndex, type }) => {
  return (
    <div id="slider">
      <img
        src={generateCloseIcon(type)}
        className="closeIcon"
        onClick={closeSlider}
      />

      <ImageGallery
        items={pictures.map(p => {
          return {
            original: p,
            thumbnail: p
          };
        })}
        startIndex={photoIndex}
        showFullscreenButton={false}
        showPlayButton={false}
        additionalClass={type === "influencers" ? "redGallery" : "greenGallery"}
      />
    </div>
  );
};

export default Slider;
