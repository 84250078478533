import React, { useEffect, useState } from "react";
import axios from "axios";
import { activateScroll, blockScroll } from "../utils/iosScroll";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";
import ServiceCard from "./ServiceCard";
const { red, yellow } = COLORS;
const TabContentServices = (
  {
    data,
    theme,
  }) => {
  const [services, setServices] = useState();

  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/v1/profiles/user_services?id=${data.user_id}`);
      setServices(response.data);
    })();
  }, []);

  return (
    <>
      <div className="lower">
      <div className='cardWraper'>
        {services && services.length > 0 ? (
          services.map((service, i) => (
            <ServiceCard
              service_id={service.id}
              {...service}
              withModal={true}
              key={i}
              dummyButton={false}
              showApplyButton={false}
              indexPage={true}
              kind={theme}
            />
          ))
        ) : (
          <div className="ServicesTabContent">
            {services && services.length === 0 ? (
              <div className="noServiceFound">
                <p>No service found</p>
              </div>
            ) : (
              <div className="gridLoaderBox">
                <Loader
                  type="TailSpin"
                  color={theme === "models" ? yellow : red}
                  height={80}
                  width={80}
                  timeout={300000}
                />
              </div>
            )}
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default TabContentServices;