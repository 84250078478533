import React, { useState, useRef, useEffect } from "react";
import GigModal from "./GigModal";
import ShareGig from "./ShareGig";
import { moneyFormatter } from "../utils/moneyFormatter";
import GlobalRed from "../images/global-red.png";
import GlobalGreen from "../images/global-green.png";
import GlobalOrange from "../images/global-orange.png";
import pinRed from "../images/pin-logo-red.png";
import pinGreen from "../images/pin-logo.png";
import pinOrange from "../images/pin-logo-orange.png";

const GigCardTopLeft = ({ gender, theme, workType }) => {
  const [text, setText] = useState("");

  const textMapper = () => {
    if (workType === 0) {
      if (gender === "Any") {
        setText(`Models - any gender`);
      } else {
        setText(`Models - ${gender}`);
      }
    } else if (workType === 1) {
      setText("Influencer");
    } else if (workType === 2) {
      setText("Photographer");
    } else if (workType === 3) {
      setText("Videographer");
    } else if (workType === 4) {
      setText("Stylist / MUA");
    }
  };

  useEffect(() => {
    textMapper();
  }, []);

  return <div className={`genderTypeIcon ${theme}`}>{text}</div>;
};

const GigCard = ({
  work_type: workType,
  id,
  title,
  location,
  previewImg,
  gender,
  kind,
  budget,
  date,
  status,
  has_applied: hasApplied,
  banner,
  block_share: blockShare,
  blockModal,
  hideViewMore,
  linkToDetailsPage,
  display_creative_type: displayCreativeType,
  soloButton = false,
  indexPage = false
}) => {
  const [showGigModal, setShowGigModal] = useState(false);
  const image = useRef();
  const cardWrapper = useRef();
  const creativeTypeCheck = () => [2, 3, 4].includes(workType);

  useEffect(() => {
    if (image.current.width < 0) return;
    const width = image.current.offsetWidth;
    cardWrapper.current.style.height = `${(width / 16) * 9}px`;
  }, [image.current?.width]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const activeGigId = params.get("active");
    if (
      activeGigId?.match(/\d+/) &&
      parseInt(activeGigId) === id &&
      indexPage
    ) {
      setShowGigModal(true);
      window.history.pushState(
        { path: window.location.pathname },
        "",
        window.location.pathname
      );
    }
  }, []);

  const globalIcon = () => {
    if (workType === 1) {
      return GlobalRed;
    }

    if (creativeTypeCheck()) {
      return GlobalOrange;
    }

    return GlobalGreen;
  };
  const pinIcon = () => {
    if (workType === 1) {
      return pinRed;
    }

    if (creativeTypeCheck()) {
      return pinOrange;
    }

    return pinGreen;
  };
  const theme = () => {
    if (workType === 1) {
      return "influencers";
    }

    if (creativeTypeCheck()) {
      return "creatives";
    }

    return "models";
  };

  return (
    <>
      <div ref={cardWrapper} className="gigCard">
        {showGigModal && !hideViewMore ? (
          <GigModal
            id={id}
            theme={theme()}
            close={() => setShowGigModal(false)}
          />
        ) : null}
        <GigCardTopLeft theme={theme()} gender={gender} workType={workType} />
        <div className="cardContent" ref={image}>
          <img
            className="banner"
            src={previewImg || banner}
            alt={`${title}-banner`}
          />
          <div className="details">
            {hasApplied ? <div className="appliedStripe">Applied</div> : null}
            <div className={`pill ${kind} ${theme()}`}>
              {kind === "paid"
                ? `$${moneyFormatter(budget)}`
                : kind === "collab"
                ? "COLLAB"
                : "GIFTING"}
            </div>
            <h4>{title}</h4>
            <p className="location">
              <img src={location === "Global" ? globalIcon() : pinIcon()} />
              {location}
            </p>
            <p className="date">{date}</p>
          </div>
        </div>
        <div className="cardActions">
          {hideViewMore ? null : (
            <div
              className={`apply ${theme()}`}
              onClick={() => {
                if (showGigModal || blockModal) return;
                if (linkToDetailsPage) {
                  return (window.location.href = `/jobs/${id}/details`);
                }
                setShowGigModal(true);
              }}
            >
              VIEW
            </div>
          )}
          <ShareGig
            blockShare={blockShare}
            location={location}
            text="SHARE"
            dummyButton={status == "pending"}
            id={id}
            quote={`${title}${
              location == "Global" ? "" : ` in ${location}`
            } on Agency.Pics`}
            soloButton={soloButton}
            theme={theme()}
            displayCreativeType={displayCreativeType + "s"}
          />
        </div>
      </div>
    </>
  );
};

export default GigCard;
