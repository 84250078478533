import React from "react";
import Close from "../images/close-black.png";

const ActiveFilter = ({ attrName, query, list, removeQuery, type, label }) => {
  // Don't change line 20 to strict equality, it will not work!

  const generateLabel = label => {
    if (attrName === "name") {
      return "Name/AP ID";
    } else if (attrName === "applicable") {
      return "Type";
    }
    return label;
  };
  return (
    <div className="filterBox">
      <p className="title">{generateLabel(label)}</p>
      <div className="filters">
        {{
          ...query,
          location: [query.location],
          name: [query.name]
        }[`${attrName}`].map(i => {
          let attributes;
          if (attrName === "name") {
            attributes = { text: query?.name?.toUpperCase() };
          } else if (attrName === "location") {
            attributes = { text: query?.location };
          } else if (attrName === "applicable") {
            attributes = { text: "Jobs For You" };
          } else if (attrName === "verified") {
            attributes = { text: "Verified Users" };
          } else if (list) {
            attributes = list.find(({ value }) => value == i);
          }
          return (
            <div key={i} className={`filter ${type}`}>
              <span>{attributes?.text || label}</span>
              <img src={Close} onClick={() => removeQuery(attrName, i)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ActiveFilter;
