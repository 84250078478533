import React, { useEffect, useState, useRef } from "react";
import { clearEmptyObject } from "../utils/clearEmptyObject";

const ApplicableFilter = ({ name, query, setQuery }) => {
  const [checked, setChecked] = useState();
  const [mounted, setMounted] = useState(false);
  const input = useRef();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setChecked("applicable" in query);
  }, [query]);

  const changeCheckBox = () => {
    setChecked(prev => !prev);
  };

  useEffect(() => {
    if (!mounted) return;
    const newValue = clearEmptyObject({
      ...query,
      applicable: checked ? [0] : undefined
    });

    setQuery(newValue);
  }, [checked]);

  return (
    <div id="meGigFilter" onClick={changeCheckBox}>
      <span className="filterText">Jobs I can apply for</span>
      <div className="checkboxContainer">
        <input
          className="customCheckbox"
          type="checkbox"
          value="0"
          name={name}
          ref={input}
        />
        <span className={`checkmark ${checked ? "checked" : null}`}></span>
      </div>
    </div>
  );
};

export default ApplicableFilter;
