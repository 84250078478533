import axios from "axios";
import React, { useState, useEffect } from "react";
import ServiceCard from "../ServiceCard";
import NewServiceModal from "../NewServiceModal";
import Loader from "react-loader-spinner";
import { deadlineInFuture } from "../../utils/deadlineInFuture";
import OrderCard from "../OrderCard";

const serviceStatus = service => {
  if (service.status == "published") {
    return <span className="statusIcon active">Published</span>;
  } else {
    return <span className="statusIcon toPay">{service.status.toUpperCase() }</span>;
  }
};

const serviceState = service => {
  if (service.is_active == true) {
    return <span className="statusIcon active">Active</span>;
  } else {
    return <span className="statusIcon toPay">In-Active</span>;
  }
};

const OrdersList = ({ orders }) => {
  const [fadeId, setFadeId] = useState();

  return (
    <div>
      {orders?.length > 0 ? (
        orders.map(application => (
          <div
            className={`my-3 applicationCard ${
              fadeId === application.id ? "fade" : ""
            }`}
            key={application.id}
          >
            <OrderCard
              {...application}
              withModal={true}
              dummyButton={false}
              forRecruiter={false}
              showApplyButton={false}
            />
            <div className="gigExtraInfo application">
              <p>Received at: {application.received_at}</p>
              <p className="status-icon delivered">{application.state}</p>
            </div>
          </div>
        ))
      ) : (
        <p className="noJobsText">You haven't received any order yet.</p>
      )}
    </div>
  );
};

const ServicesList = ({ services, services_count }) => {
  return (
    <div>
      <NewServiceModal services_count={services_count} />
      {services?.length > 0 ? (
        services.map(service => (
          <div key={service.id} className="my-3">
            <ServiceCard
              {...service}
              showApplyButton={false}
              linkToDetailsPage={true}
            />
            <div className="gigExtraInfo">
              <p>Status: {serviceStatus(service)}</p>
              <p>State: {serviceState(service)}</p>
              <p>Orders: {service.orders_count}</p>
            </div>
          </div>
        ))
      ) : (
        <p className="noJobsText">
          You haven’t listed any services yet. Click on the button above to get
          started.
        </p>
      )}
    </div>
  );
};

const MyServices = ({ services_count }) => {
  const [services, setServices] = useState();
  const [orders, setOrders] = useState();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState("");
  const [applications, setApplications] = useState(
    !/tab/.test(window.location.href)
  );
  const fetchMyServices = () => {
    (async () => {
      try {
        setFetching(true);
        const response = await axios.get("/api/v1/profiles/my_services");
        setServices(response.data)
        setFetching(false);
      } catch (e) {
        setError(e.message);
        setFetching(false);
      }
    })();
  };
  const fetchMyOrders = () => {
    (async () => {
      try {
        setFetching(true);
        const response = await axios.get(
          "/api/v1/profiles/my_orders"
        );
        setOrders(response.data);
        setFetching(false);
      } catch (e) {
        setError(e.message);
        setFetching(false);
      }
    })();
  };

  useEffect(() => {
    applications ? fetchMyOrders() : fetchMyServices();
  }, [applications]);

  if (error.length) {
    return (
      <div id="workingGigs">
        <h1>{error}</h1>
      </div>
    );
  }
  return (
    <div id="workingGigs" className="container">

      <div id="myGigsHeader">
        <div
          onClick={() => setApplications(prevState => !prevState)}
          className={`tab ${applications ? "active" : null}`}
        >
          My Orders
        </div>
        <div
          onClick={() => setApplications(prevState => !prevState)}
          className={`tab ${applications ? null : "active"}`}
        >
          My Services
        </div>
      </div>

      <div className="gigs">
        {fetching ? (
          <div className="loaderBox">
            <Loader
              type="TailSpin"
              color={"#aaea21"}
              height={80}
              width={80}
              timeout={300000}
            />
          </div>
        ) : applications ? (
          <OrdersList
            orders={orders}
          />
        ) : (
          <ServicesList services={services} services_count={services_count} />
        )}
      </div>
    </div>
  );
};

export default MyServices;
