export const clearEmptyObject = obj => {
  const clone = Object.assign({}, obj);

  Object.keys(clone).forEach(key => {
    if (clone[key] === undefined || clone[key].length === 0) {
      delete clone[key];
    }
  });

  return clone;
};
