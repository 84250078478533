import React, { useEffect } from "react";
import { useState } from "react";
import { generateCloseIcon } from "../utils/generateIcons";
import { generateLabel } from "../utils/generateLabel";
import { blockScroll, activateScroll } from "../utils/iosScroll";

const DropDown = ({
  attribute,
  options,
  value,
  label,
  modalLabel,
  modalFooter,
  attrName,
  errors,
  influencer,
  onChange,
  wrapperClass,
  nonMandatory,
  theme = "green",
  disabled = false,
  changeValue = () => null
}) => {
  const [selected, setSelected] = useState(value || "");
  const [showOptions, setShowOptions] = useState(false);
  const [fullOpacity, setFullOpacity] = useState(false);

  const changeAttribute = value => {
    setSelected(value);
    onChange && onChange(value);
    collapseOptions(false);
  };

  useEffect(() => {
    if (attribute !== "gender") return;
    document.querySelectorAll(`.measure`).forEach(e => {
      if (e.classList.contains(selected)) {
        e.style.display = "none";
      } else {
        e.style.display = "block";
      }
    });
  }, [selected]);

  useEffect(() => {
    changeValue(selected);
  }, [selected]);

  useEffect(() => {
    if (!showOptions) return;

    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, [showOptions]);

  const showOptionsMenu = () => {
    setShowOptions(true);
    return new Promise(resolve => {
      setTimeout(resolve, 200);
    });
  };

  const collapseOptions = () => {
    setFullOpacity(false);
    setTimeout(() => {
      setShowOptions(false);
    }, 500);
  };

  const expandOptions = () => {
    showOptionsMenu().then(() => setFullOpacity(true));
  };

  return (
    <div
      className={`${wrapperClass} dropDownContainer ${
        disabled ? "hidden" : ""
      }`}
      id={`${attribute}_select`}
    >
      {showOptions ? (
        <div className="dropdown">
          <div className="container">
            <div className="optionsBackground">
              <div className={`options ${fullOpacity ? "show" : null}`}>
                <h1>{modalLabel || label || generateLabel(attribute)}</h1>
                <img
                  src={generateCloseIcon(theme)}
                  className="closeIcon"
                  onClick={collapseOptions}
                />
                {options.map(option => (
                  <div
                    className={`option ${
                      option == selected ? `selected` : ``
                    } ${theme}`}
                    key={option}
                    onClick={() => changeAttribute(option)}
                  >
                    {option}
                  </div>
                ))}
                {modalFooter ? <h3>{modalFooter}</h3> : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="inputContainer">
        <label htmlFor={`${attribute}_input`}>
          {label !== false && (label || generateLabel(attribute))}
        </label>
        <input
          id={`${attribute}_input`}
          className={`customInput ${theme} ${nonMandatory ? "extra" : ""}`}
          type="text"
          name={attrName ? attrName : `profile[${attribute}]`}
          value={selected}
          onClick={expandOptions}
          readOnly={true}
          onFocus={expandOptions}
          disabled={disabled}
        />
        {errors ? (
          <ul className="errors">
            <li>
              {Object.keys(errors)[0]} {errors[Object.keys(errors)[0]]}
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default DropDown;
