import React from "react";
import FaqList from "../utils/faq";
const Faq = ({ modal, closeModal }) => {
  return (
    <div id="faq">
      <h1>F.A.Q.</h1>
      <div className="content">
        {FaqList.map((q, i) => (
          <div key={i} className="faq">
            <h4>{q.q}</h4>
            <p>{q.a}</p>
          </div>
        ))}
        {modal ? (
          <button onClick={closeModal} className="btn inverted-green">
            Close
          </button>
        ) : (
          <a href="/" className="btn inverted-green">
            Back
          </a>
        )}
      </div>
    </div>
  );
};

export default Faq;
