import React from "react";

const Icon = () => (
  <div className="sa">
    <div className="sa-success">
      <div className="sa-success-tip"></div>
      <div className="sa-success-long"></div>
      <div className="sa-success-placeholder"></div>
      <div className="sa-success-fix"></div>
    </div>
  </div>
);

const Alert = ({ message }) => {
  return (
    <div className="alertContainer">
      <div className="alertBox">
        <Icon />
        {message}
      </div>
    </div>
  );
};

export default Alert;
