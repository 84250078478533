import React from "react";
import Home from "../images/home.png";
import HomeRed from "../images/home-red.png";
import HomeOrange from "../images/home-orange.png";
import Find from "../images/find.png";
import FindRed from "../images/find-red.png";
import FindOrange from "../images/find-orange.png";
import Service from "../images/camera.png";
import ServiceRed from "../images/camera-red.png";
import ServiceOrange from "../images/camera-orange.png";
import Work from "../images/work.png";
import WorkRed from "../images/work-red.png";
import WorkOrange from "../images/work-orange.png";
import Join from "../images/join.png";
import JoinRed from "../images/join-red.png";
import JoinOrange from "../images/join-orange.png";
import Users from "../images/users.svg";
import UsersRed from "../images/users-red.svg";
import UsersOrange from "../images/users-orange.svg";

const MyProfileLink = ({ prevPage, page }) => {
  if ([prevPage, page].includes("influencers")) {
    return (
      <a href={`/profiles/edit`}>
        <div className="linkBox">
          <img src={UsersRed} />
          <p>My Profile</p>
        </div>
      </a>
    );
  } else if ([prevPage, page].includes("creatives")) {
    return (
      <a href={`/profiles/edit`}>
        <div className="linkBox">
          <img src={UsersOrange} />
          <p>My Profile</p>
        </div>
      </a>
    );
  }

  return (
    <a href={`/profiles/edit`}>
      <div className="linkBox">
        <img src={Users} />
        <p>My Profile</p>
      </div>
    </a>
  );
};

const NavBar = ({ page, prevPage, user, recruiter }) => {
  let homeIcon = Home;
  let findIcon = Find;
  let workIcon = Work;
  let joinIcon = Join;
  let serviceIcon = Service;

  if ([prevPage, page].includes("influencers")) {
    homeIcon = HomeRed;
    findIcon = FindRed;
    workIcon = WorkRed;
    joinIcon = JoinRed;
    serviceIcon = ServiceRed;
  } else if ([prevPage, page].includes("creatives")) {
    homeIcon = HomeOrange;
    findIcon = FindOrange;
    workIcon = WorkOrange;
    joinIcon = JoinOrange;
    serviceIcon = ServiceOrange;
  }

  return (
    <div id="navbar" className={`${page} ${prevPage}`}>
      <a href="/">
        <div className="linkBox">
          <img src={homeIcon} />
          <p>Home</p>
        </div>
      </a>
      <a href="/models">
        <div className="linkBox">
          <img src={findIcon} />
          <p>Find</p>
        </div>
      </a>
      <a href="/services">
        <div className="linkBox">
          <img src={serviceIcon} />
          <p>Services</p>
        </div>
      </a>
      <a href={recruiter ? "/my_jobs" : "/jobs"}>
        <div className="linkBox">
          <img src={workIcon} />
          <p>Work</p>
        </div>
      </a>
      {user ? (
        <MyProfileLink page={page} prevPage={prevPage} user={user} />
      ) : (
        <a href="/users/sign_up">
          <div className="linkBox">
            <img src={joinIcon} />
            <p>Join</p>
          </div>
        </a>
      )}
    </div>
  );
};

export default NavBar;
