import axios from "axios";
import React, { useState, useEffect } from "react";
import GigCard from "../GigCard";
import NewGigModal from "../NewGigModal";
import Modal from "../Modal";
import Archive from "../../images/archive.png";
import Loader from "react-loader-spinner";
import { deadlineInFuture } from "../../utils/deadlineInFuture";

const gigStatus = gig => {
  if (!gig.active) {
    return <span className="statusIcon toPay">CLOSED</span>;
  }

  if (gig.status === "not_validated") {
    return <span className="statusIcon toPay">MISSING INFO</span>;
  } else if (gig.status === "validated") {
    return <span className="statusIcon toPay">TO BE CONFIRMED</span>;
  } else if (gig.status === "pending") {
    return <span className="statusIcon toPay">TO BE PAID</span>;
  } else if (gig.status === "paid" && deadlineInFuture(gig.deadline)) {
    return <span className="statusIcon active">ACTIVE</span>;
  } else if (gig.status === "coupon" && deadlineInFuture(gig.deadline)) {
    return <span className="statusIcon active">ACTIVE</span>;
  } else if (gig.status === "closed") {
    return <span className="statusIcon toPay">CLOSED</span>;
  } else {
    return <span className="statusIcon toPay">CLOSED</span>;
  }
};

const CreatedApplications = ({
  fadeId,
  gigApplications,
  archivedApplications,
  setShowArchiveModal
}) => {
  return (
    <div>
      {gigApplications?.length > 0 ? (
        gigApplications.map(application => (
          <div
            className={`my-3 applicationCard ${
              fadeId === application.id ? "fade" : ""
            }`}
            key={application.id}
          >
            <GigCard
              {...application.gig}
              withModal={true}
              dummyButton={false}
              forRecruiter={false}
              showApplyButton={false}
            />
            <div className="gigExtraInfo application">
              <p>Applied on {application.created_at}</p>
              <div onClick={() => setShowArchiveModal(application.id)}>
                <img src={Archive} alt="archive-job" />
                <span>Archive</span>
              </div>
            </div>
          </div>
        ))
      ) : archivedApplications ? (
        <p className="noJobsText">
          You can find your archived applications in SETTINGS / APPLICATIONS
          HISTORY
        </p>
      ) : (
        <p className="noJobsText">You haven't applied for any jobs yet.</p>
      )}
    </div>
  );
};

const CreatedJobs = ({ gigs }) => {
  return (
    <div>
      <NewGigModal />
      {gigs?.length > 0 ? (
        gigs.map(gig => (
          <div key={gig.id} className="my-3">
            <GigCard
              {...gig}
              showApplyButton={false}
              linkToDetailsPage={true}
            />
            <div className="gigExtraInfo">
              <p>Status: {gigStatus(gig)}</p>
              {["paid", "closed", "coupon"].includes(gig.status) ? (
                <p>
                  Applications: {gig.applications_count}/{gig.max_application}
                </p>
              ) : null}
            </div>
          </div>
        ))
      ) : (
        <p className="noJobsText">
          You haven’t listed any jobs yet. Click on the button above to get
          started.
        </p>
      )}
    </div>
  );
};

const MyGigs = ({ recruiter }) => {
  const [archivedApplications, setArchivedApplications] = useState();
  const [fadeId, setFadeId] = useState();
  const [gigs, setGigs] = useState();
  const [showArchiveModal, setShowArchiveModal] = useState();
  const [gigApplications, setGigApplications] = useState();
  const [fetching, setFecthing] = useState(false);
  const [archiving, setArchiving] = useState(false);
  const [error, setError] = useState("");
  const [applications, setApplications] = useState(
    !/tab/.test(window.location.href) && recruiter !== true
  );
  const fetchCreatedJobs = () => {
    (async () => {
      try {
        setFecthing(true);
        const response = await axios.get("/api/v1/profiles/my_jobs");
        setGigs(response.data);
        setFecthing(false);
      } catch (e) {
        setError(e.message);
        setFecthing(false);
      }
    })();
  };
  const fetchAppliedJobs = () => {
    (async () => {
      try {
        setFecthing(true);
        const response = await axios.get(
          "/api/v1/gig_applications/my_applications"
        );
        setGigApplications(response.data.applications);
        setArchivedApplications(response.data.archived_applications);
        setFecthing(false);
      } catch (e) {
        setError(e.message);
        setFecthing(false);
      }
    })();
  };

  const fadeCard = async id => {
    setFadeId(id);
    return new Promise(resolve => {
      setTimeout(() => {
        setFadeId();
        resolve();
      }, 1000);
    });
  };

  const archiveApplication = id => {
    (async () => {
      try {
        setArchiving(true);
        const response = await axios.patch(
          `/api/v1/gig_applications/${id}/archive`
        );
        fadeCard(id).then(() => {
          setGigApplications(prevState =>
            prevState.filter(a => a.id !== response.data.id)
          );
        });
        setArchiving(false);
        setArchivedApplications(true);
        setShowArchiveModal();
      } catch (e) {
        setError(e.message);
        setArchiving(false);
      }
    })();
  };

  useEffect(() => {
    applications ? fetchAppliedJobs() : fetchCreatedJobs();
  }, [applications]);

  if (error.length) {
    return (
      <div id="workingGigs">
        <h1>{error}</h1>
      </div>
    );
  }
  return (
    <div id="workingGigs" className="container">
      {showArchiveModal ? (
        <Modal close={() => setShowArchiveModal()}>
          <div className="container">
            <p>
              Are you sure you want to archive this job application for
              {` ${
                gigApplications.find(a => a.id === showArchiveModal)?.gig?.title
              }`}
              ?
              <br />
              <br />
              The list of the archived job applications is available under
              SETTINGS/ ARCHIVED JOB APPLICATIONS
            </p>
            {archiving ? (
              <Loader
                type="TailSpin"
                color={"#aaea21"}
                height={80}
                width={80}
                timeout={300000}
              />
            ) : (
              <div>
                <button
                  onClick={() => archiveApplication(showArchiveModal)}
                  className="btn btn-green"
                >
                  ARCHIVE
                </button>
                <button
                  className="btn inverted-green"
                  onClick={() => setShowArchiveModal()}
                >
                  Back
                </button>
              </div>
            )}
          </div>
        </Modal>
      ) : null}
      {recruiter ? null : (
        <div id="myGigsHeader">
          <div
            onClick={() => setApplications(prevState => !prevState)}
            className={`tab ${applications ? "active" : null}`}
          >
            JOB APPLICATIONS
          </div>
          <div
            onClick={() => setApplications(prevState => !prevState)}
            className={`tab ${applications ? null : "active"}`}
          >
            POSTED JOBS
          </div>
        </div>
      )}
      <div className="gigs">
        {fetching ? (
          <div className="loaderBox">
            <Loader
              type="TailSpin"
              color={"#aaea21"}
              height={80}
              width={80}
              timeout={300000}
            />
          </div>
        ) : applications ? (
          <CreatedApplications
            fadeId={fadeId}
            archivedApplications={archivedApplications}
            setShowArchiveModal={setShowArchiveModal}
            gigApplications={gigApplications}
          />
        ) : (
          <CreatedJobs gigs={gigs} />
        )}
      </div>
    </div>
  );
};

export default MyGigs;
