const HEIGHT_LIST = [
  { text: "< 160 cm", value: 0 },
  { text: "161-170 cm", value: 1 },
  { text: "171-180 cm", value: 2 },
  { text: "> 180 cm", value: 3 }
];
const WAIST_LIST = [
  { text: "< 27 in", value: 0 },
  { text: "27-30 in", value: 1 },
  { text: "31-33 in", value: 2 },
  { text: "34-36 in", value: 3 },
  { text: "> 36 in", value: 4 }
];
const HIPS_LIST = [
  { text: "< 40 in", value: 0 },
  { text: "41-60 in", value: 1 },
  { text: "61-70 in", value: 2 },
  { text: "> 70 in", value: 3 }
];
const SHOES_LIST = [
  { text: "< 35 EU", value: 0 },
  { text: "36-39 EU", value: 1 },
  { text: "40-42 EU", value: 2 },
  { text: "> 42 EU", value: 3 }
];
const EYES_LIST = [
  { text: "Hazel", value: 0 },
  { text: "Blue", value: 1 },
  { text: "Black", value: 2 },
  { text: "Green", value: 3 },
  { text: "Brown", value: 4 },
  { text: "Grey", value: 5 },
  { text: "Other", value: 5 }
];

const GENDER_LIST = [
  { text: "Male", value: 0 },
  { text: "Female", value: 1 },
  { text: "Non-binary", value: 2 }
];
export const AGE_LIST = [
  { text: "18-20", value: 0 },
  { text: "21-25", value: 1 },
  { text: "26-30", value: 2 },
  { text: "31-35", value: 3 },
  { text: "35+", value: 4 }
];
const HAIR_LIST = [
  { text: "Blonde", value: 0 },
  { text: "Brown", value: 1 },
  { text: "Red", value: 2 },
  { text: "Black", value: 3 },
  { text: "Grey", value: 4 },
  { text: "Other", value: 5 }
];
const CATEGORY_LIST = [
  { text: "Swim", value: 0 },
  { text: "Curve", value: 1 },
  { text: "Fashion", value: 2 },
  { text: "Petite", value: 3 },
  { text: "Sports/Fitness", value: 4 },
  { text: "Timeless", value: 5 },
  { text: "Skincare", value: 6 }
];

const SECTOR_LIST = [
  { text: "Beauty/Grooming", value: 0 },
  { text: "Lifestyle", value: 1 },
  { text: "Travel", value: 2 },
  { text: "Celebrity", value: 3 },
  { text: "Food", value: 4 },
  { text: "Fitness/Sports", value: 5 },
  { text: "Fashion", value: 6 },
  { text: "Blogger", value: 7 },
  { text: "Photo/Video", value: 8 }
];
const ENGAGEMENT_LIST = [
  { text: "<1%", value: 0 },
  { text: "1-3%", value: 1 },
  { text: "3-6%", value: 2 },
  { text: "6-10%", value: 3 },
  { text: "10%+", value: 4 }
];
const INSTAGRAM_FOLLOWERS_LIST = [
  { text: "< 1k", value: 0 },
  { text: "1k - 10k", value: 1 },
  { text: "10k - 50k", value: 2 },
  { text: "50k - 100k", value: 3 },
  { text: "100k - 500k", value: 4 },
  { text: "500k - 1M", value: 5 },
  { text: "1M - 5M", value: 6 },
  { text: "> 5M", value: 7 }
];
const BUST_CHEST_LIST = [
  { text: "< 28 in", value: 0 },
  { text: "28-30 in", value: 1 },
  { text: "31-33 in", value: 2 },
  { text: "34-36 in", value: 3 },
  { text: "> 36 in", value: 4 }
];

const MODEL_CATEGORY_LIST = [
  { text: "Development", value: 0 },
  { text: "Professional", value: 1 },
  { text: "Signed", value: 2 }
];

const GIG_MODEL_CATEGORY_LIST = [
  { text: "Development", value: 0 },
  { text: "Professional", value: 1 },
  { text: "Signed", value: 2 },
  { text: "Any", value: 3 }
];

export const GIGS_FOR_ME = [{ text: "Gig I can Apply for", value: 0 }];
export const Verified_User = [{ text: "Verified Users", value: 0 }];

const COMPLEXION_LIST = [
  { text: "Asian", value: 0 },
  { text: "Black", value: 1 },
  { text: "Caucasian", value: 2 },
  { text: "South Asian", value: 3 },
  { text: "Latino", value: 4 },
  { text: "Other", value: 5 }
];

const SIZE_LIST = [
  { text: "X-small", value: 0 },
  { text: "Small", value: 1 },
  { text: "Medium", value: 2 },
  { text: "Large", value: 3 },
  { text: "X-large", value: 4 }
];

export const MODELS_FILTER = [
  {
    text: "Model Type",
    list: MODEL_CATEGORY_LIST,
    name: "model_type",
    basic: true
  },
  {
    text: "Category",
    list: CATEGORY_LIST,
    name: "model_category",
    basic: true
  },
  { text: "Gender", list: GENDER_LIST, name: "gender", basic: true },
  { text: "Clothing (avg.)", list: SIZE_LIST, name: "size", basic: true },
  {
    text: "Complexion",
    list: COMPLEXION_LIST,
    name: "complexion",
    basic: true
  },
  { text: "Hair Color", list: HAIR_LIST, name: "hair", basic: true },
  { text: "Height", list: HEIGHT_LIST, name: "height", basic: true },
  { text: "Waist", list: WAIST_LIST, name: "waist", basic: false },
  { text: "Eyes", list: EYES_LIST, name: "eyes", basic: false },
  { text: "Shoe Size", list: SHOES_LIST, name: "shoes", basic: false },
  { text: "Chest/Bust", list: BUST_CHEST_LIST, name: "chest", basic: false },
  { text: "Hips", list: HIPS_LIST, name: "hips", basic: false }
];

const WORK_TYPE_LIST = [
  { text: "Models", value: 0 },
  { text: "Influencers", value: 1 },
  { text: "Photographers", value: 2 },
  { text: "Videographers", value: 3 },
  { text: "Stylist/MUA", value: 4 }
];

const SERVICES_CATEGORY_LIST = [
  { text: "Content Creation", value: 0 },
  { text: "Id Post", value: 1 },
  { text: "Tiktok Post", value: 2 },
  { text: "Youtube Post", value: 3 },
  { text: "In Person", value: 4 }
];

const SERVICES_DELIVERY_TIME_LIST = [
  {text: "Less than 7 days", value: 0}, {text: "Over 7 days", value: 1}
];

const KIND_LIST = [
  { text: "Paid", value: 0 },
  { text: "Collaboration", value: 1 },
  { text: "Gifting", value: 2 }
];

export const STYLIST_SECTOR_LIST = [
  { text: "Fashion", value: 0 },
  { text: "Commercial", value: 1 },
  { text: "Editorial", value: 2 },
  { text: "Food / Drink", value: 3 }
];

export const CREATIVE_SECTOR_LIST = [
  { text: "Fashion", value: 0 },
  { text: "Commercial", value: 1 },
  { text: "Editorial", value: 2 },
  { text: "Food / Drink", value: 3 },
  { text: "Adventure / Travel", value: 4 },
  { text: "Sport / Action", value: 5 },
  { text: "Documentary / Event", value: 6 },
  { text: "Architecture", value: 7 }
];

const CREATIVE_EXPERIENCE_LIST = [
  { text: "<1 years", value: 0 },
  { text: "1-2 years", value: 1 },
  { text: "2-5 years", value: 2 },
  { text: "5 years +", value: 3 }
];

const CREATIVE_DRONE_LIST = [
  { text: "Can fly drone", value: 0 },
  { text: "Can't fly drone", value: 1 }
];
const GIG_CREATIVE_DRONE_LIST = [
  { text: "Drone needed", value: 0 },
  { text: "No drone", value: 1 }
];
const GIG_CREATIVE_STUDIO_LIST = [
  { text: "Studio needed", value: 0 },
  { text: "No studio", value: 1 }
];

const CREATIVE_STUDIO_LIST = [
  { text: "Can shoot studio", value: 0 },
  { text: "Can't shoot studio", value: 1 }
];

const CREATIVE_TYPES = [
  { text: "Photo", value: 0 },
  { text: "Video", value: 1 },
  { text: "Photo / Video", value: 2 },
  { text: "Stylist / MUA", value: 3 }
];

export const INFLUENCERS_FILTER = [
  { text: "Sector", list: SECTOR_LIST, name: "sector" },
  { text: "Gender", list: GENDER_LIST, name: "gender" },
  {
    text: "Followers",
    list: INSTAGRAM_FOLLOWERS_LIST,
    name: "ig_followers"
  },
  { text: "Engagement", list: ENGAGEMENT_LIST, name: "engagement" }
];
export const GIGS_FILTER = [
  { text: "Creative Type", list: WORK_TYPE_LIST, name: "work_type" },
  { text: "Paid/Collab", list: KIND_LIST, name: "kind" },
  { text: "Gender", list: GENDER_LIST, name: "gender" },
  { text: "Model Type", list: GIG_MODEL_CATEGORY_LIST, name: "model_type" },
  { text: "Drone", list: GIG_CREATIVE_DRONE_LIST, name: "drone" },
  { text: "Studio", list: GIG_CREATIVE_STUDIO_LIST, name: "studio" }
];

export const SERVICES_FILTER = [
  { text: "Gender", list: GENDER_LIST, name: "gender" },
  { text: "Category", list: SERVICES_CATEGORY_LIST, name: "category" },
  { text: "Delivery Time", list: SERVICES_DELIVERY_TIME_LIST, name: "delivery_time" }
];

export const CREATIVES_FILTER = [
  { text: "Type", list: CREATIVE_TYPES, name: "creative_type", basic: true },
  {
    text: "Sector",
    list: CREATIVE_SECTOR_LIST,
    name: "media_sector",
    basic: true
  },
  {
    text: "Experience",
    list: CREATIVE_EXPERIENCE_LIST,
    name: "media_experience",
    basic: true
  },
  { text: "Drone", list: CREATIVE_DRONE_LIST, name: "drone", basic: true },
  { text: "Studio", list: CREATIVE_STUDIO_LIST, name: "studio", basic: true }
];
export const GRAY_BG =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQMAAADCCAMAAAB6zFdcAAAAA1BMVEU3Nzeu5Mv0AAAASElEQVR4nO3BMQEAAADCoPVPbQwfoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3AcUIAAFkqh/QAAAAAElFTkSuQmCC";
export const NEW_JOB_LINKS = [
  {
    text: "Models",
    link: "/jobs/new?work_type=modelling",
    className: "btn inverted-green"
  },
  {
    text: "Influencers",
    link: "/jobs/new?work_type=influence",
    className: "btn inverted-red"
  },
  {
    text: "Creatives",
    link: "/jobs/new?work_type=creatives",
    className: "btn inverted-orange"
  },
  {
    text: "Agencies",
    link: "/agency_proposals/new",
    className: "btn inverted-green"
  }
];
export const COLORS = {
  red: "#ef4243",
  yellow: "#aaea21",
  orange: "#E67E22"
};

export const DATES = Array.from(Array(31).keys()).map((_, i) => ++i);

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const BUDGETS = ["< $100", "Around $100", "> $200"];
