import axios from "axios";
import React, { useState, useEffect } from "react";
import Alert from "./Alert";

import { blockScroll, activateScroll } from "../utils/iosScroll";
import CloseIcon from "../images/close.png";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";

const JobInvitation = ({ creativeIg, setShowOffer, theme }) => {
  const [message, setMessage] = useState("");
  const [clickedJobs, setClickedJobs] = useState([]);
  const { red, yellow } = COLORS;
  const [jobs, setJobs] = useState();

  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/v1/profiles/jobs_for_invitation?ig_handle=${creativeIg}`)
      setJobs(response.data)
    })();
  }, []);

  const invitationHandler = async (jobID) => {
    setClickedJobs([...clickedJobs, jobID]);
    try {
      const response = await axios.get(`/api/v1/profiles/send_invitation?job_id=${jobID}&ig_handle=${creativeIg}`);
      console.log(response)
      setMessage(response.data.message);

      setTimeout(() => {
        setMessage("");
      }, 4000);

    } catch (e) {
      console.error(e);
      setMessage(e);
    }
  };

  const createJobHandler = () => {
    window.location.assign("/jobs");
  }

  useEffect(() => {
    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, []);

  return (
    <div id="job-invitation" className="container">
      <img
        src={CloseIcon}
        id="job-invitation-close"
        className="closeIcon"
        onClick={() => setShowOffer(false)}
      />
      <h3 className="text-center">Invite for your jobs</h3>
      {jobs ? (
        <div className="jobsList">
          { jobs.length > 0 ?  (
            jobs.map((job, i) => (
              <>
                <div className="jobItem" id={`job_${i}`}>
                  <div className="leftContainer">
                    <div className="jobDetails">
                      <h3 className={`title ${theme}`}>
                        {job.title}
                      </h3>
                      <p>Deadline: {`${job.deadline}`}</p>
                    </div>
                  </div>
                  <div className="rightContainer">
                    <button
                      disabled={job.email_send || clickedJobs.includes(job.id)}
                      type="button"
                      className={`invitation-btn ${theme}`}
                      id={`invitation_${job.id}`}
                      onClick={() => invitationHandler(job.id)}
                    >
                      {job.email_send || clickedJobs.includes(job.id) ? "Invited" : "Send Invitation"}
                    </button>
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="">
              <p>
                You have no jobs available.
              </p>
              <button
                type="button"
                className={`invitation-btn btn ${theme}`}
                onClick={() => {createJobHandler()}}
              >
                {"Create A Job"}
                </button>
            </div>
          )
          }
        </div>
      ) : (
        <div className="gridLoaderBox">
          <Loader
            type="TailSpin"
            color={ theme === "models" ? yellow : red}
            height={80}
            width={80}
            timeout={300000}
          />
        </div>
      )}
      {message !== "" && <Alert message={message} />}
    </div>
  );
};

export default JobInvitation;
