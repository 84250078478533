import React from "react";

const NewServiceModal = ({
                       buttonClasses = "",
                       type = "models"

                     }) => {

  let color = "green";

  if (type === "influencers") {
    color = "red";
  } else if (type === "creatives") {
    color = "orange";
  }

  return (
    <div className="newServiceModal">
      <a href='/services/new?work_type=modelling' className={`btn inverted-${color} ${buttonClasses}`}> Add a new service</a>
    </div>
  );
};

export default NewServiceModal;
