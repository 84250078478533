import React, { useEffect, useState } from "react";

const ModelTypesSelection = ({ types, values }) => {
  const [selected, setSelected] = useState(values);

  return (
    <div id="modelTypesSelection">
      <label>Model Type</label>
      <div className="options">
        {types.map(type => (
          <div
            onClick={e => {
              e.stopPropagation();
              setSelected(prev => {
                if (prev.includes(type)) {
                  return prev.filter(t => t !== type);
                } else {
                  return [...prev, type];
                }
              });
            }}
            className={`labelBox ${selected.includes(type) ? "selected" : ""}`}
            key={type}
          >
            <label>{type}</label>
            <input
              id={type}
              hidden
              readOnly={true}
              checked={selected.includes(type)}
              type="checkbox"
              name="gig[model_types][]"
              value={type}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModelTypesSelection;
