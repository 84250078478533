import React, { useState } from "react";
import Profile from "./Profile";
import TopBar from "./../TopBar";
import NavBar from "./../NavBar";

const SrProfile = ({ data, user, type }) => {
  const [theme, setTheme] = useState(type);

  const changeView = page => {
    window.location.pathname = `/${page}`;
  };

  return (
    <div id="srShow">
      <TopBar page={theme} color="black" />
      <Profile
        data={data}
        type={theme}
        hideHeader={true}
        changeView={changeView}
        setTheme={setTheme}
        navigate={true}
        sr={true}
      />
      <NavBar page={theme} igHandle={data.ig_handle} user={user} />
    </div>
  );
};

export default SrProfile;
