const initCopyLink = () => {
  const image = document.querySelector(".profileUrl img");
  image?.addEventListener("click", () => {
    const url = document.querySelector(".profileUrl input");
    url.select();
    url.setSelectionRange(0, 99999);
    document.execCommand("copy");
    alert("Copied the text: " + url.value);
    setTimeout(() => {
      document.getSelection().collapseToEnd();
    }, 100);
  });
};

export { initCopyLink };
