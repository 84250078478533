import React, { useState, useEffect, useRef } from "react";
import GigCardPlaceHolder from "../GigCardPlaceHolder";
import { useMediaQuery } from "react-responsive";
import { axios } from "../../utils/axios";
import { toQueryParams } from "../../utils/toQueryParams";
import { clearEmptyObject } from "../../utils/clearEmptyObject";
import ApplicableFilter from "../ApplicableFilter";
import ActiveFilters from "../ActiveFilters";
import Filter from "../Filter";
import FilterLocation from "../FilterLocation";
import GigCard from "../GigCard";
import NewGigModal from "../NewGigModal";
import InfiniteScroll from "react-infinite-scroll-component";

const Gigs = ({ page, recruiter = false, display_jobs = false }) => {
  const [gigs, setGigs] = useState([]);
  const [query, setQuery] = useState({});
  const [offset, setOffset] = useState(1);
  const [stopFetching, setStopFetching] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState();
  const [mounted, setmounded] = useState(false);

  const isTableOrMobile = useMediaQuery({
    query: "(min-width: 0px) and (max-width: 499px)"
  });

  const filter = async page => {
    if (stopFetching || fetching || !display_jobs) return;
    setFetching(true);
    const { data } = await axios
      .get(`/api/v1/gigs?${toQueryParams(query)}&page=${page || offset}`)
      .catch(() =>
        setError(
          "Something bad happened. Please refresh the page to try again."
        )
      );
    setStopFetching(data.length === 0);
    setGigs(prev => [...prev, ...data]);
    setFetching(false);
  };

  useEffect(() => {
    if (offset === 1) return;
    filter();
  }, [offset]);

  useEffect(() => {
    if (!mounted) return;
    setGigs([]);
    setOffset(1);
    setStopFetching();
  }, [query]);

  useEffect(() => {
    if (fetching || !mounted) return;
    filter(1);
  }, [query, stopFetching]);

  useEffect(() => {
    setmounded(true);
    filter();
  }, []);

  const updateQueryString = (value, attr) => {
    const newValue = clearEmptyObject({
      ...query,
      [attr]: value
    });
    setQuery(newValue);
  };

  return (
    <div className="gigCards wide">
      <div className="">
        <div className="profilesContainer ">
          {isTableOrMobile ? <NewGigModal /> : null}
          <div className="filterContainer">
            {isTableOrMobile ? null : <NewGigModal />}
            {isTableOrMobile ? null : (
              <div className="specialFilter">
                <FilterLocation
                  updateQuery={updateQueryString}
                  prevLocation={query.location}
                />
                <ApplicableFilter setQuery={setQuery} query={query} />
              </div>
            )}
            <Filter
              query={query}
              setQuery={setQuery}
              updateQuery={updateQueryString}
              resetFilter={() => setQuery({})}
              type="gigs"
            />
          </div>
          <div className="body">
            <ActiveFilters query={query} type="gigs" setQuery={setQuery} />

            <InfiniteScroll
              dataLength={gigs.length}
              next={() => {
                setOffset(prev => prev + 1);
              }}
              hasMore={!stopFetching}
              loader={<GigCardPlaceHolder count={3} />}
            >
              {gigs.length === 0 && !fetching ? (
                <div className="noResultsBox">
                  Your search returned no results, try changing your filters.
                </div>
              ) : null}
              {gigs.map((gig, i) => (
                <GigCard
                  gig_id={gig.id}
                  {...gig}
                  withModal={true}
                  key={i}
                  dummyButton={false}
                  showApplyButton={recruiter === false}
                  indexPage={true}
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gigs;
