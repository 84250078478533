export default [
  {
    q: "WHAT IS AGENCY.PICS?",
    a:
      "Agency.Pics is a global talent platform for Models, Influencers and Creatives. Apply for international jobs instantly and search talent from around the globe."
  },
  {
    q: "WHY SHOULD I JOIN?",
    a:
      "We know how hard it can be for freelance talent to be seen by the right people. We run a number of powerful marketing campaigns to make sure Agency.Pics, and your profile, is seen by the exact people that want to book you - saving you time in finding your next job."
  },
  {
    q: "HOW DOES IT WORK?",
    a:
      "It’s really simple! Sign-up, upload your images, choose your plan and share your profile with the world."
  },
  {
    q: "DO YOU CHARGE AGENCY FEES?",
    a: "Never. We simply put you in contact with potential clients."
  },
  {
    q: "ARE YOU A MODEL AGENCY?",
    a:
      "No. Agency.Pics is a platform to search for work and be found for work, anywhere in the world. We have a number of partner agencies that may approach you for work via the platform but you will deal directly with them and not with Agency.Pics."
  },
  {
    q: "WHO CAN SEE MY PROFILE?",
    a:
      "Your profile can be searched for and viewed by any Agency.Pics user if it is set to public. You can set your profile to private anytime you wish."
  },
  {
    q: "CAN I HIDE MY PROFILE?",
    a:
      "Yes, instantly! Hide your profile and update any images or details via the ‘My Profile’ tab."
  },
  {
    q: "CAN I CANCEL MY ACCOUNT?",
    a: "You can cancel your account any time you wish via the ‘My Profile’ tab."
  },
  {
    q: "CAN I REQUEST A REFUND?",
    a:
      "Yes. If you aren’t happy with your account we can refund you for any whole months that your profile is not active. Email info@agency.pics for more information."
  }
];
