const moneyFormatter = amount => {
  if (!amount) return;
  const currencyOptions = new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "EUR"
  }).resolvedOptions();

  const value = amount.toLocaleString("us-US", {
    ...currencyOptions,
    style: "decimal"
  });

  return value.split(".")[0];
};
export { moneyFormatter };
