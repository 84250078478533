import React, { useState, useRef, useEffect } from "react";
import ServiceModal from "./ServiceModal";
import ShareService from "./ShareService";
import { moneyFormatter } from "../utils/moneyFormatter";
import GlobalRed from "../images/global-red.png";
import GlobalGreen from "../images/global-green.png";
import GlobalOrange from "../images/global-orange.png";
import service_bg from "../images/service-bg.jpeg";

const ServiceCard = ({
                   work_type: workType,
                   id,
                   title,
                   category,
                   gender,
                   kind,
                   cost,
                   delivery_time,
                   status,
                   has_applied: hasApplied,
                   cover_image,
                   block_share: blockShare,
                   blockModal,
                   hideViewMore,
                   linkToDetailsPage,
                   display_creative_type: displayCreativeType,
                   soloButton = false,
                   indexPage = false
                 }) => {
  const [showGigModal, setShowGigModal] = useState(false);
  const image = useRef();
  const cardWrapper = useRef();
  const creativeTypeCheck = () => [2, 3, 4].includes(workType);

  useEffect(() => {
    if (image.current.width < 0) return;
    const width = image.current.offsetWidth;
    cardWrapper.current.style.height = `${(width / 16) * 9}px`;
  }, [image.current?.width]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const activeGigId = params.get("active");
    if (
      activeGigId?.match(/\d+/) &&
      parseInt(activeGigId) === id &&
      indexPage
    ) {
      setShowGigModal(true);
      window.history.pushState(
        { path: window.location.pathname },
        "",
        window.location.pathname
      );
    }
  }, []);

  const globalIcon = () => {
    if (workType === 1) {
      return GlobalRed;
    }

    if (creativeTypeCheck()) {
      return GlobalOrange;
    }

    return GlobalGreen;
  };
  const theme = () => {
    if (workType === 1) {
      return "influencers";
    }

    if (creativeTypeCheck()) {
      return "creatives";
    }

    if (kind){
      return kind;
    }

    return "models";
  };

  return (
    <>
      <div ref={cardWrapper} className="gigCard">
        {showGigModal && !hideViewMore ? (
          <ServiceModal
            id={id}
            theme={theme()}
            close={() => setShowGigModal(false)}
          />
        ) : null}
        <div className="cardContent" ref={image}>
          <img
            className="banner"
            src={ cover_image || service_bg}
            alt={`${title}-banner`}
          />
          <div className="details">
            {hasApplied ? <div className="appliedStripe">Applied</div> : null}
            <div className={`pill ${kind} ${theme()}`}>
              {`$${moneyFormatter(cost)}`}
            </div>
            <h4>{title}</h4>
            <p className="location">
              <img src={ globalIcon()} />
              {category}
            </p>
              <p className="date">{delivery_time}</p>
          </div>
        </div>
        <div className="cardActions">
          {hideViewMore ? null : (
            <div
              className={`apply ${theme()}`}
              onClick={() => {
                if (showGigModal || blockModal) return;
                if (linkToDetailsPage) {
                  return (window.location.href = `/services/${id}/edit`);
                }
                setShowGigModal(true);
              }}
            >
              VIEW
            </div>
          )}
          <ShareService
            blockShare={blockShare}
            location={location}
            text="SHARE"
            dummyButton={status == "pending"}
            id={id}
            quote={`${title} on Agency.Pics`}
            soloButton={soloButton}
            theme={theme()}
            displayCreativeType={displayCreativeType + "s"}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceCard;