import React, { useRef, useEffect } from "react";
import Loader from "react-loader-spinner";

const GigCardPlaceHolder = () => {
  const image = useRef();
  const cardWrapper = useRef();

  useEffect(() => {
    if (image.current.width < 0) return;
    const width = image.current.offsetWidth;
    cardWrapper.current.style.height = `${(width / 16) * 9}px`;
  }, [image.current?.width]);

  return (
    <>
      <div ref={cardWrapper} className="gigCard">
        <div className="cardContent" ref={image}>
          <div className="loaderBox">
            <Loader
              color={"#aaea21"}
              type="TailSpin"
              height={50}
              width={50}
              timeout={3000}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GigCardPlaceHolder;
