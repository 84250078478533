import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import ProfileCard from "./ProfileCard";
import GigCard from "./GigCard";
import Profile from "./pages/Profile";
import { COLORS } from "../utils/constants";
import { capitalize } from "../utils/capitalize";
import { moneyFormatter } from "../utils//moneyFormatter";
import { blockScroll, activateScroll } from "../utils/iosScroll";
import { cleanWebsite } from "../utils/cleanWebsite";

const { red, yellow, orange } = COLORS;

const ProfileModal = ({ setShowProfile, igHandle, theme }) => {
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/v1/${igHandle}`);
      setProfile(response.data);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, []);

  return (
    <div className="profileModalBackground">
      <div className="profileModal">
        {profile ? (
          <Profile
            setTheme={() => {}}
            data={profile}
            type={theme}
            page={theme}
            hideHeader={true}
            closeModal={() => setShowProfile()}
          />
        ) : (
          <div className="gridLoaderBox">
            <Loader
              type="TailSpin"
              color={theme === "models" ? yellow : red}
              height={80}
              width={80}
              timeout={300000}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const GigDetail = ({ id, forRecruiter, theme }) => {
  const [gig, setGig] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [showProfile, setShowProfile] = useState();
  const [error, setError] = useState("");
  const color = () => {
    if (theme === "influencers") {
      return red;
    } else if (theme === "creatives") {
      return orange;
    }

    return yellow;
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `/api/v1/jobs/${id}/${forRecruiter ? "recruiter_" : ""}details`
        );
        setGig(response.data);
        setProfiles(response.data.profiles);
        setFetching(false);
      } catch (e) {
        setError(e.message);
      }
    })();
  }, []);

  if (error) {
    return (
      <div className="gigDetail">
        <h3>{error}</h3>
      </div>
    );
  }

  if (fetching) {
    return (
      <div id="gigDetail">
        <div className="gigLoaderBox gig">
          <Loader
            type="TailSpin"
            color={color()}
            height={60}
            width={60}
            timeout={300000}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="gigDetail">
        {showProfile ? (
          <ProfileModal
            setShowProfile={setShowProfile}
            igHandle={showProfile}
            theme={theme}
          />
        ) : null}
        <h1>
          <span className="font-weight-normal">
            JOB DETAILS: #AP{id.toString().padStart(5, "0")}
          </span>
        </h1>
        {Object.keys(gig).length > 0 ? (
          <GigCard
            {...gig}
            showApplyButton={false}
            dummyButton={true}
            soloButton={true}
            hideViewMore={true}
          />
        ) : null}
        <div>
          {forRecruiter && gig.status === "pending" && (
            <div className="jobPayment">
              <a
                href={`/gigs/${gig.id}/payments/new`}
                className={`btn pay-gig btn-${theme}`}
              >
                COMPLETE JOB LISTING
              </a>
            </div>
          )}
          {forRecruiter && gig.status === "not_validated" && (
            <div className="jobPayment">
              <a
                href={`/jobs/${gig.id}/edit`}
                className={`btn pay-gig btn-${theme}`}
              >
                COMPLETE JOB LISTING
              </a>
            </div>
          )}
          <div className="gigInfo">
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">GENDER</p>
              <p className="attrValue">{gig.gender}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">JOB TYPE</p>
              <p className="attrValue">{capitalize(gig.kind)}</p>
            </div>
            {gig.kind === "paid" && (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">BUDGET PER CREATIVE (USD)</p>
                <p className="attrValue">${moneyFormatter(gig.budget)}</p>
              </div>
            )}
            {gig.model_types_list && (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">MODEL TYPES</p>
                <p className="attrValue">{gig.model_types_list}</p>
              </div>
            )}
            {gig.work_type === 0 && (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">MODEL CATEGORIES</p>
                <p className="attrValue">{gig.model_categories_list}</p>
              </div>
            )}
            {gig.work_type === 1 && (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">INFLUENCER SECTOR</p>
                <p className="attrValue">{gig.influencer_sectors_list}</p>
              </div>
            )}
            {[2, 3, 4].includes(gig.work_type) && (
              <>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">CREATIVE TYPE</p>
                  <p className="attrValue">{gig.display_creative_type}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">SECTOR</p>
                  <p className="attrValue">{gig.creative_sector}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">EXPERIENCE</p>
                  <p className="attrValue">{gig.media_experience}</p>
                </div>
              </>
            )}
            {gig.work_type === 2 && (
              <>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">NUMBER OF IMAGES</p>
                  <p className="attrValue">{gig.images}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">DRONE</p>
                  <p className="attrValue">{gig.drone}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">STUDIO</p>
                  <p className="attrValue">{gig.studio}</p>
                </div>
              </>
            )}
            {gig.work_type === 3 && (
              <>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">NUMBER OF VIDEOS</p>
                  <p className="attrValue">{gig.number_of_videos}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">VIDEO LENGTH (SEC.)</p>
                  <p className="attrValue">{gig.length_of_videos}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">DRONE</p>
                  <p className="attrValue">{gig.drone}</p>
                </div>
                <div className={`fieldContainer ${theme}`}>
                  <p className="attrLabel">STUDIO</p>
                  <p className="attrValue">{gig.studio}</p>
                </div>
              </>
            )}
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">MIN FOLLOWERS</p>
              <p className="attrValue">{gig.display_min_followers}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">SHOOTING TIME</p>
              <p className="attrValue">{gig.shooting_time}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">DEADLINE</p>
              <p className="attrValue">{gig.str_deadline}</p>
            </div>
            {gig.ig_handle || gig.ig_account || forRecruiter ? (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">IG ACCOUNT</p>
                <p className="attrValue">
                  <a
                    href={`https://instagram.com/${
                      gig.ig_handle?.replace("@", "") ||
                      gig.ig_account?.replace("@", "")
                    }`}
                    target="blank"
                  >
                    {gig.ig_handle || gig.ig_account}
                  </a>
                </p>
              </div>
            ) : null}
            {gig.website_url || forRecruiter ? (
              <div className={`fieldContainer ${theme}`}>
                <p className="attrLabel">WEBSITE</p>
                <p className="attrValue">
                  <a
                    href={
                      cleanWebsite(gig.website) || cleanWebsite(gig.website_url)
                    }
                    target="blank"
                  >
                    {gig.website || gig.website_url}
                  </a>
                </p>
              </div>
            ) : null}
            {gig.description ? (
              <div>
                <p className="attrLabel description">JOB DESCRIPTION</p>
                <p className="descriptionContent">{gig.description}</p>
              </div>
            ) : null}
            {gig.creator && (
              <div>
                <a
                  className={`btn btn-${theme} font-bold block text-center`}
                  href={`/${gig.creator}?back=jobs/${id}`}
                >
                  See job creator's profile
                </a>
              </div>
            )}
          </div>
        </div>
        {forRecruiter ? (
          <>
            <div>
              <p className="inline-block bold-text">APPLICATIONS</p>
              <div className="profilesGrid">
                {profiles.length > 0 ? (
                  profiles.map(profile => (
                    <div key={profile.id}>
                      <ProfileCard
                        profile={profile}
                        theme={theme}
                        setShowProfile={setShowProfile}
                      />
                    </div>
                  ))
                ) : (
                  <div>No applications yet</div>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default GigDetail;
