import React, { useEffect } from "react";
import { blockScroll, activateScroll } from "../utils/iosScroll";
import { generateCloseIcon } from "../utils/generateIcons";
import { isIos } from "../utils/isIos";

const RModal = ({ hideBackButton, close, children, type, className }) => {
  const colorSelect = () => {
    if (type === "influencers") {
      return "red";
    } else if (type === "creatives") {
      return "orange";
    }
    return "green";
  };

  useEffect(() => {
    if (!isIos()) return;
    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, []);

  return (
    <div className={`rmodal ${className}`}>
      <div className="container">
        <img
          src={generateCloseIcon(type)}
          className="closeIcon"
          onClick={close}
        />
        <div className="modalBody">{children}</div>
        {hideBackButton ? null : (
          <button
            onClick={close}
            className={`btn inverted-${colorSelect()} closeModalBtn`}
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default RModal;
