import React, { useState, useEffect, useRef } from "react";
import RedLens from "../images/red-lens.png";
import YellowLens from "../images/yellow-lens.png";
import OrangeLens from "../images/orange-lens.png";
import iconCloseGreen from "../images/close.png";
import iconCloseRed from "../images/close-red.png";
import iconCloseOrange from "../images/close-orange.png";

const NameIdSearch = ({
  updateQuery,
  prevName,
  page,
  mobile,
  forceSearch,
  setPartialQuery
}) => {
  const [query, setQuery] = useState(prevName || "");
  const inputEl = useRef();
  const lensImage = useRef();

  let lensIcon = YellowLens;
  let closeIcon = iconCloseGreen;

  if (page === "influencers") {
    lensIcon = RedLens;
    closeIcon = iconCloseRed;
  } else if (page === "creatives") {
    lensIcon = OrangeLens;
    closeIcon = iconCloseOrange;
  }

  useEffect(() => {
    setTimeout(
      () => {
        if (!inputEl || !inputEl.current) return;
        inputEl.current.value = prevName || "";
      },
      mobile ? 200 : 0
    );
  }, [prevName]);

  useEffect(() => {
    if (!setPartialQuery) return;
    setPartialQuery(prev => ({ ...prev, name: query }));
  }, [query]);

  useEffect(() => {
    if (!inputEl) return;
    inputEl.current.value = query;
  }, [query]);

  useEffect(() => {
    if (forceSearch) {
      updateQuery(query, "name");
    }
  }, [forceSearch]);

  return (
    <div id="filterName">
      <div className="inputWrapper">
        <input
          ref={inputEl}
          type="text"
          defaultValue={prevName}
          onKeyUp={e => setQuery(e.target.value)}
          placeholder="NAME/ AP ID"
          className={`customInput`}
          autoComplete="off"
        />
        {query || prevName ? (
          <div className="icons">
            <img
              ref={lensImage}
              src={lensIcon}
              onClick={() => {
                updateQuery(query, "name");
              }}
            />
            <img
              src={closeIcon}
              onClick={() => {
                updateQuery(undefined, "name");
                setQuery("");
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NameIdSearch;
