import React from "react";
import { generateCloseIcon } from "../utils/generateIcons";

const Modal = ({
  close,
  children,
  type,
  className,
  hideCloseButton = false
}) => {
  return (
    <div className={`modalBackground ${className}`}>
      {!hideCloseButton && (
        <img
          src={generateCloseIcon(type)}
          id="modalClose"
          className="closeIcon"
          onClick={close}
        />
      )}
      <div className="modalBody">{children}</div>
    </div>
  );
};

export default Modal;
