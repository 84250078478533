import React, { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import FallBackImg from "../images/fallbackImg.png";

const ProfilePicture = ({
  data,
  setPhotoIndex,
  pic,
  index,
  type,
  expandPicture
}) => {
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState();
  const [errorImg, setErrorImg] = useState();
  const container = useRef();

  useEffect(() => {
    if (!container) return;
    setTimeout(() => {
      setHeight((container.current.offsetWidth / 4) * 5);
    }, 300);
  }, [container]);

  return (
    <div
      className="profilePicture"
      style={{ height: `${height}px` }}
      ref={container}
    >
      {height > 0 ? (
        <>
          {loading ? (
            <div className="loaderBox">
              <Loader
                type="TailSpin"
                color={type === "influencers" ? "#EF4243" : "#aaea21"}
                height={40}
                width={40}
                timeout={300000}
              />
            </div>
          ) : null}
          <img
            key={index}
            src={errorImg || pic}
            height={height}
            onClick={() =>
              (setPhotoIndex && setPhotoIndex(index)) ||
              (expandPicture && expandPicture())
            }
            alt={`${data.ig_handle}`}
            onLoad={() => setLoading(false)}
            onError={() => {
              setErrorImg(FallBackImg);
              setLoading(false);
            }}
          />
        </>
      ) : null}
    </div>
  );
};

export default ProfilePicture;
