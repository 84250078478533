import React, { useState } from "react";
import Modal from "./Modal";
import { NEW_JOB_LINKS } from "../utils/constants";

const NewGigModal = ({
  buttonClasses = "",
  type = "models",
  setGigModalIsOpen = () => null
}) => {
  const [show, setShow] = useState(false);
  let color = "green";

  if (type === "influencers") {
    color = "red";
  } else if (type === "creatives") {
    color = "orange";
  }

  const openModal = () => {
    setGigModalIsOpen(true);
    setShow(true);
  };

  const closeModal = () => {
    setGigModalIsOpen(false);
    setShow(false);
  };

  return (
    <div className="newGigModal">
      <button
        className={`btn inverted-${color} ${buttonClasses}`}
        onClick={openModal}
      >
        Add a new job
      </button>
      {show && (
        <Modal close={closeModal}>
          <div className="wrapper signedIn">
            <div className="container">
              <h3>Create a job for:</h3>
              <div className="jobsBox">
                {NEW_JOB_LINKS.map(({ text, link, className }, i) => (
                  <a href={link} className={className} key={i}>
                    {text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NewGigModal;
