import Close from "../images/close.png";
import CloseRed from "../images/close-red.png";
import CloseOrange from "../images/close-orange.png";

export const generateCloseIcon = theme => {
  if (["influencer", "influencers", "red"].includes(theme)) {
    return CloseRed;
  } else if (["orange", "photography", "videography", "creatives"].includes(theme)) {
    return CloseOrange;
  } else {
    return Close;
  }
};
