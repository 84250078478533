import React, { useState, useEffect, useRef } from "react";

const TYPES = [
  {
    title: "I'm a MODEL",
    description: "I want to add my profile on Agency.Pics and look for jobs"
  },
  {
    title: "I'm an INFLUENCER",
    description:
      "I want to add my profile on Agency.Pics, look for jobs and post job announces"
  },
  {
    title: "I'm a CREATIVE",
    description: "I want to add my profile on Agency.Pics and look for jobs"
  },
  {
    title: "I want to list a JOB",
    description: "I want to post job announces and find models and influencers"
  },
  {
    title: "I want to order services",
    description: "I want to order services."
  }
];

const UserTypeCard = ({ active, plan, i, handleClick }) => {
  const color = i => {
    switch (i) {
      case 1:
        return "red";
      case 2:
        return "orange";
      case 3:
        return "black";
      default:
        return "green";
    }
  };
  return (
    <div
      onClick={handleClick}
      className={`typeBox ${color(i)} ${active ? "active " : ""}`}
    >
      <div className={`btn btn-green `}>
        <p>{plan.title}</p>
      </div>
    </div>
  );
};

const UserTypeForm = () => {
  const [type, setType] = useState();
  const input = useRef();
  const submitRef = useRef();

  useEffect(() => {
    if (type >= 0 && type <= 4) {
      input.current.value = type;
      submitRef.current?.click();
    }
  }, [type]);

  return (
    <div id="userTypeForm">
      <div className="typeContainer">
        <div className="options">
          <input type="number" name="type" ref={input} hidden />
          {TYPES.map((plan, i) => (
            <UserTypeCard
              key={i}
              plan={plan}
              handleClick={() => setType(i)}
              active={type === i}
              i={i}
            />
          ))}
        </div>
        <input ref={submitRef} type="submit" hidden value="confirm" />
      </div>
      <p>You can change these settings at any time.</p>
    </div>
  );
};

export default UserTypeForm;
