import React, { Suspense } from "react";
import RModal from "./RModal";
const ServiceDetail = React.lazy(() => import("./ServiceDetail"));
const ServiceApplyBox = React.lazy(() => import("./ServiceApplyBox"));

const GigModal = ({ close, id, theme }) => {
  return (
    <RModal type={theme} close={close} className="gigModal">
      {id ? (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <ServiceDetail id={id} theme={theme} />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <ServiceApplyBox id={id} theme={theme} see_more_jobs={true} />
          </Suspense>
        </>
      ) : null}
    </RModal>
  );
};
export default GigModal;
