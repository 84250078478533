import React from "react";

const IndexHeader = ({ theme, changeView }) => {
  return (
    <div className={`headerContainer ${theme}`}>
      <div className="homeLinkContainer">
        <div
          onClick={() => changeView("models")}
          className={`type ${theme === "models" ? "active" : ""}`}
        >
          MODELS
        </div>
        <div
          onClick={() => changeView("influencers")}
          className={`type ${
            theme === "influencers" ? "active influencers" : ""
          }`}
        >
          INFLUENCERS
        </div>
        <div
          onClick={() => changeView("creatives")}
          className={`type ${theme === "creatives" ? "active creatives" : ""}`}
        >
          CREATIVES
        </div>
      </div>
    </div>
  );
};

export default IndexHeader;
