import React from "react";
import { clearEmptyObject } from "../utils/clearEmptyObject";
import ActiveFilter from "./ActiveFilter";
import { generateLabel } from "../utils/generateLabel";
import {
  MODELS_FILTER,
  INFLUENCERS_FILTER,
  GIGS_FILTER,
  SERVICES_FILTER,
  CREATIVES_FILTER
} from "../utils/constants";

const ActiveFilters = ({ query, setQuery, type }) => {
  let attributesType;
  if (type === "models") {
    attributesType = MODELS_FILTER;
  } else if (type === "influencers") {
    attributesType = INFLUENCERS_FILTER;
  } else if (type === "gigs") {
    attributesType = GIGS_FILTER;
  } else if (type === "services") {
    attributesType = SERVICES_FILTER;
  } else if (type === "creatives") {
    attributesType = CREATIVES_FILTER;
  }

  const removeQuery = (name, value) => {
    if (!query) return;
    let newValue;
    if (Array.isArray(query[`${name}`])) {
      newValue = { [name]: query[name].filter(ele => ele !== value) };
    } else {
      newValue = { [name]: undefined };
    }
    const newQuery = clearEmptyObject({ ...query, ...newValue });
    setQuery(newQuery);
  };

  return (
    <div
      id="activeFilters"
      className={`${Object.keys(query).length === 0 ? "empty" : ""}`}
    >
      {Object.keys(query).map((key, index) => {
        if (query[key]) {
          const attributes = attributesType.find(obj => obj.name == key);

          return (
            <ActiveFilter
              key={index}
              attrName={key}
              query={query}
              list={attributes?.list}
              generateLabel={generateLabel}
              removeQuery={removeQuery}
              type={type}
              label={generateLabel(key)}
            />
          );
        }
      })}
    </div>
  );
};

export default ActiveFilters;
