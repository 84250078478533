import React, { Suspense } from "react";
import RModal from "./RModal";
const GigDetail = React.lazy(() => import("./GigDetail"));
const GigApplyBox = React.lazy(() => import("./GigApplyBox"));

const GigModal = ({ close, id, theme }) => {
  return (
    <RModal type={theme} close={close} className="gigModal">
      {id ? (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <GigDetail id={id} theme={theme} />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <GigApplyBox id={id} theme={theme} />
          </Suspense>
        </>
      ) : null}
    </RModal>
  );
};
export default GigModal;
