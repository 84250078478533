export const cleanWebsite = website => {
  if (website) {
    if (website.includes("http")) {
      return website;
    }

    return `//${website}`;
  }

  return null;
};
