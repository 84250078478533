const calculateAlertsOffset = () => {
  setTimeout(() => {
    const alert = document.querySelector(".completeProfileAlert");
    const offset = document.querySelector("#topBar")?.offsetHeight;
    const indexPage = document.querySelector('.indexPage')
    let page;
    if (!alert) {
      page = document.querySelector("body").firstElementChild
        .nextElementSibling;
      if (page) page.style.marginTop = `${offset + 20}px`;
    } else {
      alert.style.marginTop = `${offset}px`;
      if (indexPage) alert.style.marginBottom = `-${offset - 20}px`;
      if (document.querySelector("#homeContainer")) return;
      page = document.querySelector(".completeProfileAlert")
        ?.nextElementSibling;
      page && (page.style.marginTop = "20px");
    }
  }, 100);
};

export { calculateAlertsOffset };
