import React from "react";
import DropDown from "./DropDown";
import Close from "../images/close-red.png";

const range = [
  "< 1k",
  "1k - 10k",
  "10k - 50k",
  "50k - 100k",
  "100k - 500k",
  "500k-1M",
  "1M - 5M",
  "> 5M"
];
const attributes = {
  Instagram: "ig",
  "Tik Tok": "tk",
  Facebook: "fb",
  Youtube: "yt"
};
const SocialMediaProfiles = ({ options, values, errors }) => {
  const handleClick = e => {
    const inputs = e.currentTarget.parentElement.querySelectorAll("input");
    inputs.forEach(i => (i.value = ""));
  };

  return (
    <div id="socialMediaProfiles">
      {values.map((value, i) => (
        <div key={i}>
          <div className="inputWrapper">
            <img src={Close} onClick={handleClick} className="resetField" />
            <div className="platform">
              <label htmlFor="">{value[0]} Profile</label>
              <input
                className="extra"
                type="text"
                name={`profile[${attributes[value[0]]}_handle]`}
                defaultValue={value[1]}
              />
            </div>
            <DropDown
              attribute={`${attributes[value[0]]}_followers`}
              options={range}
              value={value[2] === null ? "" : value[2]}
              label="Followers"
              nonMandatory={true}
              theme="red"
            />
          </div>
          {errors ? (
            <ul className="errors">
              {errors[`${attributes[value[0]]}_followers`] ? (
                <li>
                  {`${value[0]} followers`}{" "}
                  {errors[`${attributes[value[0]]}_followers`]}
                </li>
              ) : null}
              {errors[`${attributes[value[0]]}_handle`] ? (
                <li>
                  {`${value[0]} handle`}{" "}
                  {errors[`${attributes[value[0]]}_handle`]}
                </li>
              ) : null}
            </ul>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default SocialMediaProfiles;
