import React, { useState, useEffect } from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappIcon,
  FacebookIcon
} from "react-share";
import Clipboard from "./Clipboard";
import CloseIcon from "../images/close.png";
import CloseIconRed from "../images/close-red.png";
import { checkMobile } from "../utils/isMobile";

const ShareGig = ({
  color = "",
  location,
  id,
  text,
  quote,
  blockShare,
  dummyButton,
  displayCreativeType,
  soloButton = false,
  theme = "models"
}) => {
  const [show, setShow] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const [mount, setMount] = useState(false);
  const copyLink = async () => {
    if ("clipboard" in navigator) {
      setCopied(true);
      await navigator.clipboard.writeText(
        `${generateTitle()} ${generateUrl()}`
      );
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  const generateUrl = () =>
    `${window.location.origin}/jobs/ap${id.toString().padStart(5, "0")}`;

  const generateTitle = () => {
    let type = "Models";

    if (theme === "models") {
      type = "Models";
    } else if (theme === "influencers") {
      type = "Influencers";
    } else if (theme === "creatives") {
      type = displayCreativeType;
      if (type === "Stylist / MUAs") {
        type = "Stylists / MUAs";
      }
    }

    return `${type} wanted ${
      location === "Global" ? "Globally" : "in " + location
    }`;
  };

  const handleClick = () => {
    if (isMobile) {
      if (navigator.share) {
        navigator.share({
          title: generateTitle(),
          text: quote,
          url: generateUrl()
        });
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    if (mount) return;
    setMount(true);
    setIsMobile(checkMobile());
  }, []);

  const bgColor = soloButton ? theme : "transparent";
  return (
    <div className={`share ${bgColor}`}>
      <button
        type="button"
        onClick={() => {
          if (dummyButton || blockShare) return;
          !blockShare && handleClick();
        }}
        className="btn btn-transparent"
      >
        {text || "Share your job"}
      </button>
      {show && (
        <div className="shareContent">
          <div className={`linkModalBg ${color == "red" ? "influencers" : ""}`}>
            <div className="linkModal container">
              <div className="shareLinks">
                <FacebookShareButton
                  className="shareBtn"
                  title={generateTitle()}
                  url={generateUrl()}
                  quote={quote}
                >
                  <FacebookIcon size={32} round={true} />
                  Share on Facebook
                </FacebookShareButton>
                <WhatsappShareButton
                  className="shareBtn"
                  url={generateUrl()}
                  title={generateTitle()}
                  quote={quote}
                >
                  <WhatsappIcon size={32} round={true} />
                  Share on WhatsApp
                </WhatsappShareButton>
                <FacebookMessengerShareButton
                  className="shareBtn"
                  url={generateUrl()}
                  title={generateTitle()}
                  quote={quote}
                  appId="381457119697965"
                >
                  <FacebookMessengerIcon size={32} round={true} />
                  Share on Messenger
                </FacebookMessengerShareButton>
                <button
                  className="react-share__ShareButton shareBtn copyLink"
                  onClick={copyLink}
                >
                  <Clipboard />
                  {copied ? "URL copied" : "Copy Link"}
                </button>
              </div>
              <img
                className="closeIcon"
                src={color == "red" ? CloseIconRed : CloseIcon}
                onClick={() => setShow(prevState => !prevState)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareGig;
