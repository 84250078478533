const initEditProfile = () => {
  if (document.querySelector("#editProfile")) {
    const labels = document.querySelectorAll(".typeOptions label");
    if (labels.length === 1) return;
    labels.forEach(label => {
      label.addEventListener("click", () => {
        labels.forEach(l => l.classList.remove("selected"));
        label.classList.add("selected");
      });
    });
  }
};

export { initEditProfile };
