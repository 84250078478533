import flatpickr from "flatpickr";
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some(toMatchItem => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const initCalendar = () => {
  const input = document.querySelector("#profile_dob");
  if (!input) return;
  if (detectMob()) {
    document.querySelector("#profile_dob")?.remove();
  } else {
    document.querySelector("#profile_dob_mob")?.remove();
    const maxDate = input.dataset.min.replace('"', "");
    flatpickr(input, {
      disableMobile: "true",
      defaultDate: input.dataset.default,
      dateFormat: "d-m-Y",
      maxDate
    });
  }
};

export { initCalendar };
