import React, { useState } from "react";
import { CREATIVE_SECTOR_LIST, STYLIST_SECTOR_LIST } from "../utils/constants";
import DropDown from "./DropDown";

const StylistDropdown = ({ value, errors }) => (
  <DropDown
    label="Sector"
    attribute="stylist_sector"
    options={STYLIST_SECTOR_LIST.map(obj => obj.text)}
    value={value}
    errors={errors}
    theme="orange"
  />
);

const MediaDropdown = ({ value, errors }) => (
  <DropDown
    label="Sector"
    attribute="media_sector"
    options={CREATIVE_SECTOR_LIST.map(obj => obj.text)}
    value={value}
    errors={errors}
    theme="orange"
  />
);

const CreativeTypeSector = ({
  type,
  media_sector: mediaSector,
  stylist_sector: stylistSector,
  experience,
  drone,
  studio
}) => {
  const [creativeType, setCreativeType] = useState(() => {
    if (type.value === "stylist") {
      return "stylist/MUA";
    }

    return type.value;
  });
  const modifiedCreativeTypeOptions = type.options.map(option => {
    if (option === "stylist") {
      return "stylist/MUA";
    }

    return option;
  });

  return (
    <>
      <DropDown
        label="Creative type"
        attribute="creative_type"
        options={modifiedCreativeTypeOptions}
        value={creativeType}
        errors={type.errors}
        theme="orange"
        changeValue={newValue => setCreativeType(newValue)}
      />

      {creativeType === "stylist/MUA" ? (
        <StylistDropdown
          value={stylistSector.value}
          errors={stylistSector.errors}
        />
      ) : (
        <MediaDropdown value={mediaSector.value} errors={mediaSector.errors} />
      )}

      <DropDown {...experience} />
      {creativeType !== "stylist/MUA" && (
        <>
          <DropDown {...studio} />
          <DropDown {...drone} />
        </>
      )}
    </>
  );
};

export default CreativeTypeSector;
