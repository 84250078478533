import React, { useState } from "react";
import Modal from "./Modal";
import Logo from "../images/logo-inline.png";
import Instagram from "../images/instagram.png";
import Info from "../images/info.png";
import SocialButtons from "./SocialButtons";

const TopBar = ({ page, prevPage, color }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <>
      {showInfo ? (
        <Modal close={() => setShowInfo(false)} className="noPadding">
          <div id="aboutUs" className="container">
            <h1>About us</h1>
            <p>
              Welcome to Agency.Pics, a global talent platform to see and be
              seen, apply for international jobs instantly and find talent from
              around the globe.
            </p>
            <a href="/faq" className="btn inverted-green">
              Read the F.A.Q.
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/agency.pics"
              className="btn inverted-green"
              rel="noreferrer"
            >
              Follow on Instagram
            </a>
            <a href="/privacy" className="btn inverted-green">
              Privacy Policy
            </a>
          </div>
        </Modal>
      ) : null}

      <div id="topBar" className={`${page} ${prevPage}`}>
        <a href="/">
          <img src={Logo} />
        </a>
        <div className="socialBox">
          <img
            src={Info}
            alt="info-button"
            className="infoBtn"
            onClick={() => setShowInfo(true)}
          />
          <a href={`https://www.instagram.com/agency.pics`}>
            <img src={Instagram} className="igLogo" />
          </a>
          <SocialButtons color={color} />
        </div>
      </div>
    </>
  );
};

export default TopBar;
