import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import OrderCard from "./OrderCard";
import Profile from "./pages/Profile";
import { COLORS } from "../utils/constants";
import { capitalize } from "../utils/capitalize";
import { blockScroll, activateScroll } from "../utils/iosScroll";


const { red, yellow, orange } = COLORS;

const ProfileModal = ({ setShowProfile, igHandle, theme }) => {
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      const response = await axios.get(`/api/v1/${igHandle}`);
      setProfile(response.data);
    })();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, []);

  return (
    <div className="profileModalBackground">
      <div className="profileModal">
        {profile ? (
          <Profile
            setTheme={() => {}}
            data={profile}
            type={theme}
            page={theme}
            hideHeader={true}
            closeModal={() => setShowProfile()}
          />
        ) : (
          <div className="gridLoaderBox">
            <Loader
              type="TailSpin"
              color={theme === "models" ? yellow : red}
              height={80}
              width={80}
              timeout={300000}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const OrderDetail = ({ id, theme }) => {
  const [gig, setGig] = useState({});

  const [fetching, setFetching] = useState(true);
  const [showProfile, setShowProfile] = useState();
  const [error, setError] = useState("");
  const color = () => {
    if (theme === "influencers") {
      return red;
    } else if (theme === "creatives") {
      return orange;
    }

    return yellow;
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `/order_details?id=${id}`
        );
        setGig(response.data);
        setFetching(false);
      } catch (e) {
        setError(e.message);
      }
    })();
  }, []);

  if (error) {
    return (
      <div className="gigDetail">
        <h3>{error}</h3>
      </div>
    );
  }

  if (fetching) {
    return (
      <div id="gigDetail">
        <div className="gigLoaderBox gig">
          <Loader
            type="TailSpin"
            color={color()}
            height={60}
            width={60}
            timeout={300000}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="gigDetail">
        {showProfile ? (
          <ProfileModal
            setShowProfile={setShowProfile}
            igHandle={showProfile}
            theme={theme}
          />
        ) : null}
        <h1>
          <span className="font-weight-normal">
            Order DETAILS: #AP{id.toString().padStart(5, "0")}
          </span>
        </h1>
        {Object.keys(gig).length > 0 ? (
          <OrderCard
            {...gig}
            showApplyButton={false}
            dummyButton={true}
            soloButton={true}
            hideViewMore={true}
          />
        ) : null}
        <div>
          <div className="gigInfo">
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Received At</p>
              <p className="attrValue">{gig.received_at}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Cost</p>
              <p className="attrValue">{gig.cost}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Title</p>
              <p className="attrValue">{capitalize(gig.title)}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Category</p>
              <p className="attrValue">{capitalize(gig.category)}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Delivery Time</p>
              <p className="attrValue">{capitalize(gig.delivery_time)}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Status</p>
              <p className="attrValue">{capitalize(gig.state)}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Sender Name</p>
              <p className="attrValue">{capitalize(gig.sender)}</p>
            </div>
            <div className={`fieldContainer ${theme}`}>
              <p className="attrLabel">Receiver Name</p>
              <p className="attrValue">{capitalize(gig.receiver)}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;
