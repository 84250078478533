const generateLabel = attribute => {
  if (!attribute) return;
  const label = attribute.replace("_", " ");
  if (attribute === "chest") return "Chest";
  return label
    .split(" ")
    .map(w => `${w[0].toUpperCase()}${w.substring(1)}`)
    .join(" ");
};

export { generateLabel };
