import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Profile from "./pages/Profile";
import Preview from "../images/preview.png";
import { axios } from "../utils/axios";

const ProfilePreview = ({ ig_handle, text }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [theme, setTheme] = useState();
  const [profile, setProfile] = useState();
  const fetchProfile = async () => {
    const { data } = await axios.get(`/api/v1/${ig_handle}`);
    setProfile(data);
    setTheme(data.role[0].toLowerCase() + "s");
  };

  const changeTheme = newTheme => {
    setTheme(newTheme);
  };

  useEffect(() => {
    if (setShowProfile) {
      fetchProfile();
    }
  }, [showProfile]);
  return (
    <>
      {showProfile ? (
        <Modal
          close={() => {
            setShowProfile(false);
          }}
          type={theme}
        >
          {profile && theme ? (
            <Profile
              data={profile}
              page={theme}
              hideHeader={true}
              hideBackLink={true}
              type={theme}
              setTheme={changeTheme}
            />
          ) : null}
        </Modal>
      ) : null}
      <div>
        {text ? (
          <button
            onClick={() => setShowProfile(true)}
            className="profilePreviewBtn btn green-btn"
          >
            See your profile
          </button>
        ) : (
          <div className="icon preview" onClick={() => setShowProfile(true)}>
            <img src={Preview} alt="Profile Preview" />
            <span>Preview</span>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfilePreview;
