import React, { useState, useRef } from "react";
import { clearEmptyObject } from "../utils/clearEmptyObject";

const ApplicableFilter = ({ name, query, setQuery }) => {
  const [checked, setChecked] = useState(false);
  const input = useRef();

  const changeCheckBox = () => {
    setChecked(true);
  };

  return (
    <div id="meGigFilter" onClick={changeCheckBox}>
      <span className="filterText">Jobs I can apply for</span>
      <div className="checkboxContainer">
        <input
          className="customCheckbox"
          type="checkbox"
          value="0"
          name={name}
          ref={input}
          checked={checked}
          onChange={() => {
            const newValue = clearEmptyObject({
              ...query,
              applicable: checked ? [0] : undefined
            });
            setQuery(newValue);
          }}
        />
        <span className={`checkmark ${checked ? "checked" : null}`}></span>
      </div>
    </div>
  );
};

export default ApplicableFilter;
