import { checkMobile } from "../../utils/isMobile";

const initStickyFilter = () => {
  const filterBox = document.querySelector(".filterContainer");
  if (!filterBox) return;
  if (!checkMobile()) return;
  if (window.innerWidth > 500) return;
  const filters = document.querySelector(".filters");
  const profileCardContainer = document.querySelector(
    ".infinite-scroll-component__outerdiv"
  );
  document.addEventListener("scroll", () => {
    if (window.pageYOffset > 75) {
      filterBox?.classList.add("fixed");
      filters?.classList.add("blackBackground");
      profileCardContainer?.classList.add("top-spacing");
    } else {
      profileCardContainer?.classList.remove("top-spacing");
      filterBox?.classList.remove("fixed");
      filters?.classList.remove("blackBackground");
    }
  });
};

export { initStickyFilter };
