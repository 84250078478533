import React, { useState, useEffect } from "react";
import axios from "axios";
import { csrfToken } from "@rails/ujs";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";

const { red, yellow, orange } = COLORS;

const ServiceApplyBox = ({ id, theme, see_more_jobs }) => {
  const [fetchingApplication, setFetchingApplication] = useState();
  const [canApply, setCanApply] = useState();
  const [applied, setApplied] = useState();
  const themeSelect = () => {
    if (theme === "models") {
      return "green";
    } else if (theme === "influencers") {
      return "red";
    } else if (theme === "creatives") {
      return "orange";
    } else {
      return "transparent";
    }
  };
  const colorSelect = () => {
    if (theme === "influencers") {
      return red;
    } else if (theme === "creatives") {
      return orange;
    }

    return yellow;
  };

  const requestApplication = async () => {
    const response = await axios.get(`/api/v1/services/${id}/check_order_permission`);
    const { data } = response;
    setCanApply(data);
  };

  const submitApplication = async id => {
    setFetchingApplication(true);
    const params = new URLSearchParams();
    params.append("id", id);
    axios.defaults.headers["X-CSRF-Token"] = csrfToken();
    const response = await axios.post("/api/v1/orders", params);
    if (response.data?.id) {
      setApplied(true);
    }
    setFetchingApplication(false);
  };

  useEffect(() => {
    requestApplication();
  }, []);

  const applicationRequestResponse = see_more_jobs => {
    if (applied) {
      return <h4>Thanks! Your application has been received.</h4>;
    }  else if (fetchingApplication) {
      return (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={themeSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      );
    } else if (canApply.can_apply) {
      return (
        <button
          onClick={() => (window.location =`/make_payment_order?service_id=${id}`)}
          className={`btn accessBtn ${themeSelect()}`}
        >
          Create Order
        </button>
      );
    } else {
      return (
        <div className="applicationsBox">
          {canApply.login ? (
            <h4>You need to log in or register to create order for this Service.</h4>
          ) : (
            <h4>Sorry, you can't create order for this Service.</h4>
          )}
          <>
            <ul>
              {canApply.message?.map(m => (
                <li key={m}>
                  <div className={`messageBox ${themeSelect()}`}></div>
                  {m}
                </li>
              ))}
            </ul>
            {see_more_jobs && !canApply.login ? (
              <button
                onClick={() => (window.location = "/services")}
                className={`btn accessBtn inverted-${themeSelect()}`}
              >
                SEE MORE Services
              </button>
            ) : null}
          </>
          {canApply.login ? (
            <div>
              {/*<button*/}
              {/*  onClick={() => (window.location = "/users/sign_up")}*/}
              {/*  className={`btn accessBtn ${themeSelect()}`}*/}
              {/*>*/}
              {/*  REGISTER*/}
              {/*</button>*/}
              <button
                onClick={() => (window.location = "/users/sign_in")}
                className={`btn accessBtn ${themeSelect()}`}
              >
                CREATE ORDER
              </button>
              <a
                href={`/${theme}`}
                className={`btn accessBtn full inverted-${themeSelect()}`}
              >
                Search Talent
              </a>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <div id="gigApplyBox" className="applicationInfo mt-2">
      {canApply === undefined ? (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={colorSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      ) : canApply ? (
        applicationRequestResponse(see_more_jobs)
      ) : null}
    </div>
  );
};

export default ServiceApplyBox;
