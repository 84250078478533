import React, { useState, useEffect, useRef } from "react";
import Cities from "../utils/cities";
import iconLocationGreen from "../images/pin-logo.png";
import iconLocationRed from "../images/pin-logo-red.png";
import iconLocationOrange from "../images/pin-logo-orange.png";
import iconCloseGreen from "../images/close.png";
import iconCloseRed from "../images/close-red.png";
import iconCloseOrange from "../images/close-orange.png";

const FilterLocation = ({ updateQuery, prevLocation, page, defaultValue }) => {
  const [query, setQuery] = useState("");
  const [cities, setCities] = useState([]);
  const inputEl = useRef();
  let locationIcon = iconLocationGreen;
  let closeIcon = iconCloseGreen;

  if (page === "influencers") {
    locationIcon = iconLocationRed;
    closeIcon = iconCloseRed;
  } else if (page === "creatives") {
    locationIcon = iconLocationOrange;
    closeIcon = iconCloseOrange;
  }

  useEffect(() => {
    if (!inputEl) return;
    inputEl.current.value = prevLocation || "";
  }, [prevLocation]);

  useEffect(() => {
    if (query === "") setCities([]);
    const cities =
      query.length == 0
        ? []
        : Cities.filter(
            city =>
              city !== "Global" &&
              city.toLowerCase().includes(query.toLowerCase())
          );
    setCities(cities.slice(0, 5));
  }, [query]);

  return (
    <div id="filterLocation">
      <div className="inputWrapper">
        <input
          ref={inputEl}
          type="text"
          name={name}
          defaultValue={defaultValue}
          onKeyUp={e => setQuery(e.target.value)}
          placeholder="LOCATION"
          className={`customInput`}
          autoComplete="off"
        />
        {query || prevLocation ? (
          <img
            src={closeIcon}
            onClick={() => {
              inputEl.current.value = "";
              updateQuery(undefined, "location");
            }}
          />
        ) : null}
      </div>
      <div className="cities">
        {cities.map(city => (
          <div
            className={`city ${page}`}
            key={city}
            onClick={() => {
              inputEl.current.value = city;
              updateQuery(city, "location");
              setQuery("");
            }}
          >
            <img src={locationIcon} />
            {city}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterLocation;
