import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const PhoneNumberInput = ({
  defaultValue,
  label,
  errors,
  name = "profile[phone_number]"
}) => {
  const [value, setValue] = useState("");

  return (
    <div className="customInputWrapper">
      <label className="inputLabel">{label}</label>
      <PhoneInput
        value={defaultValue || value}
        onChange={setValue}
        defaultCountry="ID"
        placeholder="+621112223333"
        international
        name={name}
      />
      {errors.map((error, i) => (
        <span className="textRed textSm" key={i}>
          {`${label} ${error}`}
        </span>
      ))}
    </div>
  );
};

export default PhoneNumberInput;
