import { csrfToken } from "@rails/ujs";
import axios from "axios";
import DropDown from "./DropDown";
import React, { useState, useEffect } from "react";
import Alert from "./Alert";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { DATES, MONTHS } from "../utils/constants";
import { blockScroll, activateScroll } from "../utils/iosScroll";
import CloseIcon from "../images/close.png";

const ProfileEnquiry = ({ creativeIg, setShowOffer }) => {
  const [proposition, setProposition] = useState("");
  const [igHandle, setIgHandle] = useState("");
  const [email, setEmail] = useState("");
  const [budget, setBudget] = useState("");
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const googleCaptchaKey = document.querySelector(
    "meta[name='google-recaptcha']"
  ).content;

  const submit = async () => {
    const allFilled =
      proposition !== "" &&
      igHandle !== "" &&
      email !== "" &&
      budget !== "" &&
      date !== "" &&
      month !== "" &&
      phoneNumber !== "" &&
      verified;

    if (!allFilled) {
      return;
    }

    const data = {
      enquiry: {
        proposition,
        ig_handle: igHandle,
        email,
        budget,
        date,
        month,
        phone_number: phoneNumber,
        creative_ig_handle: creativeIg
      }
    };

    axios.defaults.headers["X-CSRF-Token"] = csrfToken();
    try {
      const response = await axios.post("/api/v1/enquiries", data);
      setProposition("");
      setIgHandle("");
      setEmail("");
      setBudget("");
      setDate("");
      setMonth("");
      setMessage(response.data.message);
      setDisabled(true);

      setTimeout(() => {
        setMessage("");
      }, 4000);
      setTimeout(() => {
        const signedIn = document.body.dataset.userSignedIn === "true";
        if (signedIn) {
          window.location = "/models";
        } else {
          window.location = "/jobs";
        }
      }, 4000);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCaptchaChange = value => {
    if (value) {
      setVerified(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, []);

  useEffect(() => {
    const allFilled =
      proposition !== "" &&
      igHandle !== "" &&
      email !== "" &&
      budget !== "" &&
      date !== "" &&
      month !== "" &&
      phoneNumber !== "" &&
      verified;

    if (allFilled) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    proposition,
    igHandle,
    email,
    budget,
    date,
    month,
    phoneNumber,
    verified
  ]);

  return (
    <div id="profile-enquiry" className="container">
      <img
        src={CloseIcon}
        id="profile-enquiry-close"
        className="closeIcon"
        onClick={() => setShowOffer(false)}
      />
      <h3 className="text-center">Enquiry</h3>
      <textarea
        placeholder="Your business proposition"
        cols="10"
        rows="5"
        value={proposition}
        onChange={e => setProposition(e.target.value)}
      ></textarea>
      <div className="ig-input-wrapper">
        <p>@</p>
        <input
          type="text"
          value={igHandle}
          onChange={e => setIgHandle(e.target.value)}
          placeholder="Your Instagram"
        />
      </div>
      <input
        type="text"
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder="Your Email"
      />
      <PhoneInput
        value={phoneNumber}
        onChange={val => setPhoneNumber(val)}
        defaultCountry="ID"
        placeholder="+621112223333"
        international
      />
      <div className="budget-input-wrapper">
        <label htmlFor="budget">Project budget (USD$):</label>
        <input
          id="budget"
          type="number"
          min="0"
          value={budget}
          onChange={e => setBudget(e.target.value)}
        />
      </div>
      <div className="dates-input-wrapper">
        <label htmlFor="date">Project deadline:</label>
        <div className="selectors">
          <DropDown
            attribute="month"
            options={MONTHS}
            value={month}
            onChange={value => setMonth(value)}
            label={false}
          />
          <DropDown
            attribute="date"
            options={DATES}
            value={date}
            onChange={value => setDate(value)}
            label={false}
          />
        </div>
      </div>
      <ReCAPTCHA
        sitekey={googleCaptchaKey}
        onChange={handleCaptchaChange}
        theme="dark"
      />
      <div className="submit-wrapper">
        <button
          disabled={disabled}
          className="btn btn-green"
          id="submit"
          onClick={submit}
        >
          Submit
        </button>
      </div>
      {message !== "" && <Alert message={message} />}
    </div>
  );
};

export default ProfileEnquiry;
