const initFbSpinner = () => {
  const btn = document.querySelector(".fbBtn");
  const loader = document.querySelector(".loader");
  if (btn) {
    btn.addEventListener("click", () => {
      loader.classList.remove("hidden");
    });
  }
};

export { initFbSpinner };
