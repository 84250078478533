import axios from "axios";
import { csrfToken } from "@rails/ujs";
import { blockScroll, activateScroll } from "../../utils/iosScroll";

const icon = () =>
  '<?xml version="1.0" encoding="UTF-8"?><svg width="207px" height="207px" viewBox="0 0 207 207" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>exclamation</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="exclamation" fill="#EF4243" fill-rule="nonzero"><path d="M103.5,0 C46.2907793,0 0,46.2948223 0,103.5 C0,160.709221 46.2948223,207 103.5,207 C160.709221,207 207,160.705178 207,103.5 C207,46.2907793 160.705178,0 103.5,0 Z M103.5,190.828125 C55.2297832,190.828125 16.171875,151.766982 16.171875,103.5 C16.171875,55.2297832 55.2330176,16.171875 103.5,16.171875 C151.770217,16.171875 190.828125,55.2330176 190.828125,103.5 C190.828125,151.770217 151.766982,190.828125 103.5,190.828125 Z" id="Shape"></path><path d="M104,52 C99.5816,52 96,55.6072112 96,60.0572062 L96,111.942794 C96,116.392789 99.5816,120 104,120 C108.4184,120 112,116.392789 112,111.942794 L112,60.0572062 C112,55.6072112 108.4184,52 104,52 Z" id="Path"></path><circle id="Oval" cx="104" cy="141" r="11"></circle></g></g></svg>';

const alertNode = () => `
  <div class='alertContainer'>
    <div class="alertBox">
      ${icon()}
      It looks like you already have an account
      <div style="display: flex;" class="mt-3">
        <button class="btn btn-${
          window.location.search.includes("influence") ? "primary" : "models"
        } bold" id="gig-form-login">Click here to log in</button>
      </div>
    </div>
  </div>
`;

const attachSubmitListener = form => {
  form.submit();
  activateScroll();
};

const attachListener = async e => {
  axios.defaults.headers["X-CSRF-Token"] = csrfToken();

  const response = await axios.post("/api/v1/email_check", {
    email: e.target.value
  });

  if (response.data.message) {
    const gigForm = document.getElementById("gig_form");

    document.body.insertAdjacentHTML("afterbegin", alertNode());
    blockScroll();

    const gigFormLoginBtn = document.getElementById("gig-form-login");
    gigFormLoginBtn.addEventListener("click", () => {
      if (gigForm) {
        attachSubmitListener(gigForm);
      } else {
        window.location = "/users/sign_in";
      }
    });
  }
};

export const emailCheck = () => {
  const gigEmailInput = document.getElementById("gig_email");
  const userRegisterEmailInput = document.getElementById("user-register-email");

  gigEmailInput?.addEventListener("focusout", attachListener);
  userRegisterEmailInput?.addEventListener("focusout", attachListener);
};
