import React, { useState } from "react";

const ToggleVisibility = ({ label, value, inputName, theme }) => {
  const [show, setShow] = useState(value);

  return (
    <div className="toggleVisibility">
      <label>{label}</label>
      <div className={`options ${theme}`}>
        <div
          onClick={() => setShow(true)}
          className={`option  ${theme} ${show === true ? "active" : ""}`}
        >
          SHOW
        </div>
        <div
          onClick={() => setShow(false)}
          className={`option ${theme} ${show === false ? "active" : ""}`}
        >
          HIDE
        </div>
      </div>
      <input
        type="radio"
        readOnly={true}
        value={0}
        name={inputName}
        checked={!show}
      />
      <input
        type="radio"
        readOnly={true}
        value={1}
        name={inputName}
        checked={show}
      />
    </div>
  );
};

export default ToggleVisibility;
