import React from "react";

const IndexNavigation = () => {


  return (
    <nav className="navContainer">
      {/* <Link href="/models">
        <a className={`navItem ${isActive("models")}`}>MODELS</a>
      </Link>
      <Link href="/influencers">
        <a className={`navItem ${isActive("influencers")}`}>INFLUENCERS</a>
      </Link> */}
    </nav>
  );
};

export default IndexNavigation;
