import React from "react";
import { useState, useEffect } from "react";
import downArrow from "../images/down-arrow.svg";

const ModelCompCard = ({ data, page, expanded, setExpanded }) => {
  return (
    <div className="modelInfo">
      <div className={`fieldContainer`}>
        <p className="attrLabel">COUNTRY</p>
        <p className="attrValue">{data.location?.split(",")[1]}</p>
      </div>
      <div className={`fieldContainer`}>
        <p className="attrLabel">CITY</p>
        <p className="attrValue">{data.location?.split(",")[0]}</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">TYPE</p>
        <p className="attrValue">{data.model_type} Model</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">CATEGORY</p>
        <p className="attrValue">{data.model_category}</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">GENDER</p>
        <p className="attrValue">{data.gender}</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">CLOTHES SIZE (AVG)</p>
        <p className="attrValue">{data.size}</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">COMPLEXION</p>
        <p className="attrValue">{data.complexion}</p>
      </div>
      <div className="fieldContainer">
        <p className="attrLabel">HAIR</p>
        <p className="attrValue">{data.hair}</p>
      </div>
      <div
        className="fieldContainer showMore"
        onClick={() => setExpanded(expanded => !expanded)}
      >
        <p className="attrLabel">MORE INFO</p>
        <div className={`expandIcon ${page}`}>
          <img
            src={downArrow}
            alt="down-arrow"
            width={8}
            height={8}
            className={`${expanded ? "expanded" : ""}`}
          />
        </div>
      </div>
      <div className={`advanced ${expanded ? "expanded" : ""}`}>
        <div className="fieldContainer">
          <p className="attrLabel">HEIGHT</p>
          <p className="attrValue">{data.height}cm</p>
        </div>
        <div className="fieldContainer">
          <p className="attrLabel">WAIST</p>
          <p className="attrValue">{data.waist}in</p>
        </div>
        {data.gender == "Male" ? (
          <>
            <div className="fieldContainer">
              <p className="attrLabel">CHEST</p>
              <p className="attrValue">{data.chest}in</p>
            </div>
          </>
        ) : (
          <>
            <div className="fieldContainer">
              <p className="attrLabel">BUST</p>
              <p className="attrValue">{data.bust}in</p>
            </div>
            <div className="fieldContainer">
              <p className="attrLabel">HIPS</p>
              <p className="attrValue">{data.hips}in</p>
            </div>
          </>
        )}
        <div className="fieldContainer">
          <p className="attrLabel">SHOE SIZE</p>
          <p className="attrValue">{data.shoes}EU</p>
        </div>
        <div className="fieldContainer">
          <p className="attrLabel">EYES</p>
          <p className="attrValue">{data.eyes}</p>
        </div>
      </div>
    </div>
  );
};

const InfluencerCompCard = ({ data, page }) => {
  return (
    <div className="influenceInfo">
      <div className={`fieldContainer`}>
        <p className="attrLabel">COUNTRY</p>
        <p className="attrValue">{data.location?.split(",")[1]}</p>
      </div>
      <div className={`fieldContainer`}>
        <p className="attrLabel">CITY</p>
        <p className="attrValue">{data.location?.split(",")[0]}</p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">GENDER</p>
        <p className="attrValue">{data.gender}</p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">SECTOR</p>
        <p className="attrValue">{data.sector}</p>
      </div>
      {data.ig_followers && data.ig_handle ? (
        <div className={`fieldContainer ${page}`}>
          <p className="attrLabel">
            <a href={`https://instagram.com/${data.ig_handle}`}>IG FOLLOWERS</a>
          </p>
          <p className="attrValue">{data.ig_followers}</p>
        </div>
      ) : null}
      {data.tk_followers && data.tk_handle !== "@" ? (
        <div className={`fieldContainer ${page}`}>
          <p className="attrLabel">
            <a href={`https://tiktok.com/${data.tk_handle}`}>TK FOLLOWERS</a>
          </p>
          <p className="attrValue">{data.tk_followers}</p>
        </div>
      ) : null}
      {data.yt_followers && data.yt_handle ? (
        <div className={`fieldContainer ${page}`}>
          <p className="attrLabel">YT FOLLOWERS</p>
          <p className="attrValue">{data.yt_followers}</p>
        </div>
      ) : null}
      {data.fb_followers && data.fb_handle ? (
        <div className={`fieldContainer ${page}`}>
          <p className="attrLabel">FB FOLLOWERS</p>
          <p className="attrValue">{data.fb_followers}</p>
        </div>
      ) : null}
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">ENGAGEMENT</p>
        <p className="attrValue">{data.engagement}</p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">CLOTHES SIZE (AVG)</p>
        <p className="attrValue">{data.size}</p>
      </div>
    </div>
  );
};

const CreativeCompCard = ({ data, page }) => {
  return (
    <div className="creativeInfo">
      <div className={`fieldContainer`}>
        <p className="attrLabel">COUNTRY</p>
        <p className="attrValue">{data.location?.split(",")[1]}</p>
      </div>
      <div className={`fieldContainer`}>
        <p className="attrLabel">CITY</p>
        <p className="attrValue">{data.location?.split(",")[0]}</p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">TYPE</p>
        <p className="attrValue">
          {data.creative_type === "Stylist"
            ? "Stylist / MUA"
            : data.creative_type}
        </p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">CATEGORY</p>
        <p className="attrValue">{data.creative_sector}</p>
      </div>
      {data.creative_type !== "Stylist" && (
        <>
          <div className={`fieldContainer ${page}`}>
            <p className="attrLabel">STUDIO</p>
            <p className="attrValue">
              {data.studio ? "I can shoot studio" : "I don't shoot studio"}
            </p>
          </div>
          <div className={`fieldContainer ${page}`}>
            <p className="attrLabel">DRONE</p>
            <p className="attrValue">
              {data.drone ? "I can fly drone" : "I can't fly drone"}
            </p>
          </div>
        </>
      )}
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">EXPERIENCE</p>
        <p className="attrValue">{data.media_experience}</p>
      </div>
      <div className={`fieldContainer ${page}`}>
        <p className="attrLabel">GENDER</p>
        <p className="attrValue">{data.gender}</p>
      </div>
    </div>
  );
};

const SelectedCompCard = ({
  initialTab,
  page,
  data,
  expanded,
  setExpanded
}) => {
  if (initialTab === "Model") {
    return (
      <ModelCompCard
        data={data}
        page={page}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    );
  } else if (initialTab === "Influencer") {
    return <InfluencerCompCard data={data} page={page} />;
  } else if (initialTab === "Creative") {
    return <CreativeCompCard data={data} page={page} />;
  }
};

const CompCard = ({ data, page, setTheme }) => {
  const [tab, setTab] = useState(page === "models" ? "Model" : "Influencer");
  const [expanded, setExpanded] = useState();
  const [initialTab, setInitialTab] = useState();
  const [currentRole, setCurrentRole] = useState();
  const toggleTab = role => {
    if (role !== tab) {
      setTab(role);
    }
  };
  const toggleTabAndTheme = role => {
    toggleTab(role);

    if (role === "Model") {
      setTheme("models");
    } else if (role === "Influencer") {
      setTheme("influencers");
    } else if (role === "Creative") {
      setTheme("creatives");
    }
  };

  useEffect(() => {
    if (!page) return;
    if (page === "models") {
      setInitialTab("Model");
      setCurrentRole("Model");
    } else if (page === "influencers") {
      setCurrentRole("Influencer");
      setInitialTab("Influencer");
    } else if (page === "creatives") {
      setCurrentRole("Creative");
      setInitialTab("Creative");
    }
  }, [page]);

  if (!initialTab) {
    return <div></div>;
  }
  return (
    <div id="compCard" className={page}>
      <div
        className={`cardHeader ${page} ${
          data.role.length === 3 ? "full-width" : ""
        }`}
      >
        {data.role
          .sort()
          .reverse()
          .map(role => (
            <p
              key={role}
              className={`${currentRole === role ? "active" : ""} ${page}`}
              onClick={() => toggleTabAndTheme(role)}
            >
              {role}
            </p>
          ))}
      </div>
      <div className="cardBody">
        <SelectedCompCard
          page={page}
          data={data}
          expanded={expanded}
          setExpanded={setExpanded}
          initialTab={initialTab}
        />

        {/* <div className={`profileActivity text-${page}`}>
          <p>Last Login: {data.last_activity}</p>
          <p>Total Views: {data.total_views}</p>
          <p>Impressions: {data.impressions_count}</p>
        </div> */}
      </div>
    </div>
  );
};

export default CompCard;
