import React, { useEffect, useState } from "react";
import {
  WhatsappShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton
} from "react-share";
import { FacebookMessengerIcon, WhatsappIcon, FacebookIcon } from "react-share";
import Clipboard from "./Clipboard";
import CloseIcon from "../images/close.png";
import CloseIconRed from "../images/close-red.png";
import { checkMobile } from "../utils/isMobile";
import Share from "../images/share.png";
import ShareGreen from "../images/share-green.png";
import ShareRed from "../images/share-red.png";

// TODO: Change the icons to the red ones when picture is available
const SocialButtons = ({ data, color, btnText }) => {
  const [copied, setCopied] = useState(false);
  const [isMobile, setIsMobile] = useState();
  const [mount, setMount] = useState(false);
  const [showLink, setShowLinks] = useState(false);

  const title = data => {
    return data ? `${data.name} on Agency.Pics` : "Agency.Pics";
  };

  const url = data => {
    return data
      ? `${window.location.origin}/${data.ig_handle}`
      : window.location.origin;
  };

  const text = data => {
    return data
      ? `Check out ${data.name} on Agency.Pics`
      : "A global search platform for Models, Influencers and Creatives.";
  };

  const copyLink = async () => {
    if ("clipboard" in navigator) {
      setCopied(true);
      await navigator.clipboard.writeText(url(data));
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  };

  const handleClick = () => {
    if (isMobile) {
      if (navigator.share) {
        navigator.share({
          title: title(data),
          text: text(data),
          url: url(data)
        });
      } else {
        setShowLinks(true);
      }
    } else {
      setShowLinks(true);
    }
  };
  useEffect(() => {
    if (mount) return;
    setMount(true);
    setIsMobile(checkMobile());
  }, []);

  return (
    <div id="socialButtons">
      {btnText ? (
        <button
          onClick={handleClick}
          type="button"
          className={`shareTextButton btn btn-${color}`}
        >
          {btnText} <img src={Share} className="shareBtn" />
        </button>
      ) : (
        <img
          onClick={handleClick}
          src={
            color === "black"
              ? Share
              : color === "green"
              ? ShareGreen
              : ShareRed
          }
          className="shareBtn"
        />
      )}
      {showLink ? (
        <div className={`linkModalBg ${color == "red" ? "influencers" : ""}`}>
          <div className="linkModal container">
            <div className="shareLinks">
              <FacebookShareButton
                className="shareBtn"
                title={title(data)}
                url={url(data)}
                quote={text(data)}
              >
                <FacebookIcon size={32} round={true} />
                Share on Facebook
              </FacebookShareButton>
              <WhatsappShareButton
                className="shareBtn"
                url={url(data)}
                title={title(data)}
                quote={text(data)}
              >
                <WhatsappIcon size={32} round={true} />
                Share on WhatsApp
              </WhatsappShareButton>
              <FacebookMessengerShareButton
                className="shareBtn"
                url={url(data)}
                title={title(data)}
                quote={text(data)}
                appId="381457119697965"
              >
                <FacebookMessengerIcon size={32} round={true} />
                Share on Messenger
              </FacebookMessengerShareButton>
              <button
                className="react-share__ShareButton shareBtn copyLink"
                onClick={copyLink}
              >
                <Clipboard />
                {copied ? "URL copied" : "Copy Link"}
              </button>
            </div>
            <img
              className="closeIcon"
              src={color == "red" ? CloseIconRed : CloseIcon}
              onClick={() => setShowLinks(prevState => !prevState)}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SocialButtons;
