import React from "react";

const Input = ({
  hint,
  label,
  onBlur,
  name,
  placeholder,
  value,
  setValue,
  id,
  classList = "",
  errors = [],
  type = "text"
}) => {
  return (
    <div className="customInputWrapper">
      <label className="inputLabel">{label}</label>
      <input
        placeholder={placeholder}
        onBlur={e => onBlur && onBlur(e.target.value)}
        type={type}
        value={value}
        onChange={e => setValue(e.target.value)}
        name={name}
        className={`customInput ${classList}`}
        id={id}
      />
      {hint ? <span className="textRed textSm">{`${hint}`}</span> : null}
      {errors.map((error, i) => (
        <span className="textRed textSm" key={i}>
          {`${label} ${error}`}
        </span>
      ))}
    </div>
  );
};

export default Input;
