import React, { useState } from "react";
import CreativeTypesSelection from "./CreativeTypesSelection";
import useDidMountEffect from "./hooks/useDidMountEffect";

const PhotoInputs = ({ images, setImages }) => {
  return (
    <div className="form-group string required gig_images">
      <label className="string required" htmlFor="gig_images">
        Number of images
      </label>
      <input
        className="form-control string required orange"
        value={images}
        onChange={e => setImages(Number(e.target.value))}
        type="text"
        inputMode="numeric"
        name="gig[images]"
        id="gig_images"
      />
    </div>
  );
};

const VideoInputs = ({
  numberOfVideos,
  setNumberOfVideos,
  lengthOfVideos,
  setLengthOfVideos
}) => {
  return (
    <>
      <div className="form-group string required gig_number_of_videos">
        <label className="string required" htmlFor="gig_number_of_videos">
          Number of videos
        </label>
        <input
          className="form-control string required orange"
          value={numberOfVideos}
          onChange={e => setNumberOfVideos(Number(e.target.value))}
          type="text"
          inputMode="numeric"
          name="gig[number_of_videos]"
          id="gig_number_of_videos"
        />
      </div>
      <div className="form-group string required gig_length_of_videos">
        <label className="string required" htmlFor="gig_length_of_videos">
          Video length (seconds)
        </label>
        <input
          className="form-control string required orange"
          value={lengthOfVideos}
          onChange={e => setLengthOfVideos(Number(e.target.value))}
          type="text"
          inputMode="numeric"
          name="gig[length_of_videos]"
          id="gig_length_of_videos"
        />
      </div>
    </>
  );
};

const GigCreativeTypeDropdown = ({
  attribute,
  value,
  options,
  defaultNumberOfImages = 1,
  defaultNumberOfVideos = 1,
  defaultLengthOfVideos = 1
}) => {
  const [selected, setSelected] = useState(value || "");
  const [showPhoto, setShowPhoto] = useState(value === "photographer" || false);
  const [showVideo, setShowVideo] = useState(value === "videographer" || false);
  const [images, setImages] = useState(defaultNumberOfImages);
  const [numberOfVideos, setNumberOfVideos] = useState(defaultNumberOfVideos);
  const [lengthOfVideos, setLengthOfVideos] = useState(defaultLengthOfVideos);

  useDidMountEffect(() => {
    const stylistSector = document.getElementById("stylist_sector_select");
    const mediaSector = document.getElementById("media_sector_select");
    const drone = document.getElementById("drone_select");
    const studio = document.getElementById("studio_select");

    if (selected === "videographer") {
      setShowPhoto(false);
      setShowVideo(true);
      if (stylistSector && mediaSector) {
        stylistSector.classList.add("hidden");
        stylistSector.querySelectorAll("input").forEach(input => {
          input.disabled = false;
        });
        mediaSector.classList.remove("hidden");
        mediaSector.querySelectorAll("input").forEach(input => {
          input.disabled = false;
        });
      }
      if (drone) {
        drone.classList.remove("hidden");
        drone.querySelector("input").disabled = false;
      }
      if (studio) {
        studio.classList.remove("hidden");
        studio.querySelector("input").disabled = false;
      }
    } else if (selected === "photographer") {
      setShowPhoto(true);
      setShowVideo(false);
      if (stylistSector && mediaSector) {
        stylistSector.classList.add("hidden");
        stylistSector.querySelectorAll("input").forEach(input => {
          input.disabled = false;
        });
        mediaSector.classList.remove("hidden");
        mediaSector.querySelectorAll("input").forEach(input => {
          input.disabled = false;
        });
      }
      if (drone) {
        drone.classList.remove("hidden");
        drone.querySelector("input").disabled = false;
      }
      if (studio) {
        studio.classList.remove("hidden");
        studio.querySelector("input").disabled = false;
      }
    } else {
      setShowPhoto(false);
      setShowVideo(false);
      if (stylistSector && mediaSector) {
        stylistSector.classList.remove("hidden");
        stylistSector.querySelectorAll("input").forEach(input => {
          input.disabled = false;
        });
        mediaSector.classList.add("hidden");
        mediaSector.querySelectorAll("input").forEach(input => {
          input.disabled = true;
        });
      }
      if (drone) {
        drone.classList.add("hidden");
        drone.querySelector("input").disabled = true;
      }
      if (studio) {
        studio.classList.add("hidden");
        studio.querySelector("input").disabled = true;
      }
    }
  }, [selected]);

  return (
    <div className="dropDownContainer" id={`${attribute}_select`}>
      <CreativeTypesSelection
        types={options}
        selected={selected}
        setSelected={setSelected}
      />

      {showPhoto && <PhotoInputs images={images} setImages={setImages} />}
      {showVideo && (
        <VideoInputs
          numberOfVideos={numberOfVideos}
          setNumberOfVideos={setNumberOfVideos}
          lengthOfVideos={lengthOfVideos}
          setLengthOfVideos={setLengthOfVideos}
        />
      )}
    </div>
  );
};

export default GigCreativeTypeDropdown;
