import React, { Suspense } from "react";
import RModal from "./RModal";
const OrderDetail = React.lazy(() => import("./OrderDetail"));
const OrderApplyBox = React.lazy(() => import("./OrderApplyBox"));

const OrderModal = ({ close, id, theme }) => {
  return (
    <RModal type={theme} close={close} className="gigModal">
      {id ? (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <OrderDetail id={id} theme={theme} />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <OrderApplyBox id={id} theme={theme} />
          </Suspense>
        </>
      ) : null}
    </RModal>
  );
};
export default OrderModal;
