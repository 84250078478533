import React from "react";

const CreativeTypesSelection = ({ types, selected, setSelected }) => {
  return (
    <div id="creativeTypesSelection">
      <label>Creative Type</label>
      <div className="options">
        {types.map(type => (
          <div
            onClick={e => {
              e.stopPropagation();
              setSelected(type);
            }}
            className={`labelBox orange ${selected === type ? "selected" : ""}`}
            key={type}
          >
            <label>{type == "stylist" ? "Stylist/MUA" : type}</label>
            <input
              id={type}
              hidden
              readOnly={true}
              checked={selected === type}
              type="checkbox"
              name="gig[work_type]"
              value={type}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreativeTypesSelection;
