import React, { useState, useRef, useEffect } from "react";
import ShareOrder from "./ShareOrder";
import { moneyFormatter } from "../utils/moneyFormatter";
import GlobalRed from "../images/global-red.png";
import GlobalGreen from "../images/global-green.png";
import GlobalOrange from "../images/global-orange.png";
import order_bg from "../images/service-bg.jpeg";
import OrderModal from "./OrderModal";

const OrderCardTopLeft = ({ gender, theme, workType, is_sender, is_receiver }) => {
  const [text, setText] = useState("");

  const textMapper = () => {
    if(is_sender){
      setText("Order Placed");
    }else if (is_receiver) {
      setText("Order Received");
    }
  };

  useEffect(() => {
    textMapper();
  }, []);

  return <div className={`genderTypeIcon ${theme}`}>{text}</div>;
};

const OrderCard = ({
                   work_type: workType,
                   id,
                   title,
                   cost,
                   delivery_time,
                   location,
                   category,
                   sender,
                   is_sender,
                   is_receiver,
                   cover_picture,
                   gender,
                   status,
                   has_applied: hasApplied,
                   block_share: blockShare,
                   blockModal,
                   hideViewMore,
                   linkToDetailsPage,
                   display_creative_type: displayCreativeType,
                   soloButton = false,
                   indexPage = false
                 }) => {
  const [showGigModal, setShowGigModal] = useState(false);
  const image = useRef();
  const cardWrapper = useRef();
  const creativeTypeCheck = () => [2, 3, 4].includes(workType);

  useEffect(() => {
    if (image.current.width < 0) return;
    const width = image.current.offsetWidth;
    cardWrapper.current.style.height = `${(width / 16) * 9}px`;
  }, [image.current?.width]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const activeGigId = params.get("active");
    if (
      activeGigId?.match(/\d+/) &&
      parseInt(activeGigId) === id &&
      indexPage
    ) {
      setShowGigModal(true);
      window.history.pushState(
        { path: window.location.pathname },
        "",
        window.location.pathname
      );
    }
  }, []);

  const globalIcon = () => {
    if (workType === 1) {
      return GlobalRed;
    }

    if (creativeTypeCheck()) {
      return GlobalOrange;
    }

    return GlobalGreen;
  };

  const theme = () => {
    if (workType === 1) {
      return "influencers";
    }

    if (creativeTypeCheck()) {
      return "creatives";
    }

    return "models";
  };

  return (
    <>
      <div ref={cardWrapper} className="gigCard">
        {showGigModal && !hideViewMore ? (
          <OrderModal
            id={id}
            theme={theme()}
            close={() => setShowGigModal(false)}
          />
        ) : null}
        <OrderCardTopLeft theme={theme()} gender={gender} workType={workType} is_sender={is_sender} is_receiver={is_receiver} />
        <div className="cardContent" ref={image}>
          <img
            className="banner"
            src={cover_picture || order_bg}
            alt={`${title}-banner`}
          />
          <div className="details">
            {hasApplied ? <div className="appliedStripe">Applied</div> : null}
            <div className={`pill paid ${theme()}`}>
              { `$${moneyFormatter(cost)}`}
            </div>
            <h4>{title}</h4>
            <p className="location">
              <img src={globalIcon()} />
              {category}
            </p>
            <p className="date">{delivery_time}</p>
            <div className={`pill ${theme()}`}>
              { sender }
            </div>
          </div>
        </div>
        <div className="cardActions">
          {hideViewMore ? null : (
            <div
              className={`apply ${theme()}`}
              onClick={() => {
                if (showGigModal || blockModal) return;
                if (linkToDetailsPage) {
                  return (window.location.href = `/orders/${id}/details`);
                }
                setShowGigModal(true);
              }}
            >
              VIEW
            </div>
          )}
          <ShareOrder
            blockShare={blockShare}
            location={location}
            text="SHARE"
            dummyButton={status == "pending"}
            id={id}
            quote={`${title}${
              location == "Global" ? "" : ` in ${location}`
            } on Agency.Pics`}
            soloButton={soloButton}
            theme={theme()}
            displayCreativeType={displayCreativeType + "s"}
          />
        </div>
      </div>
    </>
  );
};

export default OrderCard;
