require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// // Setup for SSR React components
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import { initImagesForm, initProfileImageForm } from "./dom/initPhotoForms";
import { initCropper } from "./dom/cropImage";
import { initCopyLink } from "./dom/initCopyLink";
import { initCompleteProfileForm } from "./dom/initCompleteProfileForm";
import { calculateAlertsOffset } from "./dom/calculateAlertsOffsett";
import { initGigForm } from "./dom/initGigForm";
import { initCalendar } from "./dom/initFlatpickr";
import { initAlert } from "./dom/initAlertModal";
import { initFbSpinner } from "./dom/initFbSpinner";
import { initEditProfile } from "./dom/initEditProfile";
import { initEmailValidator } from "./dom/initEmailValidator";
import { initStickyFilter } from "./dom/initStickyFilter";
import { initPasswordValidator } from "./dom/initPasswordValidator";
import { initGigBannerCropper, initServiceBannerCropper } from "./dom/cropGigImage";
import { initScrollOnInputFocus } from "./dom/initScrollOnInputFocus";
import { emailCheck } from "./dom/emailCheck";
import { gigDeadlineValidation } from "./dom/gigDeadlineValidation";

document.addEventListener("turbolinks:load", function () {
  sessionStorage.clear();
  initImagesForm();
  initProfileImageForm();
  initCropper();
  initCopyLink();
  initCalendar();
  initGigBannerCropper();
  initServiceBannerCropper();
  initCalendar();
  initAlert();
  initPasswordValidator();
  initEmailValidator();
  initEditProfile();
  initScrollOnInputFocus();
  initFbSpinner();
  initGigForm();
  initCompleteProfileForm();
  calculateAlertsOffset();
  initStickyFilter();
  emailCheck();
  gigDeadlineValidation();
  document.querySelector("#closeAlert")?.addEventListener("click", () => {
    document.querySelector("#alert").remove();
  });
});
