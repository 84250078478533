import Cropper from "cropperjs";
import { startSpinner } from "./initPhotoForms";
const generateModal = () => {
  const form = document.querySelector("form");
  const modal = document.createElement("div");
  const cropper = document.createElement("div");
  const imagePreview = document.createElement("img");
  const closeIcon = document.createElement("span");
  const input = document.createElement("input");
  input.type = "submit";
  input.value = "confirm";
  input.classList.add("cropper_submit_btn");
  startSpinner(input);
  modal.classList.add("cropperModal");
  cropper.id = "cropper";
  closeIcon.innerText = "Close";
  closeIcon.classList.add("closeIcon");
  closeIcon.addEventListener("click", () => modal.remove());
  input.addEventListener("click", e => {
    setTimeout(() => {
      e.target.disabled = "true";
    }, 300);
    closeIcon.remove();
  });
  modal.appendChild(closeIcon);
  modal.appendChild(cropper);
  modal.appendChild(input);
  cropper.appendChild(imagePreview);
  form.appendChild(modal);
  return imagePreview;
};

const startCropper = image => {
  const xOffset = document.querySelector("#xOffset");
  const yOffset = document.querySelector("#yOffset");
  const imgWidth = document.querySelector("#img-width");
  const imgHeight = document.querySelector("#img-height");

  setTimeout(() => {
    let cropper = new Cropper(image, {
      aspectRatio: 4 / 5,
      viewMode: 2,
      center: true,
      dragMode: "move",
      movable: false,
      scalable: false,
      guides: false,
      zoomOnWheel: false,
      cropBoxMovable: true,
      crop(event) {
        xOffset.value = Math.floor(event.detail.x);
        yOffset.value = Math.floor(event.detail.y);
        imgWidth.value = Math.floor(event.detail.width);
        imgHeight.value = Math.floor(event.detail.height);
      }
    });
  }, 300);
};
const loadImage = event => {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = generateModal();
      output.src = reader.result;
      resolve(output);
    };
    reader.readAsDataURL(event.target.files[0]);
  });
};

const attachEventOnLoad = uploadField => {
  uploadField.addEventListener("change", e => {
    loadImage(e).then(img => startCropper(img));
  });
};

const initCropper = () => {
  const uploadAvatar = document.querySelector("#profile_avatar");
  const uploadPicture = document.querySelector("#profile_photo");
  uploadAvatar && attachEventOnLoad(uploadAvatar);
  uploadPicture && attachEventOnLoad(uploadPicture);
};

export { initCropper };
