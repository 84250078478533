import React, { useEffect, useState } from "react";
import Filter from "../Filter";
import ProfileCard from "../ProfileCard";
import ProfileCardPlaceholder from "../ProfileCardPlaceHolder";
import IndexHeader from "../IndexHeader";
import ActiveFilters from "../ActiveFilters";
import NameIdSearch from "../NameIdSearch.jsx";
import { axios } from "../../utils/axios";
import { toQueryParams } from "../../utils/toQueryParams";
import FilterLocation from "../FilterLocation";
import FilterVerifiedUser from "../FilterVerifiedUser";
import { useMediaQuery } from "react-responsive";
import TopBar from "../TopBar";
import NavBar from "../NavBar";
import Loader from "react-loader-spinner";
import Profile from "./Profile";

import { COLORS } from "../../utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { clearEmptyObject } from "../../utils/clearEmptyObject";

const { red, yellow, orange } = COLORS;

const ProfilesIndex = ({ user, recruiter, page, defaultLocation }) => {
  const [theme, setTheme] = useState(page);
  const [colorScheme, setColorScheme] = useState(page);
  const [showFilter, setShowFilter] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [query, setQuery] = useState({});
  const [offset, setOffset] = useState(1);
  const [error, setError] = useState({});
  const [profile, setProfile] = useState();
  const [fetching, setFetching] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [stopFetching, setStopFetching] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [switching, setSwitching] = useState(false);
  const [gigModalIsOpen, setGigModalIsOpen] = useState(false);
  const [defaultLoc, setDefaultLoc] = useState(defaultLocation);
  let loaderColor = yellow;
  if (page === "creatives") {
    loaderColor = orange;
  } else if (page === "influencers") {
    loaderColor = red;
  }

  const loadStorage = () => {
    return new Promise(resolve => {
      const prevQuery = sessionStorage.getItem(`${theme}Query`);
      const prevProfiles = sessionStorage.getItem(`${theme}Profiles`);
      const profileList = prevProfiles ? JSON.parse(prevProfiles) : [];
      const prevStopFetching = sessionStorage.getItem(`${theme}StopFetching`);
      const prevOffset = sessionStorage.getItem(`${theme}Offset`);

      setOffset(prevOffset === null ? 1 : JSON.parse(prevOffset));
      setStopFetching(JSON.parse(prevStopFetching));
      setProfiles(profileList);
      setQuery(prevQuery ? JSON.parse(prevQuery) : {});
      resolve(profileList);
    });
  };

  const saveQuery = () => {
    sessionStorage.setItem(`${theme}Query`, JSON.stringify(query));
  };

  const saveProfiles = () => {
    sessionStorage.setItem(`${theme}Profiles`, JSON.stringify(profiles));
  };

  const saveStopFetching = () => {
    sessionStorage.setItem(
      `${theme}StopFetching`,
      JSON.stringify(stopFetching)
    );
  };

  const getProfileInfo = async ig_handle => {
    if (!ig_handle) return;
    const { data } = await axios
      .get(`/api/v1/${ig_handle}`)
      .catch(() =>
        setError(
          "Something bad happened. Please refresh the page to try again."
        )
      );
    setProfile(data);
  };

  const saveOffset = () => {
    sessionStorage.setItem(`${theme}Offset`, JSON.stringify(offset));
  };

  const filter = async page => {
    if (!offset || switching || stopFetching || showProfile) return;
    setFetching(true);

    const { data } = await axios
      .get(
        `/api/v1/profiles/${theme}?${toQueryParams(query)}${
          defaultLoc ? `location=${defaultLoc}` : ""
        }&page=${page || offset}`
      )
      .catch(() =>
        setError(
          "Something bad happened. Please refresh the page to try again."
        )
      );
    setStopFetching(data.length === 0);
    setProfiles(prev => [...prev, ...data]);
    setFetching(false);
    if (defaultLoc) setDefaultLoc(null);
  };

  useEffect(() => {
    if (defaultLocation) {
      setDefaultLoc(defaultLocation);
      setQuery({ location: defaultLocation });
    }
    setMounted(true);
    filter();
  }, []);

  useEffect(() => {
    if (!stopFetching) return;
    saveStopFetching();
  }, [stopFetching]);

  useEffect(() => {
    if (!mounted) return;
    if (profiles.length === 0) return;
    saveProfiles();
  }, [profiles]);

  useEffect(() => {
    if (!mounted || switching) return;
    setProfiles([]);
    setOffset(1);
    setStopFetching(false);
    saveQuery();
  }, [query]);

  useEffect(() => {
    if (window.history.pushState) {
      window.history.pushState({}, null, theme);
    }

    if (!mounted || showProfile) return;
    loadStorage().then(prof => {
      if (prof.length === 0) {
        setSwitching(false);
        filter();
      } else {
        setSwitching(false);
      }
    });
  }, [theme]);

  useEffect(() => {
    if (!mounted) return;
    if (offset === 1) return;
    saveOffset();
    filter();
  }, [offset]);

  useEffect(() => {
    if (showProfile) return;
    setTimeout(() => {
      setShowFilter(true);
    }, 400);
  }, [theme]);

  useEffect(() => {
    if (!showProfile) {
      setColorScheme(theme);
      document.body.classList.remove("modal-open");
    } else {
      getProfileInfo(showProfile);
      document.body.classList.add("modal-open");
    }
  }, [showProfile]);

  useEffect(() => {
    if (fetching || !mounted || switching) return;
    filter(1);
  }, [query, stopFetching]);

  useEffect(() => {
    if (!mounted) return;
    if (!switching && profiles.length === 0) filter();
  }, [switching]);

  const isTableOrMobile = useMediaQuery({
    query: "(min-width: 0px) and (max-width: 1024px)"
  });

  const switchPage = newTheme => {
    setSwitching(true);
    setTheme(newTheme);
    setColorScheme(() => {
      if (newTheme === "models") {
        return "models";
      } else if (newTheme === "influencers") {
        return "influencers";
      } else if (newTheme === "creatives") {
        return "creatives";
      }
    });
  };

  const updateQueryString = (value, attr) => {
    const newValue = clearEmptyObject({
      ...query,
      [attr]: value
    });
    setQuery(newValue);
  };

  return (
    <div id="searchPage">
      <TopBar page={colorScheme} color="black" />
      {showProfile ? (
        <div className="profileModalBackground">
          <div className="profileModal">
            {profile ? (
              <Profile
                setTheme={setColorScheme}
                data={profile}
                type={colorScheme}
                hideHeader={true}
                closeModal={() => {
                  setShowProfile(false);
                  setProfile();
                }}
              />
            ) : (
              <div className="gridLoaderBox">
                <Loader
                  type="TailSpin"
                  color={loaderColor}
                  height={80}
                  width={80}
                  timeout={300000}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}

      <div className="indexPage" id="profilesScroll">
        <IndexHeader changeView={switchPage} theme={colorScheme} />
        <div className="container wide">
          <div className="profilesContainer">
            <div
              className={`filterContainer ${showFilter ? "" : "opacityZero"} ${
                isTableOrMobile ? "" : "desktop"
              }`}
            >
              {isTableOrMobile ? null : (
                <div>
                  <NameIdSearch
                    page={colorScheme}
                    updateQuery={updateQueryString}
                    prevName={query.name}
                  />
                  <FilterLocation
                    page={colorScheme}
                    updateQuery={updateQueryString}
                    prevLocation={query.location}
                  />

                  <FilterVerifiedUser
                    page={colorScheme}
                    type={colorScheme}
                    query={query}
                    setQuery={setQuery}
                    updateQuery={updateQueryString}
                    prevLocation={query.verified}
                  />
                </div>
              )}
              <Filter
                query={query}
                setQuery={setQuery}
                updateQuery={updateQueryString}
                resetFilter={() => setQuery({})}
                type={colorScheme || "models"}
                setGigModalIsOpen={setGigModalIsOpen}
              />
            </div>
            <div className="body">
              <ActiveFilters
                query={query}
                type={colorScheme}
                setQuery={setQuery}
              />
              {Object.keys(query).length > 0 &&
              profiles.length === 0 &&
              !fetching ? (
                <>
                  <div></div>
                  <div className="noResultsBox">
                    <p>
                      Your search returned no results, try changing your
                      filters.
                    </p>
                  </div>

                  <div></div>
                </>
              ) : null}
              <InfiniteScroll
                dataLength={profiles.length}
                next={() => {
                  setOffset(prev => prev + 1);
                }}
                hasMore={!stopFetching}
                loader={
                  <ProfileCardPlaceholder count={6} theme={colorScheme} />
                }
                style={
                  isTableOrMobile ? {} : { zIndex: gigModalIsOpen ? -1 : null }
                }
              >
                {profiles.map((profile, i) => (
                  <ProfileCard
                    profile={profile}
                    updateQuery={setQuery}
                    theme={colorScheme}
                    setShowProfile={setShowProfile}
                    key={i}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
      <NavBar page={colorScheme} user={user} recruiter={recruiter} />
    </div>
  );
};

export default ProfilesIndex;
