import React, { useState, useEffect } from "react";
import CompCard from "../CompCard";
import Modal from "../Modal";
import { useMediaQuery } from "react-responsive";

import SocialButtons from "../SocialButtons";
import ProfilePicture from "../ProfilePicture";
import IgLogo from "../../images/instagram.png";
import LinkIcon from "../../images/foreign.svg";
import IndexHeader from "../IndexHeader";
import LeftArrow from "../../images/left-arrow.png";
import LeftArrowRed from "../../images/left-arrow.svg";
import LeftArrowOrange from "../../images/left-arrow-orange.png";
import EmailLogo from "../../images/email.png";
import Slider from "../Slider";
import ProfileEnquiry from "../ProfileEnquiry";
import JobInvitation from "../JobInvitation";
import Tabs from "../Tabs";
import TabContentPictures from "../TabContentPictures";
import TabContentServices from "../TabContentServices";
import VerificationTick from "../../images/verified_tick.png";

const Profile = ({
  data,
  type,
  hideHeader,
  setTheme,
  hideBackLink,
  changeView,
  closeModal,
  sr = false,
  navigate = false
}) => {
  const [photoIndex, setPhotoIndex] = useState();
  const [profileColor, setProfileColor] = useState();
  const [expandProfilePicture, setExpandProfilePicture] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [showInviteForJob, setShowInviteForJob] = useState(false);
  const isTableOrMobile = useMediaQuery({
    query: "(min-width: 0px) and (max-width: 499px)"
  });
  let leftArrowIcon = LeftArrow;
  let buttonColor = "green";

  if (profileColor === "influencers") {
    leftArrowIcon = LeftArrowRed;
    buttonColor = "red";
  } else if (profileColor === "creatives") {
    leftArrowIcon = LeftArrowOrange;
    buttonColor = "orange";
  }

  useEffect(() => {
    setProfileColor(type);
  }, [type]);

  const handleImageClick = () => {
    if (navigate) {
      if (window.location.search.includes("edit_profile")) {
        window.location.pathname = "/profiles/edit";
      } else if (window.location.search.includes("job")) {
        const gigId = window.location.search.match(/\d+/)[0];
        if (gigId) {
          window.location.assign(`/jobs?active=${gigId}`);
        } else {
          window.location.assign("/jobs");
        }
      } else {
        window.location.pathname = "/models";
      }
    } else {
      closeModal();
    }
  };
 // Tabs Main Menu
  const tabs = [
    {
      label: 'Pictures',
      content: <TabContentPictures
        data={data}
        type={type}
      />,
    },
    {
      label: 'Services',
      content: <TabContentServices
        data={data}
        theme={type}
      />,
    },
  ];

  return (
    <div>
      {photoIndex !== undefined ? (
        <Slider
          photoIndex={photoIndex}
          closeSlider={() => setPhotoIndex(undefined)}
          pictures={data.pics}
          type={profileColor}
        />
      ) : null}
      <div id="show">
        {hideHeader ? null : (
          <IndexHeader page={type} changeView={changeView} />
        )}
        <div className={`container medium ${hideHeader && sr && "topMargin"}`}>
          <div className="upper">
            <div className="name">
              {hideBackLink ? null : (
                <img
                  src={leftArrowIcon}
                  className="backLink"
                  onClick={handleImageClick}
                />
              )}

              <h1 className={profileColor}>{data.name.split(" ")[0]}</h1>
              <h1>{data.name.split(" ")[1]}</h1>
              { data.is_verified ? (<img src={VerificationTick} className="verifiedIcon" />) : null}
            </div>
            <div className="profileBox">
              <div className="left">
                <ProfilePicture
                  pic={data.profile_picture}
                  data={data}
                  index={0}
                  type={profileColor}
                  expandPicture={() => setExpandProfilePicture(true)}
                />
                {expandProfilePicture && isTableOrMobile ? (
                  <Modal
                    close={() => setExpandProfilePicture(false)}
                    type={profileColor}
                    className="fullPictureModal"
                  >
                    <img src={data.profile_picture} />
                  </Modal>
                ) : null}
                <div className="profileSocial">
                  <a
                    href={`https://www.instagram.com/${data.ig_handle}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`btn btn-${buttonColor}`}
                  >
                    Connect
                    <img src={IgLogo} className="profileIg" />
                  </a>
                  <SocialButtons
                    btnText="Share"
                    color={buttonColor}
                    data={data}
                  />
                  <button
                    className={`offerBtn btn btn-${buttonColor}`}
                    onClick={() => setShowOffer(true)}
                  >
                    Offer
                    <img src={EmailLogo} className="profileIg" />
                  </button>
                  <button
                    className={`offerBtn btn btn-${buttonColor}`}
                    onClick={() => setShowInviteForJob(true)}
                  >
                    Invite to job
                    <img src={EmailLogo} className="profileIg" />
                  </button>

                  {showOffer &&
                    (isTableOrMobile ? (
                      <Modal
                        close={() => setShowOffer(false)}
                        type={profileColor}
                        className="enquiryModal"
                      >
                        <ProfileEnquiry
                          creativeIg={data.ig_handle}
                          setShowOffer={setShowOffer}
                        />
                      </Modal>
                    ) : (
                      <Modal
                        close={() => setShowOffer(false)}
                        type={profileColor}
                        className="enquiryModal"
                        hideCloseButton={true}
                      >
                        <ProfileEnquiry
                          creativeIg={data.ig_handle}
                          setShowOffer={setShowOffer}
                        />
                      </Modal>
                    ))}

                  {showInviteForJob &&
                    (isTableOrMobile ? (
                      <Modal
                        close={() => setShowInviteForJob(false)}
                        type={profileColor}
                        className="enquiryModal"
                      >
                        <JobInvitation
                          creativeIg={data.ig_handle}
                          setShowOffer={setShowInviteForJob}
                        />
                      </Modal>
                    ) : (
                      <Modal
                        close={() => setShowInviteForJob(false)}
                        type={profileColor}
                        className="jobInvitationModal"
                        hideCloseButton={true}
                      >
                        <JobInvitation
                          creativeIg={data.ig_handle}
                          setShowOffer={setShowInviteForJob}
                          theme={profileColor}
                        />
                      </Modal>
                    ))}

                  {data.external_link && (
                    <a
                      href={
                        /https?:\/\//.test(data.external_link)
                          ? data.external_link
                          : `https://${data.external_link}`
                      }
                      target="_blank"
                      rel="noreferrer"
                      className={`btn btn-${buttonColor}`}
                    >
                      Link
                      <img src={LinkIcon} className="profileIg" />
                    </a>
                  )}
                </div>
              </div>
              <CompCard
                page={profileColor}
                data={data}
                setProfileColor={setProfileColor}
                setTheme={setTheme}
              />
            </div>

            <div className="tabs-header">
              <Tabs
                tabs={tabs}
                themeColor={profileColor}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
