import React, { useEffect, useState, useRef } from "react";
import { clearEmptyObject } from "../utils/clearEmptyObject";

const FilterVerifiedUser = ({ name, query, setQuery, type }) => {
  const [checked, setChecked] = useState();
  const [mounted, setMounted] = useState(false);
  const input = useRef();

  useEffect(() => {
    setMounted(true);
  }, []);

    useEffect(() => {
      setChecked("verified" in query);
    }, [query]);

  const changeCheckBox = () => {
    setChecked(prev => !prev);
  };

  useEffect(() => {
    if (!mounted) return;
    const newValue = clearEmptyObject({
      ...query,
      verified: checked ? [0] : undefined
    });

    setQuery(newValue);
  }, [checked]);

  return (
    <div id="verifiedUserFilter" onClick={changeCheckBox}>
      <span className="filterText">Verified Users</span>
      <div className="checkboxContainer">
        <input
          className={`customCheckbox ${type}`}
          type="checkbox"
          value="0"
          name={name}
          ref={input}
        />
        <span className={`checkmark ${type} ${checked ? "checked" : null}`}></span>
      </div>
    </div>
  );
};

export default FilterVerifiedUser;
