import React, { useEffect } from "react";
import { useState } from "react";
import Close from "../images/close.png";
import CloseBlack from "../images/close-black.png";
import CloseRed from "../images/close-red.png";
import { generateLabel } from "../utils/generateLabel";
import { blockScroll, activateScroll } from "../utils/iosScroll";
import { generateCloseIcon } from "../utils/generateIcons";

const DropDownMulti = ({
  options,
  attribute,
  attrName,
  modalLabel,
  label,
  theme,
  value,
  modalFooter,
  disabled = false
}) => {
  const [selected, setSelected] = useState(value || []);
  const [showOptions, setShowOptions] = useState(false);
  const [fullOpacity, setFullOpacity] = useState(false);

  const collapseOptions = () => {
    setFullOpacity(false);
    setTimeout(() => {
      setShowOptions(false);
    }, 500);
  };

  const changeAttribute = value => {
    if (value === "Any") {
      setSelected(prev => (prev.includes("Any") ? [] : ["Any"]));
      setShowOptions(false);
    } else {
      setSelected(prev => {
        if (prev.includes(value)) {
          return prev.filter(t => t !== value && t !== "Any");
        } else {
          return [...prev, value].filter(t => t !== "Any");
        }
      });
    }
  };

  const showOptionsMenu = () => {
    setShowOptions(true);
    return new Promise(resolve => {
      setTimeout(resolve, 200);
    });
  };

  const expandOptions = () => {
    showOptionsMenu().then(() => setFullOpacity(true));
  };

  const themeSelect = () => {
    if (theme === "models") {
      return "green";
    } else if (theme === "influencers") {
      return "red";
    } else if (theme === "creatives") {
      return "orange";
    } else {
      return "transparent";
    }
  };

  useEffect(() => {
    if (!showOptions) return;

    setTimeout(() => {
      blockScroll();
    }, 100);
    return () => {
      activateScroll();
    };
  }, [showOptions]);

  return (
    <div
      className={`dropDownMultiContainer ${disabled ? "hidden" : ""}`}
      id={`${attribute}_select`}
    >
      {showOptions ? (
        <div className="dropdown">
          <div className="container">
            <div className="optionsBackground">
              <div className={`options ${fullOpacity ? "show" : null}`}>
                <h1>{modalLabel || label || generateLabel(attribute)}</h1>
                <img
                  src={generateCloseIcon(theme)}
                  className="closeIcon"
                  onClick={collapseOptions}
                />
                {options.map(option => (
                  <div
                    className={`option ${
                      selected.includes(option) ? `selected` : ``
                    } ${theme}`}
                    key={option}
                    onClick={() => changeAttribute(option)}
                  >
                    {option}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={collapseOptions}
                  className={`btn inverted-${themeSelect()}`}
                >
                  CONFIRM
                </button>
                {modalFooter ? <h3>{modalFooter}</h3> : null}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="inputContainer">
        <label htmlFor={`${attribute}_input`}>
          {label || generateLabel(attribute)}
        </label>
        <div className="inputWrapper">
          <div
            className={`selectionContainer ${theme}`}
            onClick={expandOptions}
          >
            {selected.map(option => (
              <div className="choice" key={option}>
                {option}
                <img
                  onClick={e => {
                    e.stopPropagation();
                    changeAttribute(option);
                  }}
                  src={CloseBlack}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {options.map(option => (
        <input
          key={option}
          type="checkbox"
          value={option}
          readOnly={true}
          hidden
          name={attrName}
          checked={selected.includes(option)}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default DropDownMulti;
