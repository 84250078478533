import React, { useState, useRef, useEffect } from "react";
import SocialButtons from "../SocialButtons";
import ProfilePreview from "../ProfilePreview";

const ShareProfile = ({ name, ig_handle, picture, number }) => {
  const [loading, setLoading] = useState(true);
  return (
    <div id="sharePage">
      <div className="container">
        <div className="content">
          <h1>You're in</h1>
          <div className="picture">
            <img
              src={picture}
              alt={`${ig_handle}`}
              onLoad={() => setLoading(false)}
              onError={() => {
                setLoading(false);
              }}
            />
          </div>
          <p className="memberNumber">Member number: #{number} </p>
          <div className="linkBox">
            <p>PROFILE LINK:</p>
            <div className="profileUrl">
              <p>agency.pics/{ig_handle}</p>
            </div>
          </div>
          <div className="text">
            <p>
              Email it to clients, share it with friends, use it on your
              Instagram Bio.
            </p>
            <p>The More clicks your profile gets the higher will rank.</p>
          </div>

          <SocialButtons
            btnText="Share your profile"
            data={{ ig_handle, name: name }}
          />
          <ProfilePreview ig_handle={ig_handle} text="See your profile" />
        </div>
      </div>
    </div>
  );
};

export default ShareProfile;

// <%= react_component("SocialButtons", color: "green", btnText: "Share your profile", data: {ig_handle: current_user&.profile&.ig_handle, name: current_user&.display_name}) %>
// <%= react_component("ProfilePreview", text: "See your profile", id: current_user&.profile&.id ) %>
// <p className='memberNumber'>Member number: #<%= @profile.membership_number %></p>
