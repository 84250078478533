import { checkMobile } from "../../utils/isMobile";

const attachScrollListener = elements => {
  elements.forEach(input => {
    input.addEventListener("focus", e => {
      if (e.currentTarget.readOnly == true) return;
      if (e.currentTarget.getBoundingClientRect().y <= 200) return;
      window.scroll(
        0,
        e.currentTarget.getBoundingClientRect().top + window.scrollY - 100
      );
    });
  });
};

const initScrollOnInputFocus = () => {
  if (!checkMobile()) return;
  attachScrollListener(document.querySelectorAll("form input[type='text']"));
  attachScrollListener(document.querySelectorAll("form input[type='email']"));
  attachScrollListener(
    document.querySelectorAll("form input[type='password']")
  );
};

export { initScrollOnInputFocus };
