import React, { useState } from "react";

const RailsInput = ({
  label,
  name,
  placeholder,
  defaultValue,
  errors = [],
  type = "text",
  customClassName = ""
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className={`customInputWrapper ${customClassName}`}>
      <label className="inputLabel">{label}</label>
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={e => setValue(e.target.value)}
        name={name}
        className="customInput"
      />
      {errors.map((error, i) => (
        <span className="textRed textSm" key={i}>
          {`${label} ${error}`}
        </span>
      ))}
    </div>
  );
};

export default RailsInput;
