import React, { useState, useRef } from "react";
import Close from "../images/close.png";
import Faq from "./Faq";
import Terms from "./Terms";
import ConfirmIcon from "../images/confirm.png";
const Checkbox = ({
  label,
  url,
  buttonText,
  faq,
  name,
  value,
  page,
  with_modal,
  defaultChecked,
  textOver = false,
  errors = []
}) => {
  const [checked, setChecked] = useState(defaultChecked);
  const [openModal, setOpenModal] = useState(false);
  const input = useRef();
  const handleClick = () => setChecked(prevState => !prevState);

  if (textOver) {
    return (
      <div className="customCheckboxWrapper customCheckbox">
        <input
          type="checkbox"
          className="hidden"
          value={value}
          checked={checked}
          readOnly={true}
          name={name}
        />
        <label
          onClick={handleClick}
          className={`labelButton ${checked ? "active" : ""}`}
        >
          {label}
        </label>
      </div>
    );
  }

  return (
    <div className={`customCheckboxWrapper ${with_modal ? "withModal" : ""}`}>
      {openModal ? (
        <div id="faqModal">
          <img src={Close} onClick={() => setOpenModal(false)} />
          <div className="container">
            <div className="body">
              {faq ? (
                <Faq modal={true} closeModal={() => setOpenModal(false)} />
              ) : (
                <Terms closeModal={() => setOpenModal(false)} modal={true} />
              )}
            </div>
          </div>
        </div>
      ) : null}
      <label>
        {label}
        <span onClick={() => setOpenModal(true)}>{buttonText}</span>
      </label>
      <input
        ref={input}
        hidden
        type="checkbox"
        value={value}
        checked={checked}
        onChange={() => setChecked(prevState => !prevState)}
        name={name}
      />
      <div
        onClick={() => input?.current?.click()}
        className={`checkMark ${checked ? "green" : "white"}`}
      >
        <img src={ConfirmIcon} />
      </div>
      {errors.map((error, i) => (
        <span key={i} className="textRed textSm">
          {error}
        </span>
      ))}
    </div>
  );
};

export default Checkbox;
