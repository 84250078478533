import React from "react";

const Terms = ({ modal, closeModal }) => {
  return (
    <div id="privacy">
      <div className="">
        <h1>PRIVACY POLICY</h1>
        <p>Last Updated: December 21st, 2020</p>
        <p>
          Agency.Pics respects your concerns about privacy. This privacy policy
          (“Privacy Policy”) describes the types of personal information we
          collect about individuals on www.agency.pics (the “Site”), how we may
          use the information, with whom we may share it, your rights and the
          choices available regarding our use of the information. The Privacy
          Policy also describes the measures we take to safeguard the personal
          information, how long we retain it and how individuals can contact us
          about our privacy practices and to exercise their rights.
        </p>

        <h4>Information we obtain</h4>
        <p>
          In connection with your use of the Site, you provide personal
          information to us in various ways. The types of personal information
          we obtain include personal information contained in content you
          submit, such as when you email us by clicking on Agency.Pics email
          address hyperlinks on the Site or by creating Jobs or profiles. You
          are not required to provide this information but, if you choose not to
          do so, we may not be able to offer you certain of our services and
          features of the Site.
        </p>
        <h4>How we use the information we obtain</h4>
        <p>
          We use the information we obtain on the Site on the basis of our
          legitimate interests, which we would be glad to describe further upon
          request, including to:
        </p>
        <ul>
          <li>provide our services to you;</li>
          <li>market our services to you;</li>
          <li>respond to your requests and inquiries;</li>
          <li>
            send you newsletters and other communications if you sign up to
            receive them;
          </li>
          <li>
            operate, evaluate and improve our business (including developing new
            services; enhancing and improving our services; managing our
            communications; and performing accounting, auditing and other
            internal functions);
          </li>
          <li>
            protect against, identify and prevent fraud and other criminal
            activity, claims and other liabilities;
          </li>
          <li>
            comply with and enforce applicable legal requirements, relevant
            industry standards and our policies, including this Privacy Policy.
          </li>
        </ul>
        <p>
          We may also use the personal information we obtain on the Site to
          analyse our services, personalise your experience on the Site and
          perform data analytics and market research. As required by applicable
          law, we will obtain your consent to process personal information for
          these purposes. We also may use the information we obtain in other
          ways for which we provide specific notice at the time of collection.
        </p>
        <h4>Automated collection of data</h4>
        <p>
          When you use the Site, we obtain certain information by automated
          means, such as pixel tags. The information we obtain in this manner
          could include your device IP address, domain name, identifiers
          associated with your devices, device and operating system type and
          characteristics, web browser characteristics, language preferences,
          clickstream data, your interactions with the Site (such as the web
          pages you visit, links you click and features you use), the pages that
          led or referred you to the Site, dates and times of access to the
          Site, and other information about your use of the Site.
        </p>
        <p>
          A “cookie” is a text file that websites send to a visitor’s computer
          or other Internet-connected device to uniquely identify the visitor’s
          browser or to store information or settings in the browser. This
          technology helps us (1) remember your information so you will not have
          to re-enter it; (2) track and understand how you use and interact with
          the Site; (3) tailor the Site around your preferences; (4) measure the
          usability of the Site; (5) understand the effectiveness of our
          communications; and (6) otherwise manage and enhance the Site.
        </p>
        <p>
          We do not obtain personal information about your online activities
          over time or across third-party websites, devices or other online
          services. On the Site, we use third-party online analytics services,
          such as those of Google Analytics. To the extent required by
          applicable law, we will obtain your consent before using third-party
          analytics cookies. The service providers that administer these
          analytics services use automated technologies to collect data (such as
          email address, IP addresses, cookies and other device identifiers) to
          evaluate, for example, use of the Site and to diagnose technical
          issues. To learn more about Google Analytics and how to manage your
          preferences, please visit
          https://support.google.com/analytics/answer/6004245.
        </p>
        <h4>Information Sharing</h4>
        <p>
          We do not disclose personal information we obtain about you, except as
          described in this Privacy Policy. We share your personal information
          with our (1) subsidiaries and affiliates and (2) service providers who
          perform services on our behalf, including data analytics providers,
          for the purposes described in this Privacy Policy. We do not authorise
          our service providers to use or disclose the information except as
          necessary to perform services on our behalf or comply with legal
          requirements.
        </p>
        <p>
          We also may disclose information about you (1) if we are required to
          do so by law or legal process (such as a court order or subpoena); (2)
          in response to requests by government agencies, such as law
          enforcement authorities; (3) to establish, exercise or defend our
          legal rights; (4) when we believe disclosure is necessary or
          appropriate to prevent physical or other harm or financial loss; (5)
          in connection with an investigation of suspected or actual illegal
          activity; (6) in the event we sell or transfer all or a portion of our
          business or assets (including in the event of a reorganisation,
          dissolution or liquidation); or (7) otherwise with your consent.
        </p>
        <h4>Your rights and choices</h4>
        <p>
          We offer you certain choices in connection with the personal
          information we obtain about you. To update your preferences, update or
          correct your information, limit the communications you receive from
          us, or submit a request, please contact us as specified in the How to
          Contact Us section of this Privacy Policy. You also can unsubscribe
          from our marketing mailing lists by following the “Unsubscribe” link
          in our emails.
        </p>
        <p>
          To the extent provided by applicable law, you have the right to (1)
          request access to the personal information we maintain about you; (2)
          request that we update, correct, amend, erase or restrict the
          information; or (3) exercise your right to data portability, by
          contacting us as indicated below. Depending on your location, you may
          have the right to file a complaint with a government regulator if you
          are not satisfied with our response. If you are located in the EEA and
          are not satisfied with our responses, you may lodge a complaint with
          the data protection authority in your country of residence, place of
          work or place of the alleged infringement of the GDPR. Where provided
          by law, you may withdraw any consent you previously provided to us or
          object at any time to the processing of your personal information on
          legitimate grounds relating to your particular situation, and we will
          apply your preferences going forward. To help protect your privacy and
          maintain security, we may take steps to verify your identity before
          granting you access to your information. To the extent permitted by
          applicable law, a charge may apply before we provide you with a copy
          of any of your personal information that we maintain.
        </p>
        <h4>Other online services and third-party features</h4>
        <p>
          The Site may provide links to other online services and websites for
          your convenience and information (e.g., Facebook, Vimeo Instagram,
          LinkedIn and Twitter buttons). These services, websites, and
          third-party features may operate independently from us. The privacy
          practices of these third parties, including details on the information
          they may collect about you, are subject to the privacy statements of
          these parties, which we strongly suggest you review. To the extent any
          linked online services or third-party features are not owned or
          controlled by us, Agency.Pics is not responsible for these third
          parties’ information practices.
        </p>
        <h4>Retention of personal information</h4>
        <p>
          To the extent permitted by applicable law, we retain personal
          information we obtain about you for the duration of our relationship
          with you, plus a reasonable period to be able to run regular deletion
          routines, to take into account the applicable statute of limitations
          period or to comply with mandatory applicable law.
        </p>
        <h4>How we protect personal information</h4>
        <p>
          We maintain administrative, technical and physical safeguards designed
          to protect personal information we obtain through the Site against
          accidental, unlawful or unauthorised destruction, loss, alteration,
          access, disclosure or use.
        </p>

        <h4>Children’s personal information</h4>
        <p>
          The Site is designed for a general audience and is not directed to
          children under the age of 16. Agency.Pics does not knowingly collect
          or solicit personal information from children under the age of 16
          through the Site. If we learn that we have collected personal
          information from a child under the age of 16, we will promptly delete
          that information from our records. If you believe that a child under
          the age of 16 may have provided us with personal information, please
          contact us as specified in the How to Contact Us section below.
        </p>

        <h4>Updates to our privacy policy</h4>
        <p>
          This Privacy Policy may be updated periodically to reflect changes in
          our personal information practices. We will indicate at the top of the
          notice when it was most recently updated.
        </p>
        <h4>How to contact us</h4>
        <p>
          To update your preferences, update or correct your information, submit
          a request, ask us questions about this Privacy Policy, or exercise
          other applicable privacy rights, please contact us by email at
          info@agency.pics
        </p>
        <h4>Your acceptance of these terms</h4>
        <p>
          By using this Site, you signify your acceptance of this policy. If you
          do not agree to this policy, please do not use our Site. Your
          continued use of the Site following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </p>
        <p>Agency.Pics is part of The Community Creatives LTD. group</p>
        {modal ? (
          <button onClick={closeModal} className="btn inverted-green">
            Close
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Terms;
