const spinner = () => {
  return "<div id='spinner'><div id='loading'></div><p>Uploading</p></div>";
};

const startSpinner = button => {
  button?.addEventListener("click", () => {
    document.querySelector("body").insertAdjacentHTML("afterbegin", spinner());
    setTimeout(() => {
      document.querySelector(".spinner")?.remove();
    }, 8000);
  });
};

const initProfileImageForm = () => {
  const avatarInput = document.querySelector("#profile_avatar");
  const image = document.querySelector(".photoContainer");
  image?.addEventListener("click", () => {
    avatarInput?.click();
  });
  const buttonProfile = document.querySelector(
    "#editProfile input[type='submit']"
  );
  startSpinner(buttonProfile);
};
const initImagesForm = () => {
  const photoInput = document.querySelector("#profile_photo");
  const imagesPlaceholder = document.querySelectorAll(
    ".profilesGrid .emptyPicture"
  );
  const imageUploaded = document.querySelectorAll(
    "#pictures .profilesGrid .picture"
  );
  imagesPlaceholder.forEach(img => {
    img.addEventListener("click", () => {
      photoInput?.click();
    });
  });
  const buttonPictures = document.querySelector(
    "#pictures input[type='submit']"
  );
  const image = imagesPlaceholder[0] || imageUploaded[0];
  const height = (image?.offsetWidth / 4) * 5;
  imagesPlaceholder.forEach(i => (i.style.height = `${height}px`));
  imageUploaded.forEach(i => (i.style.height = `${height}px`));
  startSpinner(buttonPictures);
};

export { initImagesForm, initProfileImageForm, startSpinner };
