import React, { useState, useEffect, useRef } from "react";

const SubscriptionCard = ({ active, plan, setType, i, days }) => {
  return (
    <div
      onClick={() => {
        setType && setType(i);
      }}
      className={`subscriptionBox ${active ? "active" : ""}`}
    >
      <div>
        <h4>{plan.description}</h4>
        <p>{plan.amount}</p>
        {days ? <p className="freeTrial">{days} days free trial</p> : null}
      </div>
    </div>
  );
};

const SubscriptionForm = ({ subscription_type, days }) => {
  const [type, setType] = useState(0);
  const input = useRef();
  useEffect(() => {
    if (type >= 0 && type <= 3) {
      input.current.value = type;
    }
  }, [type]);

  const plans = [
    {
      description: "Pay Monthly",
      amount: "Billed £2 every month"
    },
    {
      description: "Pay every 3 months",
      amount: "Billed £5 every 3 months"
    },
    {
      description: "Pay every 6 months",
      amount: "Billed £8 every 6 months"
    }
  ];

  return (
    <div id="subscriptionsForm">
      <input type="number" name="subscription[type]" ref={input} hidden />
      {subscription_type !== undefined ? (
        <SubscriptionCard
          plan={plans[subscription_type]}
          type={type}
          active={true}
          days={days}
        />
      ) : (
        plans.map((plan, i) => (
          <SubscriptionCard
            key={i}
            plan={plan}
            setType={setType}
            active={type === i}
            i={i}
          />
        ))
      )}
      {subscription_type !== undefined ? null : (
        <>
          <label htmlFor="couponInput">ADD YOUR DISCOUNT CODE HERE:</label>
          <input id="couponInput" type="text" name="subscription[coupon]" />
          <input
            type="submit"
            value="confirm"
            className="btn btn-green"
            placeholder="yourcode"
          />
        </>
      )}
    </div>
  );
};

export default SubscriptionForm;
