import React, { useState, useEffect } from "react";
import axios from "axios";
import { csrfToken } from "@rails/ujs";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";

const { red, yellow, orange } = COLORS;

const GigApplyBox = ({ id, theme, see_more_jobs }) => {
  const [fetchingApplication, setFetchingApplication] = useState();
  const [canApply, setCanApply] = useState();
  const [applied, setApplied] = useState();
  const themeSelect = () => {
    if (theme === "models") {
      return "green";
    } else if (theme === "influencers") {
      return "red";
    } else if (theme === "creatives") {
      return "orange";
    } else {
      return "transparent";
    }
  };
  const colorSelect = () => {
    if (theme === "influencers") {
      return red;
    } else if (theme === "creatives") {
      return orange;
    }

    return yellow;
  };

  const requestApplication = async () => {
    const response = await axios.get(`/api/v1/gigs/${id}/request_application`);
    const { data } = response;
    setCanApply(data);
  };

  const submitApplication = async id => {
    setFetchingApplication(true);
    const params = new URLSearchParams();
    params.append("id", id);
    axios.defaults.headers["X-CSRF-Token"] = csrfToken();
    const response = await axios.post("/api/v1/gig_applications", params);
    if (response.data?.id) {
      setApplied(true);
    }
    setFetchingApplication(false);
  };

  useEffect(() => {
    requestApplication();
  }, []);

  const applicationRequestResponse = see_more_jobs => {
    if (applied) {
      return <h4>Thanks! Your application has been received.</h4>;
    } else if (canApply.has_applied) {
      return (
        <>
          <h4>You have already applied for this job.</h4>
          <a
            href="/jobs"
            className={`btn accessBtn full inverted-${themeSelect()}`}
          >
            See more jobs
          </a>
        </>
      );
    } else if (fetchingApplication) {
      return (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={themeSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      );
    } else if (canApply.can_apply) {
      return (
        <button
          onClick={() => {
            submitApplication(id);
          }}
          className={`btn green-btn btn-${themeSelect()}`}
        >
          APPLY
        </button>
      );
    } else {
      return (
        <div className="applicationsBox">
          {canApply.login ? (
            <h4>You need to log in or register to view this job.</h4>
          ) : (
            // eslint-disable-next-line react/no-unescaped-entities
            <h4>Sorry, you can't apply for this job.</h4>
          )}
          <>
            <ul>
              {canApply.message?.map(m => (
                <li key={m}>
                  <div className={`messageBox ${themeSelect()}`}></div>
                  {m}
                </li>
              ))}
            </ul>
            {see_more_jobs && !canApply.login ? (
              <button
                onClick={() => (window.location = "/jobs")}
                className={`btn accessBtn inverted-${themeSelect()}`}
              >
                SEE MORE JOBS
              </button>
            ) : null}
          </>
          {canApply.login ? (
            <div>
              <button
                onClick={() => (window.location = "/users/sign_up")}
                className={`btn accessBtn ${themeSelect()}`}
              >
                REGISTER
              </button>
              <button
                onClick={() => (window.location = "/users/sign_in")}
                className={`btn accessBtn ${themeSelect()}`}
              >
                LOG IN
              </button>
              <a
                href={`/${theme}`}
                className={`btn accessBtn full inverted-${themeSelect()}`}
              >
                Search Talent
              </a>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <div id="gigApplyBox" className="applicationInfo">
      {canApply === undefined ? (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={colorSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      ) : canApply ? (
        applicationRequestResponse(see_more_jobs)
      ) : null}
    </div>
  );
};

export default GigApplyBox;
