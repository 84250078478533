import React, { useState, useEffect, useRef } from "react";
import { clearEmptyObject } from "../utils/clearEmptyObject";
import downArrow from "../images/down-arrow.svg";
import useDidMountEffect from "./hooks/useDidMountEffect";

const FilterButton = ({ list, text, name, page, setQuery, query }) => {
  const [show, setShow] = useState(false);
  const [fadeIn, setFadeIn] = useState("");
  const [fadeOut, setFadeOut] = useState("");
  const [sectorHeight, setSectorHeight] = useState(30);
  const [clicked, setClicked] = useState(false);
  const optionsWrapperRef = useRef(null);
  const input = useRef();
  const checked = i =>
    query && query[name] && query[name].includes(i.toString());
  let selectedClass = "";

  if (page === "influencers") {
    selectedClass = "influencers";
  } else if (page === "creatives") {
    selectedClass = "creatives";
  }

  const fadeInAnimation = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        setFadeIn("fadeInFinished");
        resolve();
      }, 300);
    });
  };
  const fadeOutAnimation = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        setFadeOut("fadeOutFinished");
        resolve();
      }, 300);
    });
  };
  const animate = async () => {
    setClicked(true);
    setShow(prevState => !prevState);
    if (show) {
      setFadeOut("");
      setFadeIn("fadeInProgress");
      await fadeInAnimation();
    } else {
      setFadeIn("");
      setFadeOut("fadeOutProgress");
      await fadeOutAnimation();
    }
  };

  const categorySelected = () => query[name];

  const changeCheckBox = e => {
    if (e.target.tagName === "INPUT") return;
    e.currentTarget.querySelector("input").click();
  };

  const handleChange = e => {
    if (!query) return;
    const { name, value, checked } = e.target;

    if (name in query) {
      if (checked && !query[name].includes(value)) {
        setQuery(prevState => ({
          ...prevState,
          [name]: [...prevState[name], value]
        }));
      } else {
        setQuery(prevState => ({
          ...prevState,
          [name]: prevState[name].filter(ele => ele !== value)
        }));
      }
    } else {
      setQuery(prevState => ({
        [name]: [value],
        ...prevState
      }));
    }
    setQuery(prevState => clearEmptyObject(prevState));
  };

  useEffect(() => {
    if (!show) {
      setSectorHeight(36);
    } else if (optionsWrapperRef && optionsWrapperRef.current) {
      setSectorHeight(optionsWrapperRef.current.offsetHeight + 55);
    }
  }, [show]);

  useEffect(() => {
    if (!query) return;
    if (query[name] !== undefined) {
      setClicked(true);
    }
    setFadeOut("fadeOutFinished");
    if (!clicked) {
      setShow(query[name] !== undefined);
    }
  }, [query]);

  useDidMountEffect(() => {
    if (name in query) {
      setShow(true);
      setSectorHeight(
        optionsWrapperRef.current
          ? optionsWrapperRef.current.offsetHeight + 40
          : 36
      );
    } else {
      setShow(false);
      setSectorHeight(36);
    }
  }, [page, query]);

  return (
    <div
      className={`filterButton ${show ? "" : "withBottomBorder"} ${fadeOut}`}
      style={{ height: `${sectorHeight}px` }}
    >
      <button
        className="expandButton"
        onClick={animate}
        disabled={fadeIn === "fadeInProgress" || fadeOut === "fadeOutProgress"}
      >
        <span
          className={`filterText ${
            categorySelected() ? "selected" : ""
          } ${selectedClass}`}
        >
          {text}
        </span>
        <div className={`expandIcon ${page}`}>
          <img
            src={downArrow}
            alt="down-arrow"
            width={12}
            height={12}
            className={`${show ? "openImg" : ""}`}
          />
        </div>
      </button>
      {show ? (
        <div
          className={`filterList ${
            fadeOut === "fadeOutFinished" ? "show" : ""
          } `}
          ref={optionsWrapperRef}
        >
          <div className={`options ${fadeIn} ${fadeOut}`}>
            {list.map((option, i) => (
              <div
                className={`option ${page ? page : ""}`}
                key={i}
                onClick={changeCheckBox}
              >
                <span
                  className={`checkboxLabel ${checked(i) ? "selected" : ""} ${
                    page ? page : ""
                  }`}
                >
                  {option.text}
                </span>
                <div className="checkboxContainer">
                  <input
                    className={`customCheckbox`}
                    type="checkbox"
                    value={option.value}
                    name={name}
                    onChange={handleChange}
                    ref={input}
                  />
                  <span
                    className={`checkmark ${checked(i) ? "checked" : null}`}
                  ></span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterButton;
