const LABEL_LENGTH_WITHOUT_ALL = 3;

const initCompleteProfileForm = () => {
  const textInputs = document.querySelectorAll("input[type='text']");
  const phoneInput = document.querySelector("input[type='tel']");
  const checkboxInput = document.querySelectorAll("input[type='checkbox']");
  const allCheckbox = document.getElementById("All");
  const submitBtn = document.querySelector("#completeProfile");
  const buttonBox = document.querySelector(".form-action-click");
  const errorsBox = document.querySelector("#errors");

  const displayErrors = recruiter => {
    const content = validateForm(recruiter)
      .map(e => `<li>${e}</li>`)
      .join("");
    errorsBox.innerHTML = `<h4>Please check the errors below:</h4>${content}`;
    window.scrollTo(0, 0);
  };

  const validateForm = recruiter => {
    const messages = [];
    if (!recruiter) {
      const checkValid = Array.from(checkboxInput)
        .slice(0, LABEL_LENGTH_WITHOUT_ALL)
        .map(input => input.checked)
        .includes(true);
      if (!checkValid)
        messages.push(
          "Select your profile type. Model, influencer, creative or all?"
        );
      let textValid = true;
      textInputs.forEach(input => {
        if (input.value.length === 0 || input.value === "@") textValid = false;
      });
      if (!textValid)
        messages.push("First name, last name and IG profile are mandatory.");
    } else {
      let textValid = true;
      textInputs.forEach(input => {
        if (input.value.length === 0 || input.value === "@") textValid = false;
      });
      if (!textValid) messages.push("First name and last name are mandatory.");
    }

    if (phoneInput.value.length < 7)
      messages.push("Please add a valid phone number.");
    return messages;
  };

  const displaySubmit = () => {
    const errors = validateForm(
      document.querySelector("#recruiterCompletePage")
    );
    submitBtn.disabled = errors.length > 0;
    buttonBox.style.display = errors.length > 0 ? "block" : "none";
  };
  const completeProfilePage =
    document.querySelector("#completeProfilePage") ||
    document.querySelector("#recruiterCompletePage");
  if (completeProfilePage) {
    const labels = Array.from(document.querySelectorAll(".typeOptions label"));

    labels.forEach(label => {
      // 60-64: To handle selected type from previous page
      const input = label.nextElementSibling;
      if (input && input.checked) {
        label.classList.add("selected");
      }
      label.addEventListener("click", () => {
        if (label === allCheckbox.previousElementSibling) {
          label.classList.toggle("selected");
          // Check the opposite
          if (!allCheckbox.checked) {
            labels.slice(0, LABEL_LENGTH_WITHOUT_ALL).forEach(l => {
              l.classList.add("selected");
              l.nextElementSibling.checked = true;
            });
          } else {
            labels.slice(0, LABEL_LENGTH_WITHOUT_ALL).forEach(l => {
              l.classList.remove("selected");
              l.nextElementSibling.checked = false;
            });
          }
        } else {
          label.classList.toggle("selected");

          if (label.nextElementSibling.checked) {
            allCheckbox.checked = false;
            allCheckbox.previousElementSibling.classList.remove("selected");
          }
        }
      });
    });

    [...textInputs, phoneInput, ...checkboxInput].forEach(input => {
      input.addEventListener("change", displaySubmit);
    });
    buttonBox.addEventListener("click", () => {
      if (submitBtn.disabled) {
        displayErrors(document.querySelector("#recruiterCompletePage"));
      }
    });
  }
};

export { initCompleteProfileForm };
