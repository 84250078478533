const confirmIcon = () =>
  '<div class="sa"><div class="sa-success"><div class="sa-success-tip"></div><div class="sa-success-long"></div><div class="sa-success-placeholder"></div><div class="sa-success-fix"></div></div></div>';

const alertIcon = () =>
  '<?xml version="1.0" encoding="UTF-8"?><svg width="207px" height="207px" viewBox="0 0 207 207" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>exclamation</title><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="exclamation" fill="#EF4243" fill-rule="nonzero"><path d="M103.5,0 C46.2907793,0 0,46.2948223 0,103.5 C0,160.709221 46.2948223,207 103.5,207 C160.709221,207 207,160.705178 207,103.5 C207,46.2907793 160.705178,0 103.5,0 Z M103.5,190.828125 C55.2297832,190.828125 16.171875,151.766982 16.171875,103.5 C16.171875,55.2297832 55.2330176,16.171875 103.5,16.171875 C151.770217,16.171875 190.828125,55.2330176 190.828125,103.5 C190.828125,151.770217 151.766982,190.828125 103.5,190.828125 Z" id="Shape"></path><path d="M104,52 C99.5816,52 96,55.6072112 96,60.0572062 L96,111.942794 C96,116.392789 99.5816,120 104,120 C108.4184,120 112,116.392789 112,111.942794 L112,60.0572062 C112,55.6072112 108.4184,52 104,52 Z" id="Path"></path><circle id="Oval" cx="104" cy="141" r="11"></circle></g></g></svg>';

const initAlert = () => {
  const notice = document.querySelector("#flash.notice");
  const alertBox = document.querySelector("#flash.alert");
  const currentAlert = notice || alertBox;

  if (!currentAlert) return;
  if (currentAlert.dataset.test == "true") return;
  const icon = notice ? confirmIcon() : alertIcon();
  const body = document.querySelector("body");
  const message = currentAlert.dataset.message;
  body.insertAdjacentHTML(
    "afterbegin",
    `<div class='alertContainer'><div class="alertBox">${icon}${message}</div></div>`
  );
  setTimeout(() => {
    document.querySelector(".alertContainer").remove();
  }, 2000);
};

export { initAlert };
