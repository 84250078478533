import React, { useState, useEffect } from "react";
import axios from "axios";
import { csrfToken } from "@rails/ujs";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";

const { red, yellow, orange } = COLORS;

const OrderApplyBox = ({ id, theme, see_more_jobs }) => {
  const [fetchingApplication, setFetchingApplication] = useState();
  const [canApply, setCanApply] = useState();
  const [applied, setApplied] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [contentUrl, setContentUrl] = useState('');
  const themeSelect = () => {
    if (theme === "models") {
      return "green";
    } else if (theme === "influencers") {
      return "red";
    } else if (theme === "creatives") {
      return "orange";
    } else {
      return "transparent";
    }
  };
  const colorSelect = () => {
    if (theme === "influencers") {
      return red;
    } else if (theme === "creatives") {
      return orange;
    }
    return yellow;
  };

  const makeStatus = (status) => {
    if (status === "accepted"){
      return "Order is Accepted successfully."
    }else if (status === "rejected"){
      return "Order is Rejected successfully."
    }
    else if (status === "delivered"){
      return "Thanks! Order is Delivered."
    }else if (status === "delivery_accepted"){
      return "The delivery has been accepted successfully."
    }else if (status === "delivery_rejected"){
      return "The delivery has been rejected and an administrator will be in touch within 3 working days."
    } else{
      return "Thanks! Order is Accepted."
    }
  };

  const makeActions = (user, status) => {
    if(user === "sender"){
      return (
        <>
          <p><strong>Content URL: </strong> {contentUrl}</p>
        </>
      )
    }else if (user === "receiver"){
      return (
        <>
          {
            status === "rejected" ? null :
              (
                <>
                  <div className="labelBox">
                    <label>Content URL</label>
                    <span id="contentURL"></span>
                  </div>
                  <div className="form-group string required content_url">
                    <input
                      className="form-control string"
                      type="text"
                      id="content_url"
                      value={contentUrl}
                      onChange={handleContentUrlChange}
                    />
                  </div>
                  <button
                    onClick={() => {
                      updateOrderStatus(id,"deliver");
                    }}
                    className={`btn green-btn btn-${themeSelect()}`}
                  >
                    { orderStatus === "delivered" ? "Deliver Again" : "Deliver" }
                  </button>
                </>
              )
          }
        </>
      )
    }
  };

  const requestApplication = async () => {
    const response = await axios.get(`/order_actions_info?id=${id}`);
    const { data } = response;
    setCanApply(data);
    setContentUrl(response.data.content_url);
    setOrderStatus(response.data.order_status);
  };

  const updateOrderStatus = async (id, status) => {
    setFetchingApplication(true);
    const params = new URLSearchParams();

    params.append("id", id);
    params.append("status", status);
    if(status === "deliver") {
      const contentUrl = document.getElementById('content_url').value
      params.append("contentUrl", contentUrl);
    }
    axios.defaults.headers["X-CSRF-Token"] = csrfToken();
    const response = await axios.post("/update_order_status", params);
    if (response.data?.id) {
      setApplied(true);
      setOrderStatus(response.data.order_status);
    }
    setFetchingApplication(false);
  };

  useEffect(() => {
    requestApplication();
  }, []);


  const handleContentUrlChange = (event) => {
    setContentUrl(event.target.value);
  };

  const applicationRequestResponse = see_more_jobs => {
    if (applied) {
      return (
        <>
          <h4> { makeStatus(orderStatus) }</h4>
          <div className="serviceDescription">
            {makeActions(canApply.is_sender ? 'sender' : "receiver", orderStatus )}
          </div>
        </>
      )
    } else if (fetchingApplication) {
      return (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={themeSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      );
    } else if (canApply.is_sender){
      return (
        <div className="applicationsBox">

          {canApply.pending ? (
            <h4>Order is not accepted yet.</h4>
          ) : null }

          {canApply.accepted ? (
            <h4>Order is accepted and under process.</h4>
          ) : null }

          {canApply.rejected ? (
            <h4>Order is rejected by the seller. Your payment will be refunded to your account in 3-5 working days.</h4>
          ) : null }

          {canApply.delivered ? (
            <>
              <h4>Order is delivered please find content URL.</h4>
              <div>
                <p><strong>Content URL: </strong>{contentUrl}</p>
                <div className="">
                  <button
                    onClick={() => {
                      updateOrderStatus(id,"accept_delivery");
                    }}
                    className={`btn green-btn btn-${themeSelect()}`}
                  >
                    Accept Delivery
                  </button>
                  <button
                    onClick={() => {
                      updateOrderStatus(id, "reject_delivery");
                    }}
                    className={`btn red-btn btn-${themeSelect()}`}
                  >
                    Reject Delivery
                  </button>
                </div>
              </div>
            </>
          ) : null }

          {canApply.delivery_accepted ? (
            <>
              <h4>You accepted the service delivery.</h4>
              <p><strong>Content URL: </strong> {contentUrl}</p>
            </>
          ) : null }

          {canApply.delivery_rejected ? (
            <>
              <h4>You rejected service delivery, an administrator will be in touch within 3 working days.</h4>
              <p><strong>Content URL:  </strong> {contentUrl}</p>
            </>
          ) : null }
        </div>
      )
    }
    else if (canApply.is_receiver){
      return (
        <div className="applicationsBox">

          {canApply.pending ? (
            <>{}
              <h4>Order is not accepted yet.</h4>
              <div className="">
                <button
                  onClick={() => {
                    updateOrderStatus(id,"accept");
                  }}
                  className={`btn green-btn btn-${themeSelect()}`}
                >
                  Accept
                </button>
                <button
                  onClick={() => {
                    updateOrderStatus(id, "reject");
                  }}
                  className={`btn red-btn btn-${themeSelect()}`}
                >
                  Reject
                </button>
              </div>
            </>
          ) : null }

          {canApply.accepted ? (
            <>
              <h4>Thanks! Order is Accepted.</h4>
              <div className="serviceDescription">
                <div className="labelBox">
                  <label>Content URL</label>
                  <span id="contentURL"></span>
                </div>
                <div className="form-group string required service_title">
                  <input
                    className="form-control string"
                    type="text"
                    id="content_url"
                    value={contentUrl}
                    onChange={handleContentUrlChange}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  updateOrderStatus(id,"deliver");
                }}
                className={`btn green-btn btn-${themeSelect()}`}
              >
                Deliver
              </button>
            </>
          ) : null }

          {canApply.delivered ? (
            <>
              <h4>Order is delivered.</h4>
              <div className="serviceDescription">
                <div className="labelBox">
                  <label>Content URL</label>
                  <span id="contentURL"></span>
                </div>
                <div className="form-group string required content_url">
                  <input
                    className="form-control string"
                    type="text"
                    id="content_url"
                    value={contentUrl}
                    onChange={handleContentUrlChange}
                  />
                </div>
              </div>
              <button
                onClick={() => {
                  updateOrderStatus(id,"deliver");
                }}
                className={`btn green-btn btn-${themeSelect()}`}
              >
                Deliver Again
              </button>
            </>
          ) : null }

          {canApply.rejected ? (
            <h4>You reject the order, an administrator will be in touch within 3 working days.</h4>
          ) : null }

          {canApply.delivery_accepted ? (
            <h4>Your delivery has been accepted, you will receive payment in 3 working days.</h4>
          ) : null }

          {canApply.delivery_rejected ? (
            <h4>Your service delivery has been rejected by the buyer, an administrator will be in touch within 3 working days.</h4>
          ) : null }

        </div>
        )
    }else {
      return (
        <div className="applicationsBox">
          {canApply.login ? (
            <h4>You need to log in or register to view this Order.</h4>
          ) : null }

          <>
            <ul>
              {canApply.message?.map(m => (
                <li key={m}>
                  <div className={`messageBox ${themeSelect()}`}></div>
                  {m}
                </li>
              ))}
            </ul>
          </>
          {canApply.login ? (
            <div>
              <button
                onClick={() => (window.location = "/users/sign_up")}
                className={`btn accessBtn ${themeSelect()}`}
              >
                REGISTER
              </button>
              <button
                onClick={() => (window.location = "/users/sign_in")}
                className={`btn accessBtn ${themeSelect()}`}
              >
                LOG IN
              </button>
              <a
                href={`/${theme}`}
                className={`btn accessBtn full inverted-${themeSelect()}`}
              >
                Search Talent
              </a>
            </div>
          ) : null}
        </div>
      );
    }
  };

  return (
    <div id="gigApplyBox" className="applicationInfo">
      {canApply === undefined ? (
        <div className="gigApplicationsBox application">
          <Loader
            type="TailSpin"
            color={colorSelect()}
            height={40}
            width={40}
            timeout={300000}
          />
        </div>
      ) : canApply ? (
        applicationRequestResponse(see_more_jobs)
      ) : null}
    </div>
  );
};

export default OrderApplyBox;
