export default [
  "Global",
  "Japan",
  "Indonesia",
  "United States",
  "South Korea",
  "Philippines",
  "India",
  "Brazil",
  "Mexico",
  "Egypt",
  "China",
  "Russia",
  "Argentina",
  "Turkey",
  "France",
  "Pakistan",
  "Nigeria",
  "United Kingdom",
  "Thailand",
  "Dem Rep Of Congo",
  "Iran",
  "Peru",
  "Colombia",
  "Bangladesh",
  "Germany",
  "Hong Kong",
  "Taiwan",
  "Viet Nam",
  "South Africa",
  "Iraq",
  "Canada",
  "Chile",
  "Malaysia",
  "Spain",
  "Sudan",
  "Saudi Arabia",
  "Singapore",
  "Myanmar",
  "Italy",
  "Côte D'ivoire",
  "Greece",
  "Australia",
  "Angola",
  "Algeria",
  "North Korea",
  "Ethiopia",
  "Kenya",
  "Afghanistan",
  "Morocco",
  "Tanzania",
  "Venezuela",
  "Senegal",
  "Jordan",
  "Israel",
  "Ecuador",
  "Ukraine",
  "Uzbekistan",
  "Poland",
  "Syria",
  "Portugal",
  "Tunisia",
  "Haiti",
  "Dominican Republic",
  "Cuba",
  "United Arab Emirates",
  "Netherlands",
  "Yemen",
  "Ghana",
  "Romania",
  "Sweden",
  "Azerbaijan",
  "Belarus",
  "Mali",
  "Hungary",
  "Austria",
  "Paraguay",
  "Serbia",
  "Libya",
  "Zimbabwe",
  "Bolivia",
  "Mozambique",
  "Cameroon",
  "Uruguay",
  "New Zealand",
  "Kazakhstan",
  "Cambodia",
  "Somalia",
  "Uganda",
  "Burkina Faso",
  "Czech Republic",
  "Bulgaria",
  "Armenia",
  "Switzerland",
  "Guinea",
  "Georgia",
  "Zambia",
  "Guatemala",
  "Belgium",
  "Madagascar",
  "Ireland",
  "Mongolia",
  "Nicaragua",
  "Honduras",
  "Kyrgyzstan",
  "Moldova",
  "Croatia",
  "Latvia",
  "Malawi",
  "Niger",
  "Nepal",
  "Benin",
  "Sri Lanka",
  "Jamaica",
  "Finland",
  "Mauritania",
  "Norway",
  "Lithuania",
  "Chad",
  "Bosnia And Herzegovina",
  "Tajikistan",
  "Laos",
  "Macedonia",
  "Tokyo, Japan",
  "Jakarta, Indonesia",
  "New York, United States",
  "Seoul, South Korea",
  "Manila, Philippines",
  "Mumbai, India",
  "Sao Paulo, Brazil",
  "Mexico City, Mexico",
  "Delhi, India",
  "Osaka, Japan",
  "Cairo, Egypt",
  "Kolkata, India",
  "Los Angeles, United States",
  "Shanghai, China",
  "Moscow, Russia",
  "Beijing, China",
  "Buenos Aires, Argentina",
  "Guangzhou, China",
  "Shenzhen, China",
  "Istanbul, Turkey",
  "Rio De Janeiro, Brazil",
  "Paris, France",
  "Karachi, Pakistan",
  "Nagoya, Japan",
  "Chicago, United States",
  "Lagos, Nigeria",
  "London, United Kingdom",
  "Bangkok, Thailand",
  "Kinshasa, Dem Rep Of Congo",
  "Tehran, Iran",
  "Lima, Peru",
  "Dongguan, China",
  "Bogota, Colombia",
  "Chennai, India",
  "Dhaka, Bangladesh",
  "Essen, Germany",
  "Tianjin, China",
  "Hong Kong, Hong Kong",
  "Taipei, Taiwan",
  "Lahore, Pakistan",
  "Ho Chi Minh City, Viet Nam",
  "Bangalore, India",
  "Hyderabad, India",
  "Johannesburg, South Africa",
  "Baghdad, Iraq",
  "Toronto, Canada",
  "Santiago, Chile",
  "Kuala Lumpur, Malaysia",
  "San Francisco, United States",
  "Philadelphia, United States",
  "Wuhan, China",
  "Miami, United States",
  "Dallas, United States",
  "Madrid, Spain",
  "Bali, Indonesia",
  "Ahmedabad, India",
  "Boston, United States",
  "Belo Horizonte, Brazil",
  "Khartoum, Sudan",
  "Saint Petersburg, Russia",
  "Shenyang, China",
  "Houston, United States",
  "Pune, India",
  "Riyadh, Saudi Arabia",
  "Singapore, Singapore",
  "Washington, United States",
  "Yangon, Myanmar",
  "Milan, Italy",
  "Atlanta, United States",
  "Chongqing, China",
  "Alexandria, Egypt",
  "Nanjing , China",
  "Guadalajara, Mexico",
  "Barcelona, Spain",
  "Chengdu, China",
  "Detroit, United States",
  "Ankara, Turkey",
  "Abidjan, Côte D'ivoire",
  "Athens, Greece",
  "Berlin, Germany",
  "Sydney, Australia",
  "Monterrey, Mexico",
  "Phoenix, United States",
  "Busan, South Korea",
  "Recife, Brazil",
  "Bandung, Indonesia",
  "Porto Alegre, Brazil",
  "Melbourne, Australia",
  "Luanda, Angola",
  "Hangzhou , China",
  "Algiers, Algeria",
  "Hà Noi, Viet Nam",
  "Montréal, Canada",
  "Xi'an , China",
  "Pyongyang, North Korea",
  "Qingdao , China",
  "Surat, India",
  "Fortaleza, Brazil",
  "Medellín, Colombia",
  "Durban, South Africa",
  "Kanpur, India",
  "Addis Ababa, Ethiopia",
  "Nairobi, Kenya",
  "Jeddah, Saudi Arabia",
  "Naples, Italy",
  "Kabul, Afghanistan",
  "Salvador, Brazil",
  "Harbin, China",
  "Kano, Nigeria",
  "Casablanca, Morocco",
  "Cape Town, South Africa",
  "Curitiba, Brazil",
  "Surabaya, Indonesia",
  "San Diego, United States",
  "Seattle, United States",
  "Rome, Italy",
  "Dar Es Salaam, Tanzania",
  "Taichung, China",
  "Jaipur, India",
  "Caracas, Venezuela",
  "Dakar, Senegal",
  "Kaohsiung, China",
  "Minneapolis, United States",
  "Lucknow, India",
  "Amman, Jordan",
  "Tel Aviv-yafo, Israel",
  "Guayaquil, Ecuador",
  "Kyiv, Ukraine",
  "Faisalabad, Pakistan",
  "Mashhad, Iran",
  "Izmir, Turkey",
  "Rawalpindi, Pakistan",
  "Tashkent, Uzbekistan",
  "Katowice, Poland",
  "Changchun, China",
  "Campinas, Brazil",
  "Daegu, South Korea",
  "Changsha, China",
  "Nagpur, India",
  "San Juan, Philippines",
  "Aleppo, Syria",
  "Lisbon, Portugal",
  "Frankfurt Am Main, Germany",
  "Nanchang, China",
  "Birmingham, United Kingdom",
  "Tampa, United States",
  "Medan, Indonesia",
  "Dalian, China",
  "Tunis, Tunisia",
  "Shijiazhuang, China",
  "Manchester, United Kingdom",
  "Port-au-prince, Haiti",
  "Damascus, Syria",
  "Ji'nan, China",
  "Fukuoka, Japan",
  "Santo Domingo, Dominican Republic",
  "Havana, Cuba",
  "Cali, Colombia",
  "Denver, United States",
  "St. Louis, United States",
  "Colombo, Brazil",
  "Dubai, United Arab Emirates",
  "Baltimore, United States",
  "Sapporo, Japan",
  "Rotterdam, Netherlands",
  "Vancouver, Canada",
  "Preston, United Kingdom",
  "Patna, India",
  "Sana'a, Yemen",
  "Warsaw, Poland",
  "Bonn, Germany",
  "Accra, Ghana",
  "Bucharest, Romania",
  "Yokohama, Japan",
  "Kunming, China",
  "Guiyang, China",
  "Zibo, China",
  "Incheon, South Korea",
  "Zhengzhou, China",
  "Taiyuan, China",
  "Chaoyang, China",
  "Brasilia, Brazil",
  "Zhongshan, China",
  "West Midlands, United Kingdom",
  "Giza, Egypt",
  "Quezon City, Philippines",
  "Nanhai, China",
  "Fuzhou, China",
  "Lanzhou, China",
  "Xiamen, China",
  "Chittagong, Bangladesh",
  "Zaozhuang, China",
  "Jilin, China",
  "Linyi, China",
  "Wenzhou, China",
  "Stockholm, Sweden",
  "Puebla De Zaragoza, Mexico",
  "Puning, China",
  "Baku, Azerbaijan",
  "Ibadan, Nigeria",
  "Brisbane, Australia",
  "Minsk, Belarus",
  "Sikasso, Mali",
  "Nanchong, China",
  "Nanning, China",
  "Urumqi, China",
  "Yantai, China",
  "Fuyang, China",
  "Tangshan, China",
  "Maracaibo, Venezuela",
  "Hamburg, Germany",
  "Budapest, Hungary",
  "Shunde, China",
  "Manaus, Brazil",
  "Xuzhou, China",
  "Ségou, Mali",
  "Baotou, China",
  "Hefei, China",
  "Vienna, Austria",
  "Indore, India",
  "Asuncion, Paraguay",
  "Tianmen, China",
  "Belgrade, Serbia",
  "Suzhou, China",
  "Suizhou, China",
  "Nanyang, China",
  "Nakuru, Kenya",
  "Koulikoro, Mali",
  "Ningbo, China",
  "Liuan, China",
  "Anshan, China",
  "Tengzhou, China",
  "Qiqihaer, China",
  "Pizhou, China",
  "Taian, China",
  "Datong, China",
  "Kobe, Japan",
  "Hama, Syria",
  "Esfahan, Iran",
  "Tripoli, Libya",
  "West Yorkshire, United Kingdom",
  "Vadodara, India",
  "Taizhou, China",
  "Luoyang, China",
  "Quito, Ecuador",
  "Jinjiang, China",
  "Mopti, Mali",
  "Perth, Australia",
  "Daejeon, South Korea",
  "Kyoto, Japan",
  "Xiantao, China",
  "Tangerang, Indonesia",
  "Bhopal, India",
  "Coimbatore, India",
  "Kharkiv, Ukraine",
  "Gwangju, South Korea",
  "Xinghua, China",
  "Harare, Zimbabwe",
  "Fushun, China",
  "Shangqiu, China",
  "Belém, Brazil",
  "Wuxi, China",
  "Hechuan, China",
  "Wujin, China",
  "Guigang, China",
  "Jianyang, China",
  "Huhehaote, China",
  "Santa Cruz, Bolivia",
  "Semarang, Indonesia",
  "Ludhiana, India",
  "Novosibirsk, Russia",
  "Neijiang, China",
  "Maputo, Mozambique",
  "Nan'an, China",
  "Douala, Cameroon",
  "Weifang, China",
  "Daqing, China",
  "Kayes, Mali",
  "Tongzhou, China",
  "Tabriz, Iran",
  "Homs, Syria",
  "Rugao, China",
  "Guiping, China",
  "Huainan, China",
  "Kochi, India",
  "Suining, China",
  "Bozhou, China",
  "Zhanjiang, China",
  "Changde, China",
  "Montevideo, Uruguay",
  "Suzhou, China",
  "Xintai, China",
  "Ekaterinoburg, Russia",
  "Juárez, Mexico",
  "Handan, China",
  "Visakhapatnam, India",
  "Kawasaki, Japan",
  "Jiangjin, China",
  "Pingdu, China",
  "Agra, India",
  "Jiangyin, China",
  "Tijuana, Mexico",
  "Liuyang, China",
  "Bursa, Turkey",
  "Al-hasakeh, Syria",
  "Makkah, Saudi Arabia",
  "Yaounde, Cameroon",
  "Xuanwei, China",
  "Dengzhou, China",
  "Palembang, Indonesia",
  "Nizhny Novgorod, Russia",
  "León, Mexico",
  "Guarulhos, Brazil",
  "Heze, China",
  "Auckland, New Zealand",
  "Omdurman, Sudan",
  "Shantou, China",
  "Leizhou, China",
  "Yongcheng, China",
  "Valencia, Venezuela",
  "Thane, India",
  "San Antonio, United States",
  "Xinyang, China",
  "Luzhou, China",
  "Almaty, Kazakhstan",
  "Changshu, China",
  "Taixing, China",
  "Phnom Penh, Cambodia",
  "Laiwu, China",
  "Xiaoshan, China",
  "Yiyang, China",
  "Goiânia, Brazil",
  "Liuzhou, China",
  "Gaozhou, China",
  "Fengcheng, China",
  "Cixi, China",
  "Karaj, Iran",
  "Mogadishu, Somalia",
  "Varanasi, India",
  "Córdoba, Argentina",
  "Kampala, Uganda",
  "Ruian, China",
  "Lianjiang, China",
  "Huaian, China",
  "Shiraz, Iran",
  "Multan, Pakistan",
  "Madurai, India",
  "München, Germany",
  "Kalyan, India",
  "Quanzhou, China",
  "Adana, Turkey",
  "Bazhong, China",
  "Fès, Morocco",
  "Ouagadougou, Burkina Faso",
  "Haicheng, China",
  "Xishan, China",
  "Leiyang, China",
  "Caloocan, Philippines",
  "Kalookan, Philippines",
  "Jingzhou, China",
  "Saitama, Japan",
  "Prague, Czech Republic",
  "Fuqing, China",
  "Kumasi, Ghana",
  "Meerut, India",
  "Hyderabad, Pakistan",
  "Lufeng, China",
  "Dongtai, China",
  "Yixing, China",
  "Mianyang, China",
  "Wenling, China",
  "Leqing, China",
  "Ottawa, Canada",
  "Yushu, China",
  "Barranquilla, Colombia",
  "Hiroshima, Japan",
  "Chifeng, China",
  "Nashik, India",
  "Makasar, Indonesia",
  "Sofia, Bulgaria",
  "Rizhao, China",
  "Davao, Philippines",
  "Tianshui, China",
  "Huzhou, China",
  "Samara, Russia",
  "Omsk, Russia",
  "Gujranwala, Pakistan",
  "Adelaide, Australia",
  "Macheng, China",
  "Wuxian, China",
  "Bijie, China",
  "Yuzhou, China",
  "Leshan, China",
  "La Matanza, Argentina",
  "Rosario, Argentina",
  "Jabalpur, India",
  "Kazan, Russia",
  "Jimo, China",
  "Dingzhou, China",
  "Calgary, Canada",
  "Yerevan, Armenia",
  "El-jadida, Morocco",
  "Jamshedpur, India",
  "Zürich, Switzerland",
  "Zoucheng, China",
  "Pikine-guediawaye, Senegal",
  "Anqiu, China",
  "Guang'an, China",
  "Chelyabinsk, Russia",
  "Conakry, Guinea",
  "Asansol, India",
  "Shouguang, China",
  "Changzhou, China",
  "Ulsan, South Korea",
  "Zhuji, China",
  "Toluca, Mexico",
  "Marrakech, Morocco",
  "Dhanbad, India",
  "Tbilisi, Georgia",
  "Hanchuan, China",
  "Lusaka, Zambia",
  "Qidong, China",
  "Faridabad, India",
  "Zaoyang, China",
  "Zhucheng, China",
  "Rostov-na-donu, Russia",
  "Jiangdu, China",
  "Xiangcheng, China",
  "Zigong, China",
  "Jining, China",
  "Edmonton, Canada",
  "Allahabad, India",
  "Beiliu, China",
  "Dnipropetrovsk, Ukraine",
  "Gongzhuling, China",
  "Qinzhou, China",
  "Ufa, Russia",
  "Sendai, Japan",
  "Volgograd, Russia",
  "Ezhou, China",
  "Guatemala City, Guatemala",
  "Zhongxiang, China",
  "Amsterdam, Netherlands",
  "Brussels, Belgium",
  "Bamako, Mali",
  "Ziyang, China",
  "Antananarivo, Madagascar",
  "Mudanjiang, China",
  "Amritsar, India",
  "Vijayawada, India",
  "Haora, India",
  "Donetsk, Ukraine",
  "Huazhou, China",
  "Fuzhou, China",
  "Pimpri Chinchwad, India",
  "Dublin, Ireland",
  "Rajkot, India",
  "Sao Luís, Brazil",
  "Béni-mellal, Morocco",
  "Lianyuan, China",
  "Liupanshui, China",
  "Kaduna, Nigeria",
  "Kitakyushu, Japan",
  "Qianjiang, China",
  "Perm, Russia",
  "Odessa, Ukraine",
  "Qom, Iran",
  "Yongchuan, China",
  "Peshawar, Pakistan",
  "Linzhou, China",
  "Benxi, China",
  "Ulaanbaatar, Mongolia",
  "Zhangqiu, China",
  "Yongzhou, China",
  "Sao Gonçalo, Brazil",
  "Srinagar, India",
  "Ghaziabad, India",
  "Xinyi, China",
  "Köln, Germany",
  "Zhangjiagang, China",
  "Wafangdian, China",
  "Xianyang, China",
  "Liaocheng, China",
  "Ahwaz, Iran",
  "Taishan, China",
  "Linhai, China",
  "Feicheng, China",
  "Suwon, South Korea",
  "Wuwei, China",
  "Haimen, China",
  "San Luis Potosí, Mexico",
  "Liling, China",
  "Xinhui, China",
  "Gaziantep, Turkey",
  "Krasnoyarsk, Russia",
  "Chiba, Japan",
  "Voronezh, Russia",
  "Durg-bhilai Nagar, India",
  "Ruzhou, China",
  "Maceió, Brazil",
  "Yichun, China",
  "Al-madinah, Saudi Arabia",
  "Yulin, China",
  "Seongnam, South Korea",
  "Yueyang, China",
  "Yiwu, China",
  "San Jose, United States",
  "Jixi, China",
  "Managua, Nicaragua",
  "Xinyi, China",
  "Safi, Morocco",
  "Guangyuan, China",
  "Soweto, South Africa",
  "Zhangjiakou, China",
  "Baoding, China",
  "Cartagena, Colombia",
  "Huludao, China",
  "Pingdingshan, China",
  "Torino, Italy",
  "Zengcheng, China",
  "Laiyang, China",
  "Qingzhou, China",
  "Aurangabad, India",
  "Lattakia, Syria",
  "Mérida, Mexico",
  "Laizhou, China",
  "Thiruvananthapuram, India",
  "Weinan, China",
  "Wuchang, China",
  "Guangshui, China",
  "Gaizhou, China",
  "Göteborg, Sweden",
  "Xiaogan, China",
  "Torreón, Mexico",
  "Jiaxing, China",
  "Kozhikode, India",
  "Salé, Morocco",
  "Zhuzhou, China",
  "Tyneside, United Kingdom",
  "Hengyang, China",
  "Dehui, China",
  "Honghu, China",
  "Danyang, China",
  "Daye, China",
  "Solapur, India",
  "Xingning, China",
  "Xiangfan, China",
  "Shubra-el-khema, Egypt",
  "Luoding, China",
  "Gwalior, India",
  "Ranchi, India",
  "Huiyang, China",
  "Mombasa, Kenya",
  "Jinzhou, China",
  "Jiangyan, China",
  "Chenghai, China",
  "Jiamusi, China",
  "Songzi, China",
  "Tegucigalpa, Honduras",
  "Wujiang, China",
  "Jodhpur, India",
  "Duque De Caxias, Brazil",
  "Xi'ning, China",
  "Yuyao, China",
  "Hezhou, China",
  "Jiangyou, China",
  "Tiruchchirappalli, India",
  "Baoshan, China",
  "Saratov, Russia",
  "Nova Iguaçu, Brazil",
  "Ankang, China",
  "Gaomi, China",
  "Yangchun, China",
  "Santiago De Los Caballeros, Dominican Republic",
  "Danzhou, China",
  "La Paz, Bolivia",
  "Zhuanghe, China",
  "Zhuhai, China",
  "Zhaodong, China",
  "Sakai, Japan",
  "Haikou, China",
  "Jiaonan, China",
  "El Alto, Bolivia",
  "Xuancheng, China",
  "Wuchuan, China",
  "Yuhang, China",
  "Qinhuangdao, China",
  "Bogor, Indonesia",
  "Kermanshah, Iran",
  "Longhai, China",
  "Liverpool, United Kingdom",
  "Yanshi, China",
  "Guwahati, India",
  "Yichun, China",
  "Konya, Turkey",
  "Barquisimeto, Venezuela",
  "Yingde, China",
  "Bengbu, China",
  "Yibin, China",
  "Chandigarh, India",
  "Xiangxiang, China",
  "Yinchuan, China",
  "Valencia, Spain",
  "Guilin, China",
  "Hamamatsu, Japan",
  "Sao Bernardo Do Campo, Brazil",
  "Deir El-zor, Syria",
  "Bishkek, Kyrgyzstan",
  "Teresina, Brazil",
  "Suihua, China",
  "Benghazi, Libya",
  "Jiutai, China",
  "Meishan, China",
  "Zaporizhya, Ukraine",
  "Gaoyou, China",
  "Marseille, France",
  "Kaifeng, China",
  "Changning, China",
  "Tongliao, China",
  "Natal, Brazil",
  "Bandar Lampung, Indonesia",
  "Dongying, China",
  "Gaoan, China",
  "Langzhong, China",
  "Lichuan, China",
  "Hubli-dharwad, India",
  "Mysore, India",
  "Niigata, Japan",
  "Indianapolis, United States",
  "Jiaozhou, China",
  "Pingxiang, China",
  "Haiphong, Viet Nam",
  "Arequipa, Peru",
  "Jacksonville, United States",
  "Tanger, Morocco",
  "Dandong, China",
  "Kishinev, Moldova",
  "Krasnodar, Russia",
  "Zagreb, Croatia",
  "Xinmi, China",
  "Chaohu, China",
  "Xinyu, China",
  "Gongyi, China",
  "Huixian, China",
  "Xinxiang, China",
  "Port Elizabeth, South Africa",
  "Mendoza, Argentina",
  "Nantong, China",
  "Pengzhou, China",
  "Khulna, Bangladesh",
  "Malang, Indonesia",
  "Padang, Indonesia",
  "Anyang, China",
  "Renqiu, China",
  "Foshan, China",
  "Anshun, China",
  "Chihuahua, Mexico",
  "Campo Grande, Brazil",
  "Lódz, Poland",
  "Goyang, South Korea",
  "Benin City, Nigeria",
  "Bucheon, South Korea",
  "Gaocheng, China",
  "Pulandian, China",
  "Hejian, China",
  "Dafeng, China",
  "Kraków, Poland",
  "Enshi, China",
  "Dongyang, China",
  "Lviv, Ukraine",
  "Kunshan, China",
  "Shuangcheng, China",
  "Salem, India",
  "Jiaozuo, China",
  "Ad-dammam, Saudi Arabia",
  "Huaibei, China",
  "Liyang, China",
  "Samut Prakan, Thailand",
  "Rongcheng, China",
  "Cenxi, China",
  "Nampho, North Korea",
  "Columbus, United States",
  "Bareilly, India",
  "Leping, China",
  "Laixi, China",
  "Liaoyang, China",
  "Zhaotong, China",
  "Jerusalem, Israel",
  "Tainan, China",
  "Cuernavaca, Mexico",
  "Riga, Latvia",
  "Linfen, China",
  "Québec, Canada",
  "Lingbao, China",
  "Shangyu, China",
  "Wuan, China",
  "Hailun, China",
  "Xingyi, China",
  "Wuxue, China",
  "Cebu, Philippines",
  "Aguascalientes, Mexico",
  "Tolyatti, Russia",
  "Hamilton, Canada",
  "Zhoushan, China",
  "Langfang, China",
  "Osasco, Brazil",
  "Nonthaburi, Thailand",
  "Dashiqiao, China",
  "Tongxiang, China",
  "Yichang, China",
  "Yangzhou, China",
  "Blantyre City, Malawi",
  "Hamhung, North Korea",
  "Jalandhar, India",
  "Al-rakka, Syria",
  "Niamey, Niger",
  "Xiangtan, China",
  "Winnipeg, Canada",
  "Oran, Algeria",
  "Kota, India",
  "Sevilla, Spain",
  "Navi Mumbai, India",
  "Port Harcourt, Nigeria",
  "Saltillo, Mexico",
  "Khartoum North, Sudan",
  "Shizuoka, Japan",
  "Yuanjiang, China",
  "Raipur, India",
  "Kryviy Rig, Ukraine",
  "Yingkou, China",
  "Wuhu, China",
  "Zhenjiang, China",
  "Querétaro, Mexico",
  "Nankang, China",
  "Wugang, China",
  "Hegang, China",
  "Linqing, China",
  "Pretoria, South Africa",
  "Zunyi, China",
  "Panzhihua, China",
  "Austin, United States",
  "Changle, China",
  "Lianyungang, China",
  "Yancheng, China",
  "Zunhua, China",
  "Changyi, China",
  "Meknès, Morocco",
  "Qiongshan, China",
  "Bulawayo, Zimbabwe",
  "Wendeng, China",
  "Okayama, Japan",
  "Santo André, Brazil",
  "Rabat, Morocco",
  "Pakanbaru, Indonesia",
  "Nehe, China",
  "Memphis, United States",
  "Joao Pessoa, Brazil",
  "Kathmandu, Nepal",
  "Longkou, China",
  "Shengzhou, China",
  "Antalya, Turkey",
  "Kumamoto, Japan",
  "Lilongwe, Malawi",
  "Mexicali, Mexico",
  "Kaiping, China",
  "Palermo, Italy",
  "Aligarh, India",
  "Nottingham, United Kingdom",
  "Haining, China",
  "Mosul, Iraq",
  "Hermosillo, Mexico",
  "Tongcheng, China",
  "Shulan, China",
  "Miluo, China",
  "Bhubaneswar, India",
  "Yangquan, China",
  "Chenzhou, China",
  "Haiyang, China",
  "Morelia, Mexico",
  "Huangshi, China",
  "Xinmin, China",
  "Tétouan, Morocco",
  "Barnaul, Russia",
  "Qixia, China",
  "Jaboatao Dos Guarapes, Brazil",
  "Chongzhou, China",
  "Cotonou, Benin",
  "Yingcheng, China",
  "Zaragoza, Spain",
  "Changzhi, China",
  "Qujing, China",
  "Linghai, China",
  "Changge, China",
  "Trujillo, Peru",
  "Tampico, Mexico",
  "Maoming, China",
  "Morón, Argentina",
  "La Plata, Argentina",
  "Ciudad Guayana, Venezuela",
  "Moradabad, India",
  "Jieshou, China",
  "Sheffield, United Kingdom",
  "Donggang, China",
  "Jingjiang, China",
  "Acheng, China",
  "Acapulco, Mexico",
  "Veracruz, Mexico",
  "Ulyanovsk, Russia",
  "Wroclaw, Poland",
  "Jieyang, China",
  "Shaoxing, China",
  "Qian'an, China",
  "Nanchuan, China",
  "Qionglai, China",
  "Deyang, China",
  "Sagamihara, Japan",
  "Fuyang, China",
  "Fuxin, China",
  "Jiyuan, China",
  "Puente Alto, Chile",
  "Qufu, China",
  "Gaoyao, China",
  "Gorakhpur, India",
  "Fort Worth, United States",
  "Xinji, China",
  "San Miguel De Tucumán, Argentina",
  "Dujiangyan, China",
  "The Hague, Netherlands",
  "Bhiwandi, India",
  "Culiacán Rosales, Mexico",
  "Lingyuan, China",
  "Xingyang, China",
  "Maiduguri, Nigeria",
  "Genova, Italy",
  "Meihekou, China",
  "Izhevsk, Russia",
  "Jeonju, South Korea",
  "Leling, China",
  "Xichang, China",
  "Colombo, Sri Lanka",
  "Zaria, Nigeria",
  "Anlu, China",
  "Sao José Dos Campos, Brazil",
  "Charlotte, United States",
  "Yizheng, China",
  "Malmö, Sweden",
  "Weihai, China",
  "Xinzheng, China",
  "Dengfeng, China",
  "Vladivostok, Russia",
  "Shaoyang, China",
  "Taizhou, China",
  "Jammu, India",
  "Lanxi, China",
  "Yuncheng, China",
  "Kagoshima, Japan",
  "Yaroslave, Russia",
  "Contagem, Brazil",
  "Shishou, China",
  "Panjin, China",
  "Zamboanga, Philippines",
  "Orumiyeh, Iran",
  "Binzhou, China",
  "Kisumu, Kenya",
  "Baoji, China",
  "Uberlândia, Brazil",
  "El Paso, United States",
  "Yunzhou, China",
  "Kénitra, Morocco",
  "Diyarbakir, Turkey",
  "Jurong, China",
  "Cúcuta, Colombia",
  "Zhaoyuan, China",
  "Huizhou, China",
  "Tianchang, China",
  "Dortmund, Germany",
  "Shihezi, China",
  "Shiyan, China",
  "Cuttack, India",
  "Cochabamba, Bolivia",
  "Cheongju, South Korea",
  "Jingmen, China",
  "Shangzhi, China",
  "Anqing, China",
  "Chongjin, North Korea",
  "Stuttgart, Germany",
  "Rushan, China",
  "Kingston, Jamaica",
  "Milwaukee, United States",
  "Sorocaba, Brazil",
  "Glasgow, United Kingdom",
  "Khabarovsk, Russia",
  "Guanghan, China",
  "Warangal, India",
  "Irkutsk, Russia",
  "Tyumen, Russia",
  "Lomas De Zamora, Argentina",
  "Beipiao, China",
  "Funabashi, Japan",
  "Mingguang, China",
  "Düsseldorf, Germany",
  "Shenzhou, China",
  "Içel, Turkey",
  "Zhangzhou, China",
  "Xianning, China",
  "Maanshan, China",
  "Bandjarmasin, Indonesia",
  "Callao, Peru",
  "Poznan, Poland",
  "Kayseri, Turkey",
  "Chon Buri, Thailand",
  "Quetta, Pakistan",
  "Shuozhou, China",
  "Samarinda, Indonesia",
  "Helsinki, Finland",
  "Akesu, China",
  "Novokuznetsk, Russia",
  "Málaga, Spain",
  "Fengcheng, China",
  "Hachioji, Japan",
  "Ribeirao Prêto, Brazil",
  "Beihai, China",
  "Jamnagar, India",
  "Nouakchott, Mauritania",
  "Bazhou, China",
  "Yongkang, China",
  "Louisville, United States",
  "Chizhou, China",
  "Huaiyin, China",
  "Fuan, China",
  "Bhilai Nagar, India",
  "Dezhou, China",
  "Makhachkala, Russia",
  "Xingping, China",
  "Jiujiang, China",
  "Bristol, United Kingdom",
  "Botou, China",
  "Fengnan, China",
  "Astana, Kazakhstan",
  "Yizhou, China",
  "Amravati, India",
  "Nashville-davidson, United States",
  "Batam, Indonesia",
  "Orenburg, Russia",
  "Zhuozhou, China",
  "Las Vegas, United States",
  "Cancun, Mexico",
  "Longyan, China",
  "Oslo, Norway",
  "Cuiabá, Brazil",
  "Tiruppur, India",
  "Vilnius, Lithuania",
  "Bremen, Germany",
  "Gold Coast-tweed, Australia",
  "Gaobeidian, China",
  "Mangalore, India",
  "Songyuan, China",
  "Yangjiang, China",
  "Wanyuan, China",
  "Jiangmen, China",
  "Xingtai, China",
  "Shaoguan, China",
  "Feira De Santana, Brazil",
  "Guixi, China",
  "Ruijin, China",
  "Zahedan, Iran",
  "Jinzhong, China",
  "Portland, United States",
  "Jintan, China",
  "Reynosa, Mexico",
  "Ilorin, Nigeria",
  "Oklahoma City, United States",
  "Nakhon Ratchasima, Thailand",
  "N'djamena, Chad",
  "Shangzhou, China",
  "Panshi, China",
  "Kerman, Iran",
  "Kaiyuan, China",
  "Islamabad, Pakistan",
  "Sarajevo, Bosnia And Herzegovina",
  "Bikaner, India",
  "Dushanbe, Tajikistan",
  "Vientiane, Laos",
  "Dehradun, India",
  "Zhangshu, China",
  "Beining, China",
  "Abu Dhabi, United Arab Emirates",
  "Shimkent, Kazakhstan",
  "Xingcheng, China",
  "Imbaba, Egypt",
  "Yicheng, China",
  "Skoplje, Macedonia",
  "Kadhimain, Iraq",
  "At-ta'if, Saudi Arabia",
  "Dali, China",
  "Fuding, China",
  "Jinzhou, China",
  "Renhuai, China",
  "Mira-bhayandar, India",
  "Kemerovo, Russia",
  "Duisburg, Germany",
  "Rasht, Iran",
  "Renhuai, China",
  "Essex, United Kingdom",
  "Birmingham, United Kingdom",
  "Tulum, Mexico",
  "Playa del Carmen, Mexico",
  "Ibiza, Spain"
];
