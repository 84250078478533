import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import LocationLogoYellow from "../images/pin-logo.png";
// TODO: Change this into a better one
import LocationLogoOrange from "../images/pin-logo-orange.png";
import VerificationTick from "../images/verified_tick.png"

const RED = "#ef4243";
const YELLOW = "#aaea21";
const ORANGE = "#E67E22";

const ProfileCardTopLeft = ({ theme, creativeType, modelType }) => {
  if (theme === "influencers") {
    return null;
  }

  if (theme === "models") {
    return <p className="modelType">{modelType}</p>;
  }

  return (
    <p className="modelType creative">
      {creativeType === "S" ? "S / M" : creativeType}
    </p>
  );
};

const ProfileCardBottomLeft = ({ theme, maxFollowers, location, is_verified }) => {
  const src = theme === "creatives" ? LocationLogoOrange : LocationLogoYellow;

  if (["creatives", "models"].includes(theme)) {
    return (
      <span>
        <img src={src} className="backArrow" />
        <p className="">{location?.toUpperCase()}</p>
        { is_verified ? (<img src={VerificationTick} className="verifiedIcon" />) : null}
      </span>
    );
  } else if (theme === "influencers") {
    return (
      <span className="">
        {maxFollowers} FOLLOWERS
        { is_verified ? (<img src={VerificationTick} className="verifiedIcon" />) : null}
      </span>
    )
  }
};

const ProfileCard = ({ profile, updateQuery, theme, setShowProfile }) => {
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState(1);
  const [loaderColor, setLoaderColor] = useState(YELLOW);

  const container = useRef();
  useEffect(() => {
    if (!container) return;
    setHeight((container.current.offsetWidth / 4) * 5);
  }, [container]);

  useEffect(() => {
    if (theme === "creatives") {
      setLoaderColor(ORANGE);
    } else if (theme === "influencers") {
      setLoaderColor(RED);
    } else {
      setLoaderColor(YELLOW);
    }
  }, []);

  return (
    <div
      ref={container}
      onClick={() => {
        setShowProfile(profile.ig_handle);
      }}
      className={`profileCardWrapper ${theme}`}
      style={{ height: `${height}px` }}
    >
      {height > 0 ? (
        <>
          {loading ? (
            <div className="loaderBox" height={height}>
              <Loader
                type="TailSpin"
                color={loaderColor}
                height={40}
                width={40}
                timeout={4000}
              />
            </div>
          ) : null}
          <div className="profileCard">
            <div
              className="pickGradient"
              style={{ display: `${loading ? "none" : "block"}` }}
            >
              <img
                src={profile.profile_picture || profile.profile_picture_url}
                height={height}
                alt={profile.name}
                className="profileImage"
                onLoad={() => setLoading(false)}
                onError={() => setLoading(false)}
              />
            </div>
            {loading ? null : (
              <p className="profileCategory">
                {(
                  profile.model_category ||
                  profile.sector ||
                  profile.creative_sector
                )?.toUpperCase()}
              </p>
            )}
            {loading ? null : (
              <ProfileCardTopLeft
                theme={theme}
                modelType={profile.model_type}
                creativeType={profile.creative_type}
              />
            )}
            {loading ? null : (
              <div className="profileLocation">
                <ProfileCardBottomLeft
                  theme={theme}
                  maxFollowers={profile.max_followers}
                  location={profile?.shorten_location?.toUpperCase()}
                  is_verified={profile.is_verified}
                />
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ProfileCard;
