import React, { useState, useEffect } from "react";
import ProfilePicture from "../components/ProfilePicture";
import Slider from "../components/Slider";

const ServicePhoto = ({
                              data,
                              type
                            }) => {
  const [photoIndex, setPhotoIndex] = useState();
  const [profileColor, setProfileColor] = useState();

  useEffect(() => {
    setProfileColor(type);
  }, [type]);
  return (
    <>
      {photoIndex !== undefined ? (
        <Slider
          photoIndex={photoIndex}
          closeSlider={() => setPhotoIndex(undefined)}
          pictures={data.pics}
          type={profileColor}
        />
      ) : null}
      <div className='service_photos'>
        {data.pics.map((pic, i) => (
          <ProfilePicture
            key={i}
            data={data}
            setPhotoIndex={setPhotoIndex}
            pic={pic}
            index={i}
            type={profileColor}
          />
        ))}
      </div>

    </>
  );
};

export default ServicePhoto;