import React, { useState, useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import { COLORS } from "../utils/constants";

const { red, yellow, orange } = COLORS;

const ProfileCardPlaceholder = ({ count = 1, theme = "models" }) => {
  const [height, setHeight] = useState(1);
  const times = Array.from(Array(count).keys());
  const container = useRef();
  let loaderColor = yellow;
  if (theme === "creatives") {
    loaderColor = orange;
  } else if (theme === "influencers") {
    loaderColor = red;
  }
  useEffect(() => {
    if (!container) return;
    setHeight((container.current.offsetWidth / 4) * 5);
  }, [container]);

  return times.map(n => (
    <div
      key={n}
      ref={container}
      className={`profileCardWrapper ${theme}`}
      style={{ height: `${height}px` }}
    >
      {height > 0 ? (
        <div className="loaderBox" height={height}>
          <Loader
            type="TailSpin"
            color={`${loaderColor}`}
            height={40}
            width={40}
            timeout={2000}
          />
        </div>
      ) : null}
    </div>
  ));
};

export default ProfileCardPlaceholder;
