import { emailValidator } from "../../utils/emailValidator.js";
import { startSpinner } from "./initPhotoForms.js";

const toggleOnDisplay = () => {
  setTimeout(() => {
    const budget = document.querySelector(".gig_budget");
    const kind = document.querySelector("#kind_input");
    if (kind.value === "Paid") {
      budget.style.display = "block";
    } else {
      budget.style.display = "none";
    }
  }, 500);
  setTimeout(() => {
    const location_type = document.querySelector("#location_type_input");
    const autoComplete = document.querySelector("#citiesAutocomplete");
    if (location_type.value !== "Global" && location_type.value !== "") {
      autoComplete.style.display = "block";
    } else {
      autoComplete.style.display = "none";
    }
  }, 500);
};

const toggleBudget = e => {
  const budget = document.querySelector(".gig_budget");
  setTimeout(() => {
    if (e.target.value === "Paid") {
      budget.style.display = "block";
    } else {
      budget.style.display = "none";
    }
  }, 400);
};

const activateSubmitBtn = bool => {
  const input = document.querySelector("form input[type='submit']");
  const error = document.querySelector("#errorSubmit");
  input.disabled = !bool;
  if (!bool) {
    error.innerText = "The title of the job is too long.";
  } else {
    error.innerText = "";
  }
};

const toggleLocation = e => {
  const autoComplete = document.querySelector("#citiesAutocomplete");
  setTimeout(() => {
    if (e.target.value === "Global") {
      autoComplete.style.display = "none";
    } else {
      autoComplete.style.display = "block";
    }
  }, 400);
};

const locationInputs = () => {
  const location_type = document.querySelector("#location_type_input");
  location_type.addEventListener("focusout", toggleLocation);
};

const freeAndPayJobs = () => {
  const kind = document.querySelector("#kind_input");
  kind.addEventListener("blur", toggleBudget);
};

const maxLength = (input, valueBox, maxLength) => {
  input.addEventListener("input", e => {
    const length = e.target.value.length;
    if (length > 0) {
      valueBox.innerText = `${length}/${maxLength}`;
      if (length > maxLength) {
        input.style.borderColor = "red";
        valueBox.style.color = "black";
        valueBox.style.borderWidth = "2px";
        valueBox.style.backgroundColor = "red";
        valueBox.style.padding = "4px 4px";
        valueBox.style.borderRadius = "8px";
        activateSubmitBtn(false);
      } else {
        input.style.borderColor = "#b7fc22";
        valueBox.style.color = "#d0d0d0";
        valueBox.style.backgroundColor = "transparent";
        valueBox.style.padding = "0";
        valueBox.style.borderRadius = "0";
        activateSubmitBtn(true);
      }
    } else {
      valueBox.innerText = "";
      input.style.borderColor = "#b7fc22";
      valueBox.style.color = "none";
      valueBox.style.backgroundColor = "transparent";
      valueBox.style.padding = "0";
      valueBox.style.borderRadius = "0";
      activateSubmitBtn(true);
    }
  });
};

const checkValidEmail = (emailInput, submitBtn) => {
  const errorNode = document.getElementById("email-error");

  emailInput.addEventListener("change", e => {
    if (emailValidator(e.target.value)) {
      submitBtn.disabled = false;
      errorNode.innerText = "";
    } else {
      submitBtn.disabled = true;
      errorNode.innerText = "The email is invalid";
    }
  });
};

const initGigForm = () => {
  const descriptionInput = document.querySelector("#gig_description");
  const descriptionLength = document.querySelector("#descriptionLength");
  const titleInput = document.querySelector("#gig_title");
  const titleLenght = document.querySelector("#titleLength");
  const newPage = document.querySelector("#newGigPage");
  const editPage = document.querySelector("#editGigPage");
  const button = document.querySelector("form input[type='submit']");
  const emailInput = document.querySelector("#gig_email");

  if (newPage || editPage) {
    startSpinner(button);
    toggleOnDisplay();
    freeAndPayJobs();
    locationInputs();
    maxLength(descriptionInput, descriptionLength, 300);
    maxLength(titleInput, titleLenght, 20);
    checkValidEmail(emailInput, button);
  }
};

export { initGigForm };
