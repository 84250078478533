import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import FilterButton from "./FilterButton";
import NewGigModal from "./NewGigModal";
import FilterImg from "../images/filters.png";
import {
  MODELS_FILTER,
  INFLUENCERS_FILTER,
  GIGS_FILTER,
  SERVICES_FILTER,
  CREATIVES_FILTER,
  CREATIVE_SECTOR_LIST,
  STYLIST_SECTOR_LIST
} from "../utils/constants";
import CloseIcon from "../images/close.png";
import CloseIconRed from "../images/close-red.png";
import CloseIconOrange from "../images/close-orange.png";
import ApplicableFilter from "./ApplicableFilter";
import FilterLocation from "../components/FilterLocation";
import NameIdSearch from "../components/NameIdSearch";
import downArrow from "../images/down-arrow.svg";
import Reset from "../images/reset.png";
import FilterVerifiedUser from "../components/FilterVerifiedUser";

const Filter = ({
  setQuery,
  type,
  page,
  resetFilter,
  query,
  updateQuery,
  setGigModalIsOpen = () => null
}) => {
  const [show, setShow] = useState(false);
  const [partialQuery, setPartialQuery] = useState();
  const [forceSearch, setForceSearch] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [creativeSector, setCreativeSector] = useState(CREATIVE_SECTOR_LIST);
  const isTableOrMobile = useMediaQuery({
    query: "(min-width: 0px) and (max-width: 499px)"
  });

  let closeIcon = CloseIcon;

  let FILTER_DATA;
  if (type === "models") {
    FILTER_DATA = MODELS_FILTER;
  } else if (type === "influencers") {
    FILTER_DATA = INFLUENCERS_FILTER;
    closeIcon = CloseIconRed;
  } else if (type === "gigs") {
    FILTER_DATA = GIGS_FILTER;
  }else if (type === "services"){
    FILTER_DATA = SERVICES_FILTER;
  } else if (type === "creatives") {
    FILTER_DATA = CREATIVES_FILTER;
    closeIcon = CloseIconOrange;
  }
  useEffect(() => {
    if (!show) {
      document.body.classList.remove("modal-open");
    } else {
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 300);
    }
  }, [show]);

  useEffect(() => {
    if (
      partialQuery &&
      "creative_type" in partialQuery &&
      partialQuery.creative_type.length === 1 &&
      partialQuery.creative_type[0] === "3"
    ) {
      setCreativeSector(STYLIST_SECTOR_LIST);
    } else {
      setCreativeSector(CREATIVE_SECTOR_LIST);
    }
  }, [partialQuery]);

  const searchQueryString = () => {
    setForceSearch(true);
    setTimeout(() => {
      setForceSearch(false);
    }, 100);
  };

  useEffect(() => {
    setPartialQuery(query);
  }, [query]);

  if (isTableOrMobile) {
    return (
      <div className="filters">
        {type !== "gigs" && type !== "services" && (
          <NewGigModal buttonClasses="w-100 mt-0" type={type} />
        )}
        <div className="filterHeader">
          <button onClick={resetFilter} className={`resetBtn ${type}`}>
            Reset filters
            <img src={Reset} alt="reset-filters" />
          </button>
          <div
            className={`filterBtn ${type}`}
            onClick={() => setShow(prevState => !prevState)}
          >
            Apply Filters
            <img
              src={FilterImg}
              alt="down-arrow"
              className="expandIconImgMobile"
            />
          </div>
        </div>
        {show && (
          <div className="mobileFiltersBg">
            <h1>Filters</h1>
            <img
              src={closeIcon}
              alt="Close"
              onClick={() => setShow(false)}
              className={`closeIcon ${type}`}
            />
            <div className="profilesFilter">
              {type === "gigs" ? null : (
                <NameIdSearch
                  page={type}
                  updateQuery={updateQuery}
                  mobile={true}
                  forceSearch={forceSearch}
                  prevName={query.name}
                  setPartialQuery={setPartialQuery}
                  partialQuery={partialQuery}
                />
              )}
              <FilterLocation
                page={type}
                updateQuery={updateQuery}
                prevLocation={query.location}
              />
              {type === "gigs" ? (
                <ApplicableFilter setQuery={setQuery} query={query} />
              ) : null}

              {type !== "gigs" && type !== "services" ? (
                <FilterVerifiedUser setQuery={setQuery} query={query} type={type} />
              ) : null}

              {FILTER_DATA.map((cat, i) => {
                if (cat.basic !== false) {
                  if (cat.name === "media_sector") {
                    return (
                      <FilterButton
                        {...cat}
                        page={type}
                        query={partialQuery}
                        key={i}
                        setQuery={setPartialQuery}
                        list={creativeSector}
                      />
                    );
                  } else {
                    return (
                      <FilterButton
                        {...cat}
                        page={type}
                        query={partialQuery}
                        key={i}
                        setQuery={setPartialQuery}
                      />
                    );
                  }
                } else {
                  return null;
                }
              })}
              {FILTER_DATA.filter(f => f.basic === false).length > 0 ? (
                <div
                  className="showAdvancedFilters"
                  onClick={() => setShowAdvanced(showAdvanced => !showAdvanced)}
                >
                  ADVANCED FILTERS
                  <div
                    className={`expandIcon ${
                      showAdvanced ? "open" : "closed"
                    } ${page}`}
                  >
                    <img
                      src={downArrow}
                      alt="down-arrow"
                      width={12}
                      height={12}
                      className={`${show ? "openImg" : ""}`}
                    />
                  </div>
                </div>
              ) : null}
              {showAdvanced ? (
                <div className="advancedFilters">
                  {FILTER_DATA.map((cat, i) =>
                    cat.basic === false ? (
                      <FilterButton
                        {...cat}
                        page={type}
                        query={partialQuery}
                        key={i}
                        setQuery={setPartialQuery}
                      />
                    ) : null
                  )}
                </div>
              ) : null}

              <button
                onClick={() => {
                  searchQueryString();
                  setQuery({ ...query, ...partialQuery });
                  setTimeout(() => {
                    setShow(false);
                  }, 200);
                }}
                className={`btn btn-${type}`}
              >
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="filters">
      <div className="profilesFilter">
        {FILTER_DATA.map((cat, i) => {
          if (cat.basic !== false) {
            if (cat.name === "media_sector") {
              return (
                <FilterButton
                  {...cat}
                  page={type}
                  query={query}
                  key={i}
                  setQuery={setQuery}
                  list={creativeSector}
                />
              );
            } else {
              return (
                <FilterButton
                  {...cat}
                  page={type}
                  query={query}
                  key={i}
                  setQuery={setQuery}
                />
              );
            }
          } else {
            return null;
          }
        })}
        {FILTER_DATA.filter(f => f.basic === false).length > 0 ? (
          <div
            className="showAdvancedFilters"
            onClick={() => setShowAdvanced(showAdvanced => !showAdvanced)}
          >
            ADVANCED FILTERS
            <div
              className={`expandIcon ${
                showAdvanced ? "open" : "closed"
              } ${page}`}
            >
              <img
                src={downArrow}
                alt="down-arrow"
                width={12}
                height={12}
                className={`${show ? "openImg" : ""}`}
              />
            </div>
          </div>
        ) : null}
        {showAdvanced ? (
          <div className="advancedFilters">
            {FILTER_DATA.map((cat, i) =>
              cat.basic === false ? (
                <FilterButton
                  {...cat}
                  page={type}
                  query={query}
                  key={i}
                  setQuery={setQuery}
                />
              ) : null
            )}
          </div>
        ) : null}
      </div>
      <button onClick={resetFilter} className={`resetBtn ${type}`}>
        Reset Filters
        <img src={Reset} alt="reset-filters" />
      </button>
      {type !== "gigs" && type !== "services" && (
        <NewGigModal
          buttonClasses="w-100"
          type={type}
          setGigModalIsOpen={setGigModalIsOpen}
        />
      )}
    </div>
  );
};

export default Filter;
